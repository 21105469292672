import { useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';

import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';

import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';

import { useAddAdminsMutation, useRemoveAdminsMutation, useGetUsersQuery } from 'service/users';

import { AddUser, AdminListItem } from './AdminListItem';

import { ActionToast } from './AddToast';

interface AdminFormProps {
  open: boolean;
  handleOpenForm: () => void;
  handleCloseForm: () => void;
}
export function AddAdminForm({
  open,
  handleOpenForm,
  handleCloseForm,
}: AdminFormProps): JSX.Element {
  const { showToast, setOpen: setToastOpen } = useToast();

  const [allUsers, setAllUsers] = useState<AddUser[]>([]);

  const [addedUsers, setAddedUsers] = useState<AddUser[]>([]);

  // search query is used to maintain the state of the input
  // while the user is typing
  const [searchQuery, setSearchQuery] = useState('');

  // debouned query is used to maintain the state for sending debounded
  // the query to the backend
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  useEffect(() => {
    // Set a timeout to update the debouncedSearchQuery after the specified delay
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    // Clear the timeout if searchQuery changes before the delay period elapses
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [OrgId] = useOrgId();

  const { data } = useGetUsersQuery(
    {
      limit: 5,
      offset: 0,
      searchQuery: debouncedSearchQuery,
      orgId: OrgId,
    },
    {
      skip: debouncedSearchQuery === '',
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAdd = (id: string) => {
    const addedUser = allUsers.find((user) => user.id === id);
    if (addedUser) {
      setAddedUsers([...addedUsers, addedUser]);
    }
  };

  useEffect(() => {
    if (data) {
      setAllUsers(
        data.hits
          ?.filter((hit) => !addedUsers.some((addedUser) => addedUser.id === hit.id))
          ?.filter((hit) => !hit.admin)
          .map((user) => ({ ...user, added: false, role: 'mailadmin', employeeId: user.id }))
      );
    }
  }, [data, addedUsers]);

  const [addAdmins] = useAddAdminsMutation();

  const [removeAdmins] = useRemoveAdminsMutation();

  const handleRemove = (id: string) => {
    setAddedUsers(addedUsers.filter((user) => user.id !== id));
  };

  const handleRemoveAdmins = async (userIds: string[]) => {
    try {
      await removeAdmins({
        orgId: OrgId,
        adminIds: userIds,
      });
    } catch {
      // TODO: handle errors
      // eslint-disable-next-line no-console
    }
  };

  const handleSave = async () => {
    try {
      const adminsTobeAdded = addedUsers.map((user) => ({ employeeId: user.id, role: user.role }));

      await addAdmins({
        admins: adminsTobeAdded,
        orgId: OrgId,
      }).unwrap();

      showToast({
        component: (
          <ActionToast
            users={addedUsers.map((user) => ({
              name: user.name,
              email: user.email,
              id: user.id,
              added: true,
              vip: user.vip,
              financeAndAccounting: user.financeAndAccounting,
            }))}
            onUndo={() => {
              handleRemoveAdmins(addedUsers.map((user) => user.employeeId));
              setToastOpen(false);
            }}
            roleType="Admin"
          />
        ),
      });

      handleCloseForm();

      // setting the state of the added users to the default state so that when user opens up the
      // form again it's in the default state rather than the state when they previously opened
      setAddedUsers([]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch {
      // TODO: handle errors
      // eslint-disable-next-line no-console
      showToast({
        component: (
          <div className="p-2 text-red-500 bg-white rounded-lg w-1/2">
            Something went wrong. Please try again.
          </div>
        ),
      });
    }
  };

  const handleChangeRole = (alreadAdded: boolean, id: string, role: string) => {
    if (alreadAdded) {
      // update the user role in the addedUsers state
      setAddedUsers(addedUsers.map((user) => (user.id === id ? { ...user, role } : user)));
    } else {
      // update the user role in the allUsers state
      setAllUsers(allUsers.map((user) => (user.id === id ? { ...user, role } : user)));
    }
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Trigger>
        <button
          type="button"
          className="bg-black px-4 py-2 rounded-lg text-white text-sm"
          onClick={() => handleOpenForm()}
        >
          Add Admin +
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay
          className="bg-black/50 opacity-30 fixed inset-0 z-50"
          onClick={() => handleCloseForm()}
        />
        <Dialog.Content
          className="bg-white rounded-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-fade-in w-1/3 z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <Card width="100%">
            <CardHeader>
              <span>Add an Admin</span>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-6">
                <div className="border-light border-border-primary p-2 flex items-center gap-2 rounded-md w-full">
                  <IconSearch className="w-3 h-3 fill-light-grey" />
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full bg-transparent outline-none text-xs placeholder:font-light placeholder:text-light-grey"
                    onChange={handleSearch}
                  />
                </div>

                <div className="flex flex-col gap-6">
                  {addedUsers.map((user) => (
                    <AdminListItem
                      key={user.id}
                      name={user.name}
                      vip={user.vip}
                      financeAndAccounting={user.financeAndAccounting}
                      id={user.id}
                      email={user.email}
                      added
                      role={user.role}
                      onAdd={() => handleRemove(user.id)}
                      handleChangeRole={(role) => handleChangeRole(true, user.id, role)}
                      employeeId={user.employeeId}
                    />
                  ))}
                  {allUsers.map((user) => (
                    <AdminListItem
                      key={user.id}
                      name={user.name}
                      vip={user.vip}
                      financeAndAccounting={user.financeAndAccounting}
                      id={user.id}
                      email={user.email}
                      added={user.added}
                      role={user.role}
                      onAdd={() => handleAdd(user.id)}
                      handleChangeRole={(role) => handleChangeRole(false, user.id, role)}
                      employeeId={user.employeeId}
                    />
                  ))}
                </div>
              </div>
            </CardContent>
            <div className="flex items-center justify-center gap-4 py-5 px-8 border-t-light border-t-border-primary">
              <button
                type="button"
                className="flex-1 rounded-full border-black/20 border-light text-black/20 px-8 py-2 text-xs hover:text-black hover:border-black"
                onClick={() => handleCloseForm()}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`flex-1 ${addedUsers.length === 0 ? 'bg-black/20' : 'bg-black'} px-8 py-2 rounded-full text-white text-xs`}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </Card>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
