import { useState } from 'react';

import { Event } from 'models/events';
import Sort, { Order } from 'components/Sort';
import { useGetFlaggedEventStatsQuery } from 'service/eventsApi';

import { ReactComponent as IconAttachment } from 'assets/icons/attachment.svg';
import { ReactComponent as IconLink } from 'assets/icons/url.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { EventStatus } from 'enums/eventStatusTypes';

import { useOrgId } from 'hooks/useOrgId';
import { NoThreatFallback } from 'components/Threats/NoThreatFallback';
import Loader from 'components/Loader';
import { ThreatMetric } from 'models/threats';

import { EventRow } from './EventRow';
import { EventFilter, EventFilterOptions, EventStatusFilter } from './EventFilter';

interface EventTableProps {
  events: Event[];
  onSort: (sorting: Order) => void;
  initialSortType: number;
  from?: string;
  to?: string;
  onFilter: (options: EventFilterOptions) => void;
  isLoading: boolean;
}

type HeaderWithIcon = 'Attachments' | 'URL';

function EventTable({
  events,
  onSort,
  initialSortType,
  from,
  to,
  onFilter,
  isLoading,
}: EventTableProps) {
  // eventHeaderColumnNames is an array of the names of the columns in the table
  const eventHeaderColumns: { name: string; width: string }[] = [
    { name: 'Subject & Content', width: 'w-1/8' },
    { name: 'Time', width: 'w-1/12' },
    { name: 'Sender', width: 'w-2/12' },
    { name: 'Recipients', width: 'w-2/12' },
    { name: 'Attachments', width: 'w-1/24' },
    { name: 'URL', width: 'w-1/24' },
    { name: 'Threat', width: 'w-1/8' },
    { name: 'Status', width: 'w-1/12' },
    { name: 'Actions', width: 'w-1/12' },
  ];

  const sortableColumns = ['Time'];

  const [sortType, setSortType] = useState<number>(initialSortType);

  const sortingOrder: Order[] = ['asc', 'desc'];

  const [selectedThreats, setSelectedThreats] = useState<ThreatMetric[]>([]);

  const handleSort = () => {
    setSortType((prevSortType) => {
      const currentIndex = (prevSortType + 1) % sortingOrder.length;
      // sorting callback for the table
      onSort(sortingOrder[currentIndex]);
      return currentIndex;
    });
  };

  const getIconForHeader = (type: HeaderWithIcon) => {
    return type === 'Attachments' ? (
      <IconAttachment className="w-4 h-4" />
    ) : (
      <IconLink className="w-3 h-3" />
    );
  };

  const statusOptions: EventStatus[] = ['Pending', 'Remediated'];

  const [selectedStatus, setSelectedStatus] = useState('all');

  const handleSelectedStatus = (status: EventStatus | 'all') => {
    setSelectedStatus(status);

    if (status === 'Pending') onFilter({ status: 'pending', threats: selectedThreats });
    else if (status === 'Remediated') onFilter({ status: 'remediated', threats: selectedThreats });
    else onFilter({ status: 'all', threats: selectedThreats });
  };

  const handleApplyFilter = (options: EventFilterOptions) => {
    onFilter({
      threats: options.threats,
      status: selectedStatus.toLowerCase() as EventStatusFilter,
    });
    setSelectedThreats(options.threats || []);
  };

  const handleRemoveThreat = (threat: string) => {
    setSelectedThreats((prev) => {
      const filteredThreats = prev.filter((t) => t.code !== threat);
      onFilter({
        threats: filteredThreats,
        status: selectedStatus.toLowerCase() as EventStatusFilter,
      });
      return filteredThreats;
    });
  };

  const [OrgId] = useOrgId();

  const { data } = useGetFlaggedEventStatsQuery({
    from: from || '',
    to: to || '',
    orgId: OrgId,
    threats: selectedThreats.map((threat) => threat.code).join(','),
  });

  const getStatusData = (status: EventStatus): number => {
    if (data) return data.find((d) => d.status === status)?.count || 0;
    return 0;
  };

  const getFallback = () => {
    if (!events?.length) {
      return (
        <td colSpan={eventHeaderColumns.length}>
          <NoThreatFallback />
        </td>
      );
    }

    if (isLoading) {
      return (
        <td colSpan={eventHeaderColumns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    return null;
  };

  return (
    <div className="w-full overflow-auto px-2">
      <div className="pb-8 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <button
            type="button"
            className={`${selectedStatus === 'all' ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary flex items-center gap-2`}
            onClick={() => handleSelectedStatus('all')}
          >
            All{' '}
            <span className="text-sm text-gray-text">
              {data?.reduce((sum, metric) => sum + metric.count, 0) || '0'}
            </span>
          </button>
          {statusOptions.map((status) => (
            <button
              type="button"
              className={`${status === selectedStatus ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary flex items-center gap-2`}
              onClick={() => handleSelectedStatus(status)}
            >
              {status} <span className="text-sm text-gray-text">{getStatusData(status)}</span>
            </button>
          ))}
        </div>
        <div className="flex items-center gap-4">
          <EventFilter
            from={from || ''}
            to={to || ''}
            onApply={handleApplyFilter}
            selectedThreats={selectedThreats}
          />
        </div>
      </div>
      {selectedThreats.length > 0 && (
        <div className="flex items-center gap-4 pb-8">
          {selectedThreats.map((threat) => {
            return (
              <button
                className="px-3 py-2 flex items-center gap-2 bg-border-primary rounded-full"
                type="button"
              >
                <span className="text-sm">{threat.label}</span>
                <IconCross className="w-3 h-3" onClick={() => handleRemoveThreat(threat.code)} />
              </button>
            );
          })}
        </div>
      )}
      <table className="w-full bg-white overflow-auto table-fixed">
        <colgroup>
          {eventHeaderColumns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-sm text-light-grey w-full border-y-light border-y-border-primary">
          <tr>
            {eventHeaderColumns.map((header) => {
              const isSortable = sortableColumns.includes(header.name);
              return (
                <th
                  key={header.name}
                  scope="col"
                  className={`py-6 px-3 font-normal text-start ${isSortable ? 'cursor-pointer' : ''} ${header.width}`}
                  onClick={() => {
                    if (isSortable) {
                      handleSort();
                    }
                  }}
                >
                  <div className="inline-flex items-center gap-1">
                    {['Attachments', 'URL'].includes(header.name) ? (
                      getIconForHeader(header.name as HeaderWithIcon)
                    ) : (
                      <span>{header.name}</span>
                    )}
                    {isSortable && <Sort sortOrder={sortingOrder[sortType]} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="w-full">
          {isLoading || !events?.length
            ? getFallback()
            : events.map((event) => <EventRow key={event.messageId} event={event} />)}
        </tbody>
      </table>
    </div>
  );
}

export default EventTable;
