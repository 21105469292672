import dayjs from 'dayjs';

interface Frequency {
  date: string;
  count: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function TransformToTimeseries(from: string, to: string, data: Frequency[]) {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);

  const timeseriesData: Frequency[] = [];

  // Extract additional keys from the first entry in the data, if available
  const additionalKeys =
    data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'date' && key !== 'count') : [];

  for (
    let currentDate = fromDate;
    currentDate.isBefore(toDate) || currentDate.isSame(toDate, 'day');
    currentDate = currentDate.add(1, 'day')
  ) {
    const formattedDate = currentDate.format('YYYY-MM-DD');
    const inputEntry = data.find((item) => dayjs(item.date).isSame(currentDate, 'day'));

    const transformedEntry: Frequency = {
      date: formattedDate,
      count: inputEntry ? inputEntry.count : 0,
    };

    additionalKeys.forEach((key) => {
      transformedEntry[key] = inputEntry ? inputEntry[key] || 0 : 0;
    });

    timeseriesData.push(transformedEntry);
  }

  return timeseriesData;
}
