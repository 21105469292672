import { useEffect, useState } from 'react';

import Card from 'components/Card';
import { CardContent } from 'components/Card/CardContent';
import CardHeader from 'components/Card/CardHeader';
import * as Select from '@radix-ui/react-select';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ActionToast } from 'components/Settings/AddToast';
import {
  useAddVIPUsersMutation,
  useRemoveVIPUsersMutation,
  useAddFinanceAndAccUsersMutation,
  useRemoveFinanceAndAccUsersMutation,
  useGetUsersQuery,
} from 'service/users';
import { UserListItem, AddUser } from 'components/Settings/UserListItem';

interface AddUserRolesProps {
  onClose: () => void;
  defaultRole?: string;
}

export function AddUserRoles({ onClose, defaultRole }: AddUserRolesProps): JSX.Element {
  const { showToast, setOpen: setToastOpen } = useToast();

  const [allUsers, setAllUsers] = useState<AddUser[]>([]);

  const [addedUsers, setAddedUsers] = useState<AddUser[]>([]);

  // search query is used to maintain the state of the input
  // while the user is typing
  const [searchQuery, setSearchQuery] = useState('');

  // debouned query is used to maintain the state for sending debounded
  // the query to the backend
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  useEffect(() => {
    // Set a timeout to update the debouncedSearchQuery after the specified delay
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    // Clear the timeout if searchQuery changes before the delay period elapses
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const [OrgId] = useOrgId();
  const { data } = useGetUsersQuery(
    {
      limit: 5,
      offset: 0,
      searchQuery: debouncedSearchQuery,
      orgId: OrgId,
    },
    {
      skip: debouncedSearchQuery === '',
      refetchOnMountOrArgChange: true,
    }
  );

  const [formValues, setFormValues] = useState({
    role: {
      value: defaultRole,
      error: '',
    },
  });

  const handleAdd = (id: string) => {
    const addedUser = allUsers.find((user) => user.id === id);
    if (addedUser) {
      setAddedUsers([...addedUsers, addedUser]);
    }
  };

  useEffect(() => {
    if (data) {
      setAllUsers(
        data.hits
          .filter((hit) => !addedUsers.some((addedUser) => addedUser.id === hit.id))
          .map((user) => ({ ...user, added: false }))
      );
    }
  }, [data, addedUsers]);

  const handleRemove = (id: string) => {
    setAddedUsers(addedUsers.filter((user) => user.id !== id));
  };

  const [addVIPUsers] = useAddVIPUsersMutation();

  const [removeVIPUsers] = useRemoveVIPUsersMutation();

  const [addFinanceAccUsers] = useAddFinanceAndAccUsersMutation();

  const [removeFinanceAccUsers] = useRemoveFinanceAndAccUsersMutation();

  const handleRemoveUsers = async (userIds: string[]) => {
    try {
      await removeVIPUsers({
        orgId: OrgId,
        userIds: userIds,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleRemoveFinanceAccUsers = async (userIds: string[]) => {
    try {
      await removeFinanceAccUsers({
        orgId: OrgId,
        userIds: userIds,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleSave = async () => {
    try {
      const addedUserIds = addedUsers.map((user) => user.id);
      const selectedRole = formValues.role.value;

      if (selectedRole === 'vip') {
        await addVIPUsers({
          orgId: OrgId,
          userIds: addedUserIds,
        });

        showToast({
          component: (
            <ActionToast
              users={addedUsers}
              roleType="VIP"
              onUndo={() => {
                handleRemoveUsers(addedUserIds);
                setToastOpen(false);
              }}
            />
          ),
        });
      } else if (selectedRole === 'finance-accounting') {
        await addFinanceAccUsers({
          orgId: OrgId,
          userIds: addedUserIds,
        });

        showToast({
          component: (
            <ActionToast
              users={addedUsers}
              roleType="Finance & Accounting"
              onUndo={() => {
                handleRemoveFinanceAccUsers(addedUserIds);
                setToastOpen(false);
              }}
            />
          ),
        });
      } else {
        throw new Error('Invalid role selected');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving user roles:', error);
    } finally {
      onClose();
    }
  };

  const roleTypeOptions = [
    {
      label: 'VIP',
      value: 'vip',
    },
    {
      label: 'Finance & Accounting',
      value: 'finance-accounting',
    },
  ];

  const handleSelectChange = (value: string) => {
    setFormValues({
      ...formValues,
      role: {
        value,
        error: '',
      },
    });
  };

  const filteredUsers = allUsers.filter((user) => {
    if (formValues.role.value === 'vip') {
      return !user.vip;
    }
    if (formValues.role.value === 'finance-accounting') {
      return !user.financeAndAccounting;
    }
    return true;
  });

  return (
    <Card width="100%">
      <CardHeader>
        <span>Assign Role</span>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-6">
          <div className="border-light border-border-primary p-2 flex items-center gap-2 rounded-md w-full">
            <IconSearch className="w-3 h-3 fill-light-grey" />
            <input
              type="text"
              placeholder="Search"
              className="w-full bg-transparent outline-none text-xs placeholder:font-light placeholder:text-light-grey"
              onChange={handleSearch}
            />
          </div>
          {formValues.role.error && (
            <span className="text-2xs text-red-500">{formValues.role.error}</span>
          )}
          <Select.Root onValueChange={handleSelectChange} defaultValue={defaultRole}>
            <Select.Trigger className="border-light border-border-primary text-black placeholder:text-light-grey p-2 rounded-md text-xs flex items-center justify-between bg-white">
              <Select.Value placeholder="Choose a role" className="placeholder:text-light-grey" />
              <Select.Icon>
                <IconArrowDown className="w-4 h-4" />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content
                className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2"
                side="bottom"
                sideOffset={20}
              >
                <Select.Viewport>
                  {roleTypeOptions.map((option) => (
                    <Select.Item
                      key={option.value}
                      value={option.value}
                      className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                    >
                      <Select.ItemText>{option.label}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Viewport>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
          <div className="flex flex-col gap-6">
            {addedUsers.map((user) => (
              <UserListItem
                key={user.id}
                name={user.name}
                vip={user.vip}
                financeAndAccounting={user.financeAndAccounting}
                id={user.id}
                email={user.email}
                added
                onAdd={() => handleRemove(user.id)}
              />
            ))}
            {filteredUsers.map((user) => (
              <UserListItem
                key={user.id}
                name={user.name}
                vip={user.vip}
                financeAndAccounting={user.financeAndAccounting}
                id={user.id}
                email={user.email}
                added={false}
                onAdd={() => handleAdd(user.id)}
              />
            ))}
          </div>
        </div>
      </CardContent>
      <div className="flex items-center justify-center gap-4 py-5 px-8 border-t-light border-t-border-primary">
        <button
          type="button"
          className="flex-1 rounded-full border-black/20 border-light text-black/20 px-8 py-2 text-xs hover:text-black hover:border-black"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`flex-1 ${addedUsers.length === 0 ? 'bg-black/20' : 'bg-black'} px-8 py-2 rounded-full text-white text-xs`}
          onClick={() => handleSave()}
        >
          Save
        </button>
      </div>
    </Card>
  );
}
