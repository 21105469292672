import { useState } from 'react';
import { useOrgId } from 'hooks/useOrgId';
import { GetUsersFilter } from 'models/user';
import { useGetAdminsQuery } from 'service/users';
import { useAuth } from 'contexts/AuthContext';

import { AddAdminForm } from './AddAdminForm';
import { AdminTable } from './AdminTable';

export function AccessControl() {
  const [filters, setFilters] = useState<GetUsersFilter>({
    limit: 10,
    offset: 0,
    searchQuery: '',
  });

  const [orgId] = useOrgId();

  const { data, isLoading } = useGetAdminsQuery({
    orgId,
    limit: filters.limit,
    offset: filters.offset,
  });

  const [showAdminForm, setShowAdminForm] = useState(false);

  const handleOpenForm = () => {
    setShowAdminForm(true);
  };

  const handleCloseForm = () => {
    setShowAdminForm(false);
  };

  const { superadmin } = useAuth();

  return (
    <div className="px-12 py-10 w-full flex flex-col gap-6">
      <div className="w-full flex items-center justify-between">
        <span className="text-light-grey">Admin Roles</span>
        {superadmin ? (
          <AddAdminForm
            open={showAdminForm}
            handleOpenForm={handleOpenForm}
            handleCloseForm={handleCloseForm}
          />
        ) : null}
      </div>
      <div className="w-full">
        <AdminTable
          admins={data?.hits || []}
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
          total={data?.total || 0}
          showAdminForm={() => setShowAdminForm(true)}
        />
      </div>
    </div>
  );
}
