import { useDLPForm } from 'contexts/DLPFormContext';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as IconBlock } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconCorrect } from 'assets/icons/icon-correct.svg';

import { Divider } from 'components/Divider';
import { InfoTooltip } from 'components/Tooltip/TooltipInfo';
import { BlockForm } from './BlockForm';
import { ExceptionForm } from './ExceptionForm';

export function PolicyRule() {
  const { formDetails, setFormDetails } = useDLPForm();

  const messages = {
    block:
      'By default, DLP is set to a global block rule to protect all sensitive data in your organisation.',
    allow:
      'Add an exception by defining who can send sensitive data & to which IDs , domains & user groups.',
  };

  return (
    <div className="px-12 py-8">
      <div className="flex flex-col gap-6">
        <span className="text-xl">What do you want to do ?</span>
        <div className="flex flex-col gap-8">
          <div className="flex items-center gap-6">
            <RadioGroup.Root>
              <RadioGroup.Item
                checked={formDetails.isExternal || false}
                className="w-4 h-4 rounded-full border border-gray-300 bg-white focus:outline-none hover:bg-gray-50 data-[state=checked]:border-black"
                value="internal"
                onClick={() =>
                  setFormDetails({ ...formDetails, isExternal: true, isInternal: false })
                }
              >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:block after:w-2.5 after:h-2.5 after:rounded-full after:bg-black" />
              </RadioGroup.Item>
            </RadioGroup.Root>
            <div
              className={`px-4 py-3 rounded-2xl border-light border-border-primary ${formDetails.isExternal ? 'bg-white' : 'font-normal bg-transparent'} flex items-center w-fit gap-4`}
            >
              <div
                className={`flex items-center justify-center w-10 h-10 ${formDetails.isExternal ? 'bg-soft-purple' : 'bg-white'} rounded-lg border-light border-border-primary`}
              >
                <IconGlobe />
              </div>
              <span>Protect data sent out externally</span>
            </div>
            <div className="text-sm flex items-center gap-2">
              <InfoIcon className="w-3.5 h-3.5 fill-black" /> You will be able to configure specific
              actions for external violations
            </div>
          </div>
          <div className="flex items-center gap-6">
            <RadioGroup.Root>
              <RadioGroup.Item
                checked={formDetails.isInternal || false}
                className="w-4 h-4 rounded-full border border-gray-300 bg-white focus:outline-none hover:bg-gray-50 data-[state=checked]:border-black"
                value="external"
                onClick={() =>
                  setFormDetails({ ...formDetails, isInternal: true, isExternal: false })
                }
              >
                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:block after:w-2.5 after:h-2.5 after:rounded-full after:bg-black" />
              </RadioGroup.Item>
            </RadioGroup.Root>
            <div
              className={`px-4 py-3 rounded-2xl border-light border-border-primary ${formDetails.isInternal ? 'bg-white' : 'font-normal bg-transparent'} flex items-center w-fit gap-4`}
            >
              <div
                className={`flex items-center justify-center w-10 h-10 ${formDetails.isInternal ? 'bg-soft-purple' : 'bg-white'} rounded-lg border-light border-border-primary`}
              >
                <IconIndustry />
              </div>
              <span>Protect data sent internally</span>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 flex items-center justify-center bg-white border-light border-border-primary rounded-lg">
            <IconBlock className="w-4 h-4 fill-error-text" />
          </div>
          <span className="text-xl">Block Rule</span>
          <InfoTooltip icon={<InfoIcon className="fill-light-grey" />} message={messages.block} />
        </div>
        <div className="border-light border-border-primary bg-soft-red p-4 flex gap-2 rounded-lg">
          <InfoIcon className="fill-error-text" />
          <span className="text-sm">
            We suggest you keep your block rule applied to everyone in your organisation as this
            rule ensures that data never leaks from your organisation even if your exceptions aren’t
            configured efficiently. To override this, click on the lock icon below.
          </span>
        </div>
        <BlockForm />
      </div>
      <Divider />
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 flex items-center justify-center bg-white border-light border-border-primary rounded-lg">
            <IconCorrect className="w-4 h-4" />
          </div>
          <span className="text-xl">Add Exceptions</span>
          <InfoTooltip icon={<InfoIcon className="fill-light-grey" />} message={messages.allow} />
        </div>
        <ExceptionForm />
      </div>
    </div>
  );
}
