import { createApi } from '@reduxjs/toolkit/query/react';
import { Profile, UserProfile } from 'models/profileOverview';

import { GET_USER_PROFILE, PROFILE_OVERVIEW_URL } from 'constants/apiUrls';

import { setUserLoading } from 'redux-store/slices/users';

import { baseQuery } from './baseQuery';

export const profileOverviewApi = createApi({
  reducerPath: 'profileOverviewApi',
  baseQuery: baseQuery,
  tagTypes: ['PROFILE', 'USER_PROFILE'],
  endpoints: (builder) => ({
    getProfileOverview: builder.query<Profile, { orgId: string; userId: string }>({
      query: ({ orgId, userId }) => `${PROFILE_OVERVIEW_URL(orgId, userId)}`,
      providesTags: ['PROFILE'],
      async onCacheEntryAdded(_, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
        await cacheDataLoaded;

        dispatch(setUserLoading(false));

        await cacheEntryRemoved;
      },
    }),
    getProfile: builder.query<UserProfile, { orgId: string }>({
      query: ({ orgId }) => GET_USER_PROFILE(orgId),
      providesTags: ['USER_PROFILE'],
    }),
    updateNotificationPreference: builder.mutation<
      undefined,
      { orgId: string; preference: boolean }
    >({
      query: ({ orgId, preference }) => ({
        url: `${GET_USER_PROFILE(orgId)}/notification`,
        method: 'PUT',
        body: {
          emailAlert: preference,
        },
      }),
      invalidatesTags: ['USER_PROFILE'],
    }),
  }),
});

export const {
  useGetProfileOverviewQuery,
  useGetProfileQuery,
  useUpdateNotificationPreferenceMutation,
} = profileOverviewApi;
