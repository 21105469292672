import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Avatar from 'components/Avatar';
import { BlockAndAllowRules } from 'components/Rules/BlockAndAllowRules';
import { RemediationSettings } from 'components/Rules/RemediationSettings';
import { useAuth } from 'contexts/AuthContext';
import { useQueryParam } from 'hooks/useQueryParam';
import { useEffect, useState } from 'react';
import { getInitials } from 'utils/name';

export default function Rules() {
  const [tab, setTab] = useQueryParam({ key: 'tab' });

  const [selectedNavOption, setSelectedNavOption] = useState(0);

  useEffect(() => {
    if (tab !== '') {
      const selectedOption = parseInt(tab, 2);
      setSelectedNavOption(selectedOption);
    }
  }, [tab]);

  const navigationOptions = [
    {
      title: 'Remediation Settings',
      onClick: () => {
        setSelectedNavOption(0);
        setTab('0');
      },
    },
    {
      title: 'Block & Allow Rules',
      onClick: () => {
        setSelectedNavOption(1);
        setTab('1');
      },
    },
  ];

  const { logout, name } = useAuth();

  const handleSetSelectedNavOption = (index: number) => {
    setSelectedNavOption(index);
    navigationOptions[index].onClick();
  };

  const getTabComponent = () => {
    return selectedNavOption === 0 ? (
      <RemediationSettings navigateToBlockList={() => setSelectedNavOption(1)} />
    ) : (
      <BlockAndAllowRules />
    );
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full px-8 py-5 sticky top-0 bg-white z-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl">Rules</h2>
          </div>
          <div className="flex gap-8 items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="text-end" asChild>
                <div>
                  <Avatar
                    initials={getInitials(name, 2)} // a placeholder for the initials
                    bgColor="soft-blue"
                    textColor="avatar-blue"
                  />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <div className="w-full">
                  <DropdownMenu.Content
                    className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
                    side="left"
                  >
                    <DropdownMenu.Item
                      className="px-3 py-1.5 hover:bg-select-background hover:text-red-500 text-light-grey bg-transparent cursor-pointer outline-none"
                      onClick={() => logout()}
                    >
                      Logout
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </div>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </div>
      </div>
      <div className="px-8">
        <hr className="border-light border-border-primary" />
      </div>
      <div className="flex-1 flex overflow-hidden">
        <div className="sticky top-0 h-screen px-8 py-10 flex flex-col gap-8 border-r-light border-border-primary">
          {navigationOptions.map((navigationOption, index) => (
            <button
              key={navigationOption.title}
              type="button"
              className={`flex items-center gap-10 font-medium px-4 py-2 ${index === selectedNavOption ? 'rounded-md border-light border-border-primary text-black' : 'text-light-grey'} text-base justify-between`}
              onClick={() => handleSetSelectedNavOption(index)}
            >
              {navigationOption.title}
            </button>
          ))}
          <button
            type="button"
            className="flex items-center gap-10 font-medium px-4 py-2 text-light-grey text-base justify-between cursor-not-allowed"
          >
            Policies
            <span className="text-2xs text-white  px-1 rounded-[4px] bg-coming-soon-bg">
              Coming Soon
            </span>
          </button>
        </div>
        <div className="flex-1 overflow-auto">{getTabComponent()}</div>
      </div>
    </div>
  );
}
