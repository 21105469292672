import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { useOrgId } from 'hooks/useOrgId';
import { useGetViolationQuery } from 'service/dlp';
import { DLPViolation } from 'components/DLP/DLPIncidentDetails';

export function ViolationDetails() {
  const { violationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [OrgId] = useOrgId();

  // Prevent body scrolling when overlay is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { data, isLoading } = useGetViolationQuery(
    { violationId: violationId || '', orgId: OrgId },
    {
      skip: !violationId,
    }
  );

  const handleClose = () => {
    // Navigate back to the previous route, or to / if no previous state
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate('/dlp/incidents');
    }
  };

  return <DLPViolation open data={data} loading={isLoading} onClose={() => handleClose()} />;
}
