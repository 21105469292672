import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { getInitials } from 'utils/name';
import Avatar from 'components/Avatar';
import { useAuth } from 'contexts/AuthContext';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function DLP() {
  const { logout, name } = useAuth();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const navigationOptions = [
    {
      title: 'Manage Incidents',
      onClick: () => navigate('/dlp/incidents'),
      path: ['/dlp/incidents'],
    },
    {
      title: 'Manage Policies',
      onClick: () => navigate('/dlp/list'),
      path: ['/dlp/list', '/dlp/create'],
    },
  ];

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full px-8 py-5 sticky top-0 bg-white z-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h2 className="text-2xl">Data Loss Prevention</h2>
          </div>
          <div className="flex gap-8 items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="text-end" asChild>
                <div>
                  <Avatar
                    initials={getInitials(name, 2)} // a placeholder for the initials
                    bgColor="soft-blue"
                    textColor="avatar-blue"
                  />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <div className="w-full">
                  <DropdownMenu.Content
                    className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
                    side="left"
                  >
                    <DropdownMenu.Item
                      className="px-3 py-1.5 hover:bg-select-background hover:text-red-500 text-light-grey bg-transparent cursor-pointer outline-none"
                      onClick={() => logout()}
                    >
                      Logout
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </div>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </div>
      </div>
      <div className="px-8">
        <hr className="border-light border-border-primary" />
      </div>
      <div className="flex-1 flex overflow-hidden">
        <div className="sticky top-0 h-screen px-8 py-10 flex flex-col gap-8 border-r-light border-border-primary">
          {navigationOptions.map((option) => (
            <button
              key={option.title}
              type="button"
              className={`flex items-center gap-10 font-medium px-4 py-2 ${option.path.includes(pathname) ? 'rounded-md border-light border-border-primary text-black' : 'text-light-grey border-light border-transparent'} text-base justify-between`}
              onClick={() => option.onClick()}
            >
              {option.title}
            </button>
          ))}
        </div>
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
