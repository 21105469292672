import * as Dialog from '@radix-ui/react-dialog';

import { DLPIncidentDetails } from 'models/dlp';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';

import { ReactComponent as IconWarning } from 'assets/icons/alert.svg';
import { dateUtils } from 'utils/time';
import { Divider } from 'components/Divider';

import Loader from 'components/Loader';
import { toTitleCase } from 'utils/name';
import { getEmailDetails } from 'components/Events/data';

interface DLPViolationProps {
  open: boolean;
  onClose: () => void;
  data?: DLPIncidentDetails;
  loading: boolean;
}

export function DLPViolation({ open, onClose, data, loading }: DLPViolationProps) {
  if (!data || loading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 z-50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4 z-50"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 h-overview-modal  bg-white rounded-3xl">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-40" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-1/3 z-40"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full bg-white rounded-2xl border-light border-border-primary relative flex flex-col">
            <button
              type="button"
              className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
              onClick={() => onClose()}
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <IconClose className="w-2.5 h-2.5 fill-light-grey" />
            </button>
            <div className="p-6 border-b-light border-border-primary flex items-center gap-4">
              <div className="w-10 h-10 rounded border-light border-border-primary flex justify-center items-center">
                <IconWarning className="w-5 h-5 fill-error-text" />
              </div>
              <span>Incident Details</span>
            </div>
            <div className="w-full max-h-overview-modal overflow-hidden overflow-y-scroll no-scrollbar">
              <div className="p-6 border-b-light border-border-primary flex flex-col gap-3">
                <span className="text-sm text-light-grey">Policy Triggered</span>
                <div className="flex items-center flex-wrap gap-3">
                  <button
                    type="button"
                    className="border-light border-border-primary px-2.5 py-2 flex items-center gap-2 rounded-lg"
                  >
                    <span>{data.name}</span>
                  </button>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex flex-col gap-3">
                    <span className="text-light-grey text-sm">Detection Date</span>
                    <span className="text-sm">
                      {dateUtils.format(data.createdAt, 'DD/MM/YYYY, hh:mm:ss').toString()}
                    </span>
                  </div>
                  <div className="flex flex-col gap-3">
                    <span className="text-light-grey text-sm">Policy Type</span>
                    <button
                      type="button"
                      className={`px-2 py-1 rounded-lg border-light border-border-primary text-sm ${data.scope === 'external' ? 'bg-soft-purple' : 'bg-soft-blue'} flex items-center gap-2`}
                    >
                      {data.scope === 'external' ? (
                        <IconGlobe className="w-4 h-4" />
                      ) : (
                        <IconIndustry className="w-4 h-4" />
                      )}
                      {toTitleCase(data.scope)}
                    </button>
                  </div>
                  <div className="flex flex-col gap-3">
                    <span className="text-light-grey text-sm">Policy Status</span>
                    <button
                      type="button"
                      className="px-2 py-1 rounded border-light border-border-primary bg-soft-blue text-sm"
                    >
                      Monitor Mode
                    </button>
                  </div>
                </div>
                <Divider />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-4 w-full">
                    <span className="text-light-grey text-sm">Email Details</span>
                    <div className="w-full rounded-2xl border-light border-border-primary px-8 py-6 flex flex-col gap-4 bg-soft-gray">
                      <div className="flex items-center text-sm">
                        <p className="text-light-grey w-28">From :</p>{' '}
                        {getEmailDetails(data.sender)}
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="text-sm text-light-grey w-28">To :</p>{' '}
                        {data?.recipients?.length > 1
                          ? `${data.recipients.length} Recipients`
                          : getEmailDetails(data.recipients?.[0])}
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="text-sm text-light-grey w-28">Subject : </p> {data.subject}
                      </div>
                      <div className="flex items-center text-sm">
                        <p className="text-sm text-light-grey w-28">Date & Time : </p>{' '}
                        {dateUtils.format(data.sentAt, 'DD/MM/YYYY, hh:mm:ss').toString()}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 w-full">
                    <span className="text-light-grey text-sm">Detected Sensitive Data</span>
                    <div className="rounded-2xl border-light border-border-primary bg-soft-red">
                      {data?.violations.map((violation, index) => (
                        <>
                          <div className="flex flex-col gap-4 px-7 py-6">
                            <span className="text-sm">{violation.subCategory}</span>
                            <button
                              type="button"
                              className="border-light border-border-primary rounded py-1 px-2 bg-soft-gray w-fit text-sm text-start"
                            >
                              Detected : &quot;{violation.content}&quot;
                            </button>
                          </div>
                          {index !== data.violations.length - 1 ? (
                            <Divider customCss="my-1" />
                          ) : null}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t-light border-border-primary p-6">
              <button
                type="button"
                className="rounded-lg border-light border-border-primary px-6 py-2 text-sm"
                onClick={() => onClose()}
              >
                Close
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
