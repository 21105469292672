import * as Select from '@radix-ui/react-select';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { ReactComponent as IconStar } from 'assets/icons/star.svg';
import { useState } from 'react';
import { AdminUser } from 'models/admin';

export interface AddUser extends AdminUser {
  added?: boolean;
}

export function AdminListItem({
  name,
  vip,
  added,
  role,
  onAdd,
  handleChangeRole,
}: AddUser & { onAdd: () => void; handleChangeRole: (newRole: string) => void }): JSX.Element {
  const [showAddButton, setShowAddButton] = useState(false);

  const roleOptions = [
    {
      value: 'mailadmin',
      label: 'Administrator',
    },
    {
      value: 'superadmin',
      label: 'Super Administrator',
    },
  ];

  const showActionButton = () => {
    if (added) {
      return (
        <button
          type="button"
          className="bg-light-grey text-white px-3 py-1 rounded-lg text-xs"
          onClick={() => onAdd()}
        >
          Added
        </button>
      );
    }

    if (showAddButton) {
      return (
        <button
          type="button"
          className="bg-avatar-blue text-white px-3 py-1 rounded-lg text-xs"
          onMouseLeave={() => setShowAddButton(false)}
          onClick={() => onAdd()}
        >
          Add
        </button>
      );
    }

    return (
      <button
        type="button"
        className="w-6 h-6 justify-center items-center text-sm border border-border-primary text-light-grey rounded-lg"
        onMouseEnter={() => setShowAddButton(true)}
      >
        +
      </button>
    );
  };

  const handleSelect = (value: string) => {
    handleChangeRole(value);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Avatar
          initials={getInitials(name, 2)}
          bgColor="avatar-blue"
          textColor="white"
          customCssClass="w-6 h-6 p-1 text-[0.5rem]"
        />
        <span className="text-xs">{name}</span>
        {vip && <IconStar className="w-3 h-3" />}
      </div>
      <div className="flex items-center gap-2">
        <Select.Root defaultValue="30" onValueChange={handleSelect} value={role}>
          <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
            <Select.Value />
            <Select.Icon>
              <IconArrowDown className="w-4 h-4" />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2 z-50">
              <Select.Viewport>
                {roleOptions.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                  >
                    <Select.ItemText>{option.label}</Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        {showActionButton()}
      </div>
    </div>
  );
}
