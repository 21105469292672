import { useState, useMemo } from 'react';
import { useOrgId } from 'hooks/useOrgId';
import { useGetThreatCategoryStatsQuery, useGetThreatStatsQuery } from 'service/threats';
import { useGetThreatInferencesQuery } from 'service/threatInferences';

import { ThreatFrequencyFilters, ThreatType } from 'models/threatInferences';

import DateFilter from 'components/DateFilter';
import { Navbar } from 'components/Layouts/Navbar';
import Statistics from 'components/Statistics';
import { Circle } from 'components/Marker/Circle';
import { Divider } from 'components/Divider';
import { getStrokeColor } from 'components/Threats/data';
import { getInferenceDetailsByCode } from 'components/Events/data';
import { Frequency } from 'components/Threats/Frequency';
import { NoThreatFallback } from 'components/Threats/NoThreatFallback';
import Loader from 'components/Loader';

import { TabCategory, ThreatCategory } from 'enums/threatCategory';

import { dateUtils } from 'utils/time';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ThreatWarningIcon } from 'assets/icons/threat-warning.svg';
import { DeviationBadge } from 'components/Badge/DeviationBadge';

function Analytics() {
  const [OrgId] = useOrgId();

  const [filters, setFilters] = useState<ThreatFrequencyFilters>({
    orgId: OrgId,
    from: dateUtils.subtract(new Date().toDateString(), 6, 'd').toDate().toISOString(),
    to: new Date().toISOString(),
    frequency: 'daily',
  });

  const handleDateFilters = (from: string, to: string) => {
    setFilters({
      ...filters,
      from,
      to,
    });
  };

  const { data: threatCategories, isLoading: isCategoryLoading } = useGetThreatCategoryStatsQuery({
    orgId: OrgId,
    from: filters.from || '',
    to: filters.to || '',
  });

  const [category, setCategory] = useState<TabCategory>('all');

  const tabIdToName: Record<string, string> = {
    phishing: 'Phishing',
    malware: 'Malware',
    bec: 'BEC',
    insider_threat: 'Insider Threat',
  };

  const getTabDisplayName = (id: string): string => {
    return tabIdToName[id] || id;
  };

  const handleSetCategory = (threatCategory: TabCategory) => {
    setCategory(threatCategory);
  };

  const { data: threats } = useGetThreatStatsQuery(
    {
      orgId: OrgId,
      threatCategory: (category === 'all' ? '' : category) as ThreatCategory,
      from: filters.from,
      to: filters.to,
    },
    {
      skip: threatCategories ? threatCategories.length === 0 : true,
    }
  );

  const tabCategoryToThreats = useMemo<Record<string, string>>(
    () => ({
      impersonation: `${ThreatType.VIPImpersonation},${ThreatType.VENDORImpersonation},${ThreatType.REGULATORImpersonation}`,
      malware: ThreatType.MALWARE,
      bec: `${ThreatType.BEC},${ThreatType.FINANCIAL_FRAUD},${ThreatType.ATTORNEY_FRAUD},${ThreatType.INVOICE_FRAUD}`,
      insider_threat: ThreatType.INSIDER_THREAT,
    }),
    []
  );

  const { data: inferenceData } = useGetThreatInferencesQuery({
    orgId: OrgId,
    threat: threatCategories && category !== 'all' ? tabCategoryToThreats[category] : '',
    from: filters.from,
    to: filters.to,
  });

  const getFallback = () => {
    return isCategoryLoading ? <Loader /> : <NoThreatFallback />;
  };

  return (
    <div className="w-full px-8 py-5">
      <Navbar title="Analytics" />
      <div className="flex items-center justify-between">
        <Statistics from={filters.from || ''} to={filters.to || ''} />
        <DateFilter onClick={handleDateFilters} />
      </div>
      <div className="mt-6 w-full rounded-md bg-white flex">
        {threatCategories?.length && threatCategories.length > 0 ? (
          <>
            <div className="w-2/5 px-8 py-6 flex flex-col justify-between border-r-light border-border-primary gap-6">
              <div>
                <div className="flex flex-wrap gap-4">
                  <button
                    type="button"
                    className={`${category === 'all' ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary`}
                    onClick={() => handleSetCategory('all')}
                  >
                    All{' '}
                    <span className="text-light-grey ml-1">
                      {threatCategories?.reduce((acc, curr) => acc + curr.count, 0) || '0'}
                    </span>
                  </button>
                  {threatCategories?.map((threatCategory) => (
                    <button
                      type="button"
                      className={`${threatCategory.code === category ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full px-4 py-2 text-sm border-light border-border-primary`}
                      onClick={() => handleSetCategory(threatCategory.code as TabCategory)}
                    >
                      {getTabDisplayName(threatCategory.code)}{' '}
                      <span className="text-light-grey ml-1">{threatCategory.count}</span>
                    </button>
                  ))}
                </div>
                <Divider />
                <div className="flex flex-col gap-6">
                  <span className="text-sm text-light-grey">Threats</span>
                  {threats?.threats?.length ? (
                    threats?.threats?.map((threat) => {
                      return (
                        <div key={threat.code} className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Circle color={getStrokeColor(threat.code)} />
                            <span className="text-black text-sm">{threat.label}</span>
                          </div>
                          <div className="flex items-center gap-4">
                            <DeviationBadge deviation={threat.deviation} />
                            <span className="text-sm">{threat.count}</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-sm">No Threats Found</span>
                  )}
                </div>
              </div>
              <div className="bg-soft-sand flex items-center px-2 py-1 gap-2 rounded-md">
                <InfoIcon className="w-6 h-6" />
                <span className="text-sm text-soft-brown">
                  Threat counts are categorized based on primary detection methods to avoid
                  duplication across categories.
                </span>
              </div>
            </div>
            <div className="w-3/5">
              <div className="px-8 py-6 w-full">
                <span className="text-sm text-light-grey">Threats</span>
                <Frequency tab={category} from={filters.from || ''} to={filters.to || ''} />
              </div>
              <Divider customCss="my-1" />
              <div className="px-8 py-6 w-full flex flex-col gap-6">
                <span className="text-sm text-light-grey">AI Threat Detections</span>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  {inferenceData?.hits?.length ? (
                    inferenceData?.hits?.map((inference) => {
                      const inferenceDetails = getInferenceDetailsByCode(inference.code);
                      return (
                        <div key={inference.code} className="flex items-center justify-between">
                          <div className="flex items-center gap-4">
                            <ThreatWarningIcon className="W-4 h-4" />
                            <span className="text-black text-sm">{inferenceDetails.text}</span>
                          </div>
                          <div className="flex items-center gap-4">
                            <span className="text-sm">{inference.count}</span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <span className="text-sm">No Detections Found</span>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-4 w-full">{getFallback()}</div>
        )}
      </div>
    </div>
  );
}

export default Analytics;
