import { useState } from 'react';

import DLPIncidentTable from 'components/DLP/DLPIncidentTable';
import { DLPIncidentFilters } from 'models/dlp';
import { useListDLPIncidentQuery } from 'service/dlp';
import { useOrgId } from 'hooks/useOrgId';

export function DLPIncidentList() {
  const [filters, setsFilters] = useState<DLPIncidentFilters>({
    limit: 10,
    offset: 0,
    // severity: 'critical',
  });

  const [OrgId] = useOrgId();

  const { data, isLoading } = useListDLPIncidentQuery({
    ...filters,
    orgId: OrgId,
  });

  return (
    <div className="w-full">
      <DLPIncidentTable
        hits={data?.hits || []}
        limit={filters.limit}
        offset={filters.offset}
        setFilters={setsFilters}
        total={data?.total || 0}
        loading={isLoading}
      />
    </div>
  );
}
