import { useCallback, useState } from 'react';

import { ReactComponent as IconWarning } from 'assets/icons/icon-error-alert.svg';

import { useDLPForm } from 'contexts/DLPFormContext';
import { DLPRule } from 'models/dlp';

import { useCreateDLPRuleMutation, useUpdateDLPRuleMutation } from 'service/dlp';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useOrgId } from 'hooks/useOrgId';
import Loader from 'components/Loader';
import { useQueryParam } from 'hooks/useQueryParam';

import { WarningToast } from 'components/Toasts/WarningToast';
import { Divider } from 'components/Divider';

import { emailPartDetails, getCheckedDataTypes, hasAnyCheckedDataType } from './data';

export function PolicyPreview() {
  const [, setPId] = useQueryParam({ key: 'pId' });

  const { showToast } = useToast();

  const [orgId] = useOrgId();

  const [formLoading, setFormLoading] = useState(false);

  const { formDetails, onNext, setFormDetails, policyId, mode, setActiveStep, formSteps } =
    useDLPForm();

  const [createDLPRule] = useCreateDLPRuleMutation();
  const [updateDLPRule] = useUpdateDLPRuleMutation();

  const validatePreviewForm = useCallback((): { valid: boolean; error: string } => {
    if (!formDetails?.name?.value) {
      return { valid: false, error: 'Policy name is required' };
    }

    const hasAnyCheckedCategory = (formDetails.categories || []).some(hasAnyCheckedDataType);
    if (!hasAnyCheckedCategory) {
      return { valid: false, error: 'Please check data types to be protected' };
    }

    const hasAnyMailPartChecked = (formDetails.scan || []).some((s) => s.checked);
    if (!hasAnyMailPartChecked) {
      return { valid: false, error: 'Please check mail parts to be scanned' };
    }

    return { valid: true, error: '' };
  }, [formDetails]);

  const handleNext = async () => {
    const { valid, error } = validatePreviewForm();

    if (!valid) {
      showToast({
        component: (
          <WarningToast message={error} icon={<IconWarning className="fill-error-text" />} />
        ),
      });
      return;
    }

    setFormLoading(true);
    try {
      const sc = getCheckedDataTypes(formDetails.categories || []).map((c) => c.value) || [];
      const emailPart = formDetails?.scan?.filter((f) => f.checked)?.map((fe) => fe.name) || [];
      const request = {
        name: formDetails?.name?.value || '',
        subcategories: sc,
        emailPart,
        orgId: orgId,
      };

      let response: DLPRule;

      if (policyId) {
        response = await updateDLPRule({ ...request, policyId }).unwrap();
      } else {
        response = await createDLPRule(request).unwrap();
      }

      if (response) {
        setFormDetails((prev) => ({ ...prev, id: response.id }));

        setPId(response.id || '');
      }

      if (mode === 'edit') {
        setActiveStep(formSteps.length - 1);
      } else {
        onNext();
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong. Please try again!" />,
      });
    } finally {
      setFormLoading(false);
    }
  };

  const handleBack = () => {
    if (mode === 'edit') {
      setActiveStep(formSteps.length - 1);
    }
  };

  return (
    <div className="px-7 py-12 flex flex-col gap-4 justify-between h-full">
      <div>
        <div className="flex flex-col gap-4">
          <span className="text-sm text-light-grey">Policy Preview</span>
          <span className="text-sm text-black">
            This policy will detect mails containing the following sensitive data types
          </span>
        </div>
        <div className="mt-6 flex flex-col gap-2">
          {formDetails?.categories?.length
            ? formDetails.categories
                .filter((c) => c.checked)
                .map((category) => (
                  <button
                    type="button"
                    className="flex items-center gap-2 justify-between rounded-lg border-light border-border-primary text-xs py-1 px-2 w-fit bg-soft-gray"
                  >
                    <span className="text-start">{category.category}</span>
                    <span className="px-2 py-0.5 bg-border-primary rounded-lg text-black">
                      {category.dataTypes.filter((d) => d.checked).length || 0}/
                      {category.dataTypes.length}
                    </span>
                  </button>
                ))
            : null}
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          <span className="text-sm text-black">
            This policy will scan the following email parts
          </span>
        </div>
        <div className="mt-6 flex gap-2 flex-wrap">
          {formDetails?.scan
            ?.filter((c) => c.checked)
            .map((part) => (
              <button
                type="button"
                className="flex items-center gap-2 justify-between rounded-lg border-light border-border-primary text-xs py-1 px-2 w-fit bg-soft-gray"
              >
                {emailPartDetails?.[part.name]?.title || 'Unknown Selection'}
              </button>
            ))}
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <button
          type="button"
          className={`flex-1 rounded-lg border-light border-border-primary py-4 shadow-sm ${mode !== 'edit' ? 'cursor-not-allowed opacity-50' : ''} text-sm`}
          onClick={() => handleBack()}
        >
          Back
        </button>
        <button
          type="button"
          className={`rounded-lg flex-1 py-4 text-white ${!validatePreviewForm().valid ? 'bg-black/50 cursor-not-allowed' : 'bg-black '} text-sm`}
          onClick={() => handleNext()}
        >
          {formLoading ? (
            <Loader color="white" />
          ) : (
            <span>{mode === 'edit' ? 'Save Changes' : 'Next'}</span>
          )}
        </button>
      </div>
    </div>
  );
}
