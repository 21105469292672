import { useState } from 'react';
import { debounce } from 'lodash';

import { ReactComponent as IconMagnifyingGlass } from 'assets/icons/magnifying-glass.svg';

import { Divider } from 'components/Divider';
import { useGetDLPPolicyQuery } from 'service/dlp';
import { ListDLPFilters } from 'models/dlp';
import { useOrgId } from 'hooks/useOrgId';
import { DLPTable } from 'components/DLP/DLPTable';
import { PolicyStatus } from 'enums/dlp';
import { useNavigate } from 'react-router-dom';

export function DLPList() {
  const navigate = useNavigate();
  // const activeFilters = ['All', 'Active', 'Inactive', 'Draft'];
  const activeFilters: { label: string; query: PolicyStatus | '' }[] = [
    {
      label: 'All',
      query: '',
    },
    {
      label: 'Active',
      query: 'active',
    },
    {
      label: 'Inactive',
      query: 'inactive',
    },
    {
      label: 'Draft',
      query: 'draft',
    },
  ];

  const [activeFilterIndex, setActiveFilterIndex] = useState(0);

  const [filters, setFilters] = useState<ListDLPFilters>({
    limit: 10,
    offset: 0,
    query: '',
    status: '',
  });

  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetDLPPolicyQuery({
    orgId: OrgId,
    ...filters,
  });

  const handleSearch = debounce((value: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, query: value }));
  }, 500);

  const handleActiveFilters = (index: number) => {
    if (activeFilters[index].label === 'All') setFilters({ ...filters, status: '' });
    else setFilters({ ...filters, status: activeFilters[index].query || '' });

    setActiveFilterIndex(index);
  };

  return (
    <div className="px-10 py-8 w-full">
      <div className="flex items-center gap-8">
        <div className="flex-1 px-5 py-3.5 rounded-lg bg-soft-gray flex items-center gap-4 border border-border-primary">
          <IconMagnifyingGlass className="fill-light-grey w-4 h-4" />
          <input
            placeholder="Search Policies"
            className="placeholder:text-light-grey flex-1 outline-none bg-transparent placeholder:text-sm text-base text-light-grey"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <Divider />
      <div className="w-full flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          {activeFilters.map((filter, index) => (
            <button
              type="button"
              className={`px-4 py-2 ${index === activeFilterIndex ? 'bg-black text-white' : 'bg-white border-light border-border-primary text-black'} rounded-full`}
              onClick={() => handleActiveFilters(index)}
            >
              {filter.label}
            </button>
          ))}
        </div>
        <button
          type="button"
          className="p-4 bg-black text-white rounded-lg text-sm"
          onClick={() => navigate('/dlp/create')}
        >
          New Policy +
        </button>
      </div>
      <DLPTable
        hits={data?.hits || []}
        limit={data?.limit || 0}
        offset={data?.offset || 0}
        loading={isLoading}
        total={data?.total || 0}
        setFilters={setFilters}
      />
    </div>
  );
}
