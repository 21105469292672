import {
  Action,
  BlockRuleData,
  CategoryItem,
  ExceptionDetails,
  ScanItem,
  transformCategoryToItem,
  validateExceptions,
} from 'components/DLP/data';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useToast } from 'hooks/toast';
import { useOrgId } from 'hooks/useOrgId';
import { useQueryParam } from 'hooks/useQueryParam';
import { getAllDataTypes } from 'models/dlp';
import { createContext, useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeletePolicyMutation, useLazyGetPolicyQuery } from 'service/dlp';
import { useGetCategoriesQuery } from 'service/categories';

import { Category } from 'models/categories';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { PolicyStatus } from 'enums/dlp';

import {
  getUpdatedActionConfig,
  transformAudienceToExceptions,
  updateCategoryItemsCheckedState,
  updateScanItemsCheckedState,
} from './DLPFormContext.data';

interface DLPFormDetails {
  id: string;
  name?: {
    value: string;
    error: string;
  };
  categories?: CategoryItem[];
  scan?: ScanItem[];
  isInternal?: boolean;
  isExternal?: boolean;
  blockRule?: BlockRuleData;
  exceptions?: ExceptionDetails[];
  actionConfiguration: {
    block: Record<string, Action>;
    exception: Record<string, Action>;
  };
  severity: string;
  status: 'draft' | 'active' | 'inactive';
  displayId: string;
}

interface DLPFormContextType {
  activeStep: number;
  setActiveStep: (step: number) => void;
  formDetails: DLPFormDetails;
  setFormDetails: React.Dispatch<React.SetStateAction<DLPFormDetails>>;
  policyId: string;
  setPolicyId: React.Dispatch<React.SetStateAction<string>>;
  onDiscard: () => void;
  onNext: () => void;
  onBack: () => void;
  formSteps: { name: string; description: string }[];
  loadingCategories: boolean;
  loading: boolean;
  formStatus: Record<number, boolean>;
  isFormStepComplete: (step: number) => boolean;
  areActionsSubmitted: () => boolean;
  setActionSubmissionStatus: (submitted: boolean) => void;
  mode: string;
}

const DLPFormContext = createContext<DLPFormContextType | undefined>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}

export function DLPFormProvider({ children }: ProviderProps) {
  const { showToast } = useToast();

  const [policyId, setPolicyId] = useState('');

  const [mode, setMode] = useState('');

  const [formStatus, setFormStatus] = useState<Record<number, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  // Track whether the action configuration has been submitted
  const [actionsSubmitted, setActionsSubmitted] = useState(false);

  const [pId] = useQueryParam({ key: 'pId' });

  const [modeQueryParam, setModeQueryParam] = useQueryParam({ key: 'mode' });

  const [modeLoading, setModeLoading] = useState(true);

  const [orgId] = useOrgId();

  const navigate = useNavigate();

  const { data, isLoading } = useGetCategoriesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [activeStep, setActiveStep] = useState(0);
  const formSteps = useMemo(
    () => [
      {
        name: 'Define Policy',
        description: 'What data do you want to protect',
      },
      {
        name: 'Set Rules',
        description: 'Who can send sensitive data',
      },
      {
        name: 'Configure Actions & Severity',
        description: 'What to do when sensitive data is sent',
      },
      {
        name: 'Review & Submit',
        description: 'Finalise Policy Details',
      },
    ],
    []
  );

  const [formDetails, setFormDetails] = useState<DLPFormDetails>({
    id: '',
    scan: [
      {
        name: 'content',
        checked: false,
      },
      {
        name: 'attachment',
        checked: false,
      },
      {
        name: 'images',
        checked: false,
      },
      {
        name: 'links',
        checked: false,
      },
    ],
    blockRule: {
      sender: {
        value: 'All Internal Users',
        type: 'all_internal',
      },
      recipient: {
        value: 'All External Users',
        type: 'all_external',
      },
    },
    isExternal: true,
    actionConfiguration: {
      block: {
        log: {
          name: 'Log Event',
          value: 'log',
          enabled: true,
          submitted: false,
        },
        alert: {
          name: 'Alert Admin',
          value: 'alert',
          enabled: false,
          submitted: false,
        },
        // quarantine: {
        //   name: 'Quarantine Mail',
        //   value: 'quarantine',
        //   enabled: false,
        // },
        // override: {
        //   name: 'Provide Override Access',
        //   value: 'override',
        //   enabled: false,
        // },
        // decline: {
        //   name: 'Decline Mail',
        //   value: 'decline',
        //   enabled: false,
        // },
      },
      exception: {
        log: {
          name: 'Log Event',
          value: 'log',
          enabled: true,
          submitted: false,
        },
        alert: {
          name: 'Alert Admin',
          value: 'alert',
          enabled: false,
          submitted: false,
        },
        // quarantine: {
        //   name: 'Quarantine Mail',
        //   value: 'quarantine',
        //   enabled: false,
        // },
        // override: {
        //   name: 'Provide Override Access',
        //   value: 'override',
        //   enabled: false,
        // },
        // decline: {
        //   name: 'Decline Mail',
        //   value: 'decline',
        //   enabled: false,
        // },
      },
    },
    severity: '',
    status: 'draft',
    displayId: '',
  });

  useEffect(() => {
    setModeLoading(true);
    setMode(modeQueryParam || '');
    if (modeQueryParam === 'edit') setActiveStep(formSteps.length - 1);
    setModeLoading(false);
  }, [modeQueryParam, formSteps.length]);

  // Function to check if the Define Policy step is complete
  const isDefinePolicyComplete = useCallback(() => {
    return Boolean(
      formDetails.name?.value &&
        formDetails.name?.value.trim() !== '' &&
        formDetails.categories?.some((category) =>
          category.dataTypes.some((dataType) => dataType.checked)
        ) &&
        formDetails.scan?.some((item) => item.checked)
    );
  }, [formDetails]);

  // Function to check if the Set Rules step is complete
  const isSetRulesComplete = useCallback(() => {
    return Boolean(
      formDetails.blockRule &&
        ((formDetails?.exceptions && formDetails?.exceptions?.length > 0) ||
          formDetails.isExternal ||
          formDetails.isInternal) &&
        validateExceptions(formDetails.exceptions || [])
    );
  }, [formDetails]);

  // Function to check if the Configure Actions step is complete
  const isConfigureActionsComplete = useCallback(() => {
    return Boolean(
      formDetails.severity &&
        formDetails.severity.trim() !== '' &&
        (Object.values(formDetails.actionConfiguration.block).some((action) => action.enabled) ||
          formDetails?.exceptions?.length === 0 ||
          Object.values(formDetails.actionConfiguration.exception).some((action) => action.enabled))
    );
  }, [formDetails]);

  const isFormSubmitted = useCallback(() => {
    if (formDetails.status === 'draft') return false;
    return true;
  }, [formDetails.status]);

  // Update form status based on the current state of the form
  const updateFormStatus = useCallback(() => {
    const completedStepOne = isDefinePolicyComplete();
    // by default this step is completed hence adding a policy id check so that it shows this step as not completed when the form is started for a new policy
    const completedStepTwo = isSetRulesComplete() && completedStepOne && policyId !== '';
    const completedStepThree = isConfigureActionsComplete() && completedStepTwo;
    const completedStepFour = isFormSubmitted() && completedStepThree;
    setFormStatus({
      0: completedStepOne,
      1: completedStepTwo,
      2: completedStepThree,
      3: completedStepFour, // Review is only considered complete after explicit submission
    });
  }, [
    isDefinePolicyComplete,
    isSetRulesComplete,
    isConfigureActionsComplete,
    isFormSubmitted,
    policyId,
  ]);

  // Update form status when form details change
  useEffect(() => {
    updateFormStatus();
  }, [formDetails, updateFormStatus]);

  const [getPolicy] = useLazyGetPolicyQuery();

  useEffect(() => {
    // if (formDetails.categories) {
    setPolicyId(pId);
  }, [pId]);

  // Set all action submission status
  const setActionSubmissionStatus = useCallback((submitted: boolean) => {
    setActionsSubmitted(submitted);

    setFormDetails((prev) => {
      // Create new block actions with updated submitted state
      const updatedBlockActions: Record<string, Action> = {};
      Object.entries(prev.actionConfiguration.block).forEach(([key, action]) => {
        updatedBlockActions[key] = {
          ...action,
          submitted,
        };
      });

      // Create new exception actions with updated submitted state
      const updatedExceptionActions: Record<string, Action> = {};
      Object.entries(prev.actionConfiguration.exception).forEach(([key, action]) => {
        updatedExceptionActions[key] = {
          ...action,
          submitted,
        };
      });

      return {
        ...prev,
        actionConfiguration: {
          block: updatedBlockActions,
          exception: updatedExceptionActions,
        },
      };
    });
  }, []);

  const handleNext = useCallback(() => {
    if (activeStep < formSteps.length - 1) {
      // Mark the current step as completed
      setFormStatus((prev) => ({
        ...prev,
        [activeStep]: true,
      }));

      // If moving from the Configure Actions step, mark actions as submitted
      if (activeStep === 2) {
        setActionSubmissionStatus(true);
        setModeQueryParam('edit');
      }

      setActiveStep((prevStep) => prevStep + 1);
    }
  }, [activeStep, formSteps.length, setActionSubmissionStatus, setModeQueryParam]);

  const handleBack = useCallback(() => {
    if (activeStep > 0) setActiveStep((prevStep) => prevStep - 1);
  }, [activeStep]);

  const [deletePolicy] = useDeletePolicyMutation();

  const handleDiscard = useCallback(async () => {
    try {
      if (policyId) {
        await deletePolicy({
          orgId: orgId,
          policyId: policyId,
        }).unwrap();

        showToast({
          component: <SuccessToast message="Successfully discarded the policy" />,
        });

        navigate('/dlp/list');
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again!" />,
      });
    }
  }, [policyId, orgId, deletePolicy, navigate, showToast]);

  const [policyLoading, setPolicyLoading] = useState(true);

  const value = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      formDetails,
      setFormDetails,
      onDiscard: handleDiscard,
      formSteps,
      onNext: handleNext,
      onBack: handleBack,
      policyId,
      setPolicyId,
      loadingCategories: isLoading,
      loading: policyLoading || modeLoading,
      formStatus,
      isFormStepComplete: (step: number) => formStatus[step],
      areActionsSubmitted: () => actionsSubmitted,
      setActionSubmissionStatus,
      mode,
    }),
    [
      activeStep,
      formDetails,
      formSteps,
      handleBack,
      handleNext,
      handleDiscard,
      policyId,
      isLoading,
      policyLoading,
      formStatus,
      actionsSubmitted,
      setActionSubmissionStatus,
      mode,
      modeLoading,
    ]
  );

  const handleDataChange = useCallback(
    async (allCategories: Category[]) => {
      if (policyId) {
        try {
          setPolicyLoading(true);
          const response = await getPolicy({ orgId, policyId }).unwrap();

          if (response) {
            const allTypes = getAllDataTypes(response.categories);

            const checkedCategories = updateCategoryItemsCheckedState(
              allCategories.map((category) => transformCategoryToItem(category)),
              allTypes
            );

            // Calculate form completion status based on existing data
            const hasDefinedPolicy = Boolean(
              response.name &&
                response.name.trim() !== '' &&
                response.categories &&
                response.categories.length > 0
            );
            const hasSetRules = Boolean(response.audience && response.audience.length > 0);
            const hasConfiguredActions = Boolean(
              response.actions &&
                response.actions.length > 0 &&
                response.severity &&
                response.severity.trim() !== ''
            );

            // Update form status
            setFormStatus({
              0: hasDefinedPolicy,
              1: hasSetRules,
              2: hasConfiguredActions,
              3: false, // Review is only considered complete when user explicitly reviews
            });

            // If there are actions configured, mark them as submitted
            if (hasConfiguredActions) {
              setActionsSubmitted(true);
            }

            setFormDetails((prev) => {
              const actionConfig = getUpdatedActionConfig(
                response.actions,
                prev.actionConfiguration
              );

              return {
                ...prev,
                name: {
                  value: response.name,
                  error: '',
                },
                scan: updateScanItemsCheckedState(prev.scan || [], response.scans),
                categories: checkedCategories,
                actionConfiguration: actionConfig,
                severity: response.severity,
                exceptions: transformAudienceToExceptions(response.audience) || [],
                displayId: response.displayId,
                status: response.status as PolicyStatus,
              };
            });
          }
        } catch {
          showToast({
            component: <ErrorToast message="Error in fetching policy, please try again!" />,
          });
        }
      } else {
        // For a new form, reset the form status and action submission status
        setFormStatus({
          0: false,
          1: false,
          2: false,
          3: false,
        });

        setActionSubmissionStatus(false);

        setFormDetails((prev) => ({
          ...prev,
          categories: allCategories.map((c) => transformCategoryToItem(c)),
        }));
      }
      setPolicyLoading(false);
    },
    [orgId, policyId, showToast, getPolicy, setActionsSubmitted, setActionSubmissionStatus]
  );

  useEffect(() => {
    if (data) {
      handleDataChange(data);
    }
  }, [data, handleDataChange]);

  return <DLPFormContext.Provider value={value}>{children}</DLPFormContext.Provider>;
}

export function useDLPForm() {
  const context = useContext(DLPFormContext);
  if (context === undefined) {
    throw new Error('useDLPForm must be used within a DLPFormProvider');
  }
  return context;
}
