import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { EventsResponse, GetEventsQueryParams } from 'models/events';

import EventTable from './EventTable';
import { EventFilterOptions } from './EventFilter';

interface AllEventsProps {
  filters: GetEventsQueryParams;
  setFilters: React.Dispatch<React.SetStateAction<GetEventsQueryParams>>;
  isLoading: boolean;
  data?: EventsResponse;
}

export default function AllEvents({
  filters,
  setFilters,
  isLoading,
  data = {
    hits: [],
    total: 0,
    limit: 10,
    offset: 0,
  },
}: AllEventsProps) {
  // timePeriodSelectOptions is an array of the options in the select dropdown for selecting the time period

  const getStatus = (statusOptions: 'pending' | 'remediated' | 'all') => {
    return statusOptions === 'all' ? '' : statusOptions;
  };

  const handleApplyFilter = (options: EventFilterOptions) => {
    setFilters({
      ...filters,
      threat: options?.threats?.map((t) => t.code)?.join(',') || '',
      status: getStatus(options.status || 'all'),
    });
  };

  return (
    <div className="mt-4 w-full">
      <div className="overflow-hidden w-full">
        <div className="w-full relative">
          <EventTable
            events={data.hits}
            onSort={(sortType) => {
              setFilters({
                ...filters,
                sortOrder: sortType,
              });
            }}
            initialSortType={filters.sortOrder === 'asc' ? 0 : 1}
            from={filters.from}
            to={filters.to}
            onFilter={handleApplyFilter}
            isLoading={isLoading}
          />
        </div>
        {data?.hits?.length > 0 && !isLoading && (
          <div className="flex justify-between px-6 py-4 items-center">
            <span className="text-xs font-light">
              Showing {data && data?.hits?.length > 0 ? data.offset + 1 : '0'} -{' '}
              {data && data?.hits.length > 0 ? data.offset + data.hits.length : '0'} of{' '}
              {data?.total} events
            </span>
            <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
              <IconArrowDown
                className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${data && data?.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data) {
                    if (data.offset > 0) {
                      setFilters({
                        ...filters,
                        offset: data.offset - data.limit,
                      });
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                  }
                }}
              />
              <IconArrowDown
                className={`w-6 h-6 -rotate-90 ${data && data.offset + data.limit < data.total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                onClick={() => {
                  if (data && data.offset + data.limit < data.total) {
                    setFilters({
                      ...filters,
                      offset: data.offset + data.limit,
                    });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
