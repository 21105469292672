import { getEmailDetails } from 'components/Events/data';
import { DLPIncident as IncidentDetails } from 'models/dlp';
import { dateUtils } from 'utils/time';

// import { DLPIncidentDetails } from './DLPIncidentDetails';
import { toTitleCase } from 'utils/name';
import { useNavigate } from 'react-router-dom';

import { policyActionDetails } from './data';

interface DLPIncidentRowProps {
  data: IncidentDetails;
}

export function DLPIncidentRow({ data }: DLPIncidentRowProps) {
  const navigate = useNavigate();

  const getDataTypes = () => {
    let badgeList: string[] = [];
    if (data?.violations?.length > 2) {
      badgeList = [
        ...data.violations.slice(0, 2).map((d) => d.subCategory),
        `+ ${data.violations.length - 2} More`,
      ];
    } else {
      badgeList = data?.violations?.map((f) => f.subCategory);
    }

    return (
      <div className="flex items-center gap-2 flex-wrap">
        {badgeList.map((badge) => (
          <button
            className="text-sm py-2 px-2.5 rounded-lg text-black border-light border-border-primary bg-soft-gray"
            type="button"
          >
            {badge}
          </button>
        ))}
      </div>
    );
  };

  const getActions = () => {
    const badgeList = data.actions || [];

    return (
      <div className="flex items-center gap-2 flex-wrap">
        {badgeList.map((badge) => (
          <button
            className="text-sm py-2 px-2.5 rounded-lg text-black border-light border-border-primary bg-soft-red"
            type="button"
          >
            {policyActionDetails[badge]?.name || ''}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <tr
        className="border-b-light align-middle border-border-primary cursor-pointer"
        onClick={() => navigate(`/dlp/incidents/${data.id}`)}
      >
        <td className="text-start py-6 px-3 text-black text-sm">
          <div>{dateUtils.format(data.detectionDate, 'DD/MM/YYYY')?.toString()}</div>
          <div className="text-light-grey">
            {dateUtils?.format(data.detectionDate, 'hh:mm A')?.toString()}
          </div>
        </td>
        <td className="py-6 px-3 text-sm text-black">{data.name}</td>
        <td title={data.displayId} className="text-start py-6 px-6">
          <p className="text-black text-sm max-w-full text-ellipsis line-clamp-2">
            {data.displayId}
          </p>
        </td>
        <td className="py-6 px-3 text-sm text-black">
          <button
            type="button"
            className={`px-2.5 py-2 rounded ${data.scope === 'external' ? 'bg-soft-purple' : 'bg-soft-blue'} border-light border-border-primary`}
          >
            {data.scope === 'external' ? 'External' : 'Internal'}
          </button>
        </td>
        <td className="py-6 px-3 text-sm text-black">{getDataTypes()}</td>
        <td className="text-start py-6 px-3 text-black text-sm">
          <p className="max-w-full break-words text-ellipsis line-clamp-1">
            <span className="text-light-grey">From:</span> {getEmailDetails(data.sender)}
          </p>
          <p className="max-w-full break-words text-ellipsis line-clamp-2">
            <span className="text-light-grey"> To: </span>{' '}
            {data.recipients?.length > 1
              ? `${data.recipients.length} Recipients`
              : getEmailDetails(data.recipients?.[0])}
          </p>
        </td>
        <td title={data.severity} className="text-start py-6 px-6">
          <p className="text-black text-sm max-w-full text-ellipsis line-clamp-2">
            {toTitleCase(data.severity)}
          </p>
        </td>
        <td className="py-6 px-3 text-sm text-black">{getActions()}</td>
        <td className="py-6 px-3 text-sm text-black">
          <button
            type="button"
            className="px-2.5 py-2 rounded bg-soft-blue border-light border-border-primary"
          >
            Monitor
          </button>
        </td>
      </tr>
      {/* <DLPIncidentDetails open={open} onClose={() => handleClose()} data={data} /> */}
    </>
  );
}
