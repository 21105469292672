import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Avatar from 'components/Avatar';
import { AccessControl } from 'components/Settings/AccessControl';
import { GeneralSettings } from 'components/Settings/GeneralSettings';
import { useAuth } from 'contexts/AuthContext';
import { useQueryParam } from 'hooks/useQueryParam';
import { useEffect, useState } from 'react';
import { getInitials } from 'utils/name';

export default function Settings() {
  // settingsTab is used to get the value of tab query param which can be used
  // to persist the selectedCategory state across reloads
  const [settingsTab, setSettingsTab] = useQueryParam({ key: 'tab' });

  // selectedCategory is used to control the state of the settings sidebar
  // menu options - "Access Control [0]" and "General Settings [1]"
  const [selectedCategory, setSelectedCategory] = useState(0);

  // updates the state of the selectedCategory in case there's a change
  // in the value of settingsTab
  useEffect(() => {
    if (settingsTab !== '') {
      const selectedOption = parseInt(settingsTab, 2);
      setSelectedCategory(selectedOption);
    }
  }, [settingsTab]);

  // navigationOptions are the different options in the settings sidebar
  const navigationOptions = [
    {
      title: 'Access Control',
      onClick: () => {
        setSelectedCategory(0);
        setSettingsTab('0');
      },
    },
    {
      title: 'General Settings',
      onClick: () => {
        setSelectedCategory(1);
        setSettingsTab('1');
      },
    },
  ];

  // fetching name and the logout method from useAuth (AuthContext)
  const { name, logout } = useAuth();

  const getSettingsComponent = () => {
    return selectedCategory === 0 ? <AccessControl /> : <GeneralSettings />;
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full px-8 py-5 sticky top-0 bg-white z-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h2 className="text-2xl">Settings</h2>
          </div>
          <div className="flex gap-8 items-center">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className="text-end" asChild>
                <div>
                  <Avatar
                    initials={getInitials(name, 2)} // a placeholder for the initials
                    bgColor="soft-blue"
                    textColor="avatar-blue"
                  />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <div className="w-full">
                  <DropdownMenu.Content
                    className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
                    side="left"
                  >
                    <DropdownMenu.Item
                      className="px-3 py-1.5 hover:bg-select-background hover:text-red-500 text-light-grey bg-transparent cursor-pointer outline-none"
                      onClick={() => logout()}
                    >
                      Logout
                    </DropdownMenu.Item>
                  </DropdownMenu.Content>
                </div>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
        </div>
      </div>
      <div className="px-8">
        <hr className="border-light border-border-primary" />
      </div>
      <div className="flex-1 flex overflow-hidden">
        <div className="sticky top-0 h-screen px-8 py-10 flex flex-col gap-8 border-r-light border-border-primary">
          {navigationOptions.map((navigationOption, index) => (
            <button
              key={navigationOption.title}
              type="button"
              className={`flex items-center gap-10 font-medium px-4 py-2 ${index === selectedCategory ? 'rounded-md border-light border-border-primary text-black' : 'text-light-grey'} text-base justify-between`}
              onClick={() => navigationOption.onClick()}
            >
              {navigationOption.title}
            </button>
          ))}
        </div>
        <div className="flex-1 overflow-auto">{getSettingsComponent()}</div>
      </div>
    </div>
  );
}
