import { Email } from 'models/events';
import { PolicyStatus } from 'enums/dlp';

import { DataType } from './categories';

export interface DLPRule {
  id: string;
  createdAt: string;
  referenceId: string;
  name: string;
  scope: string;
  protectedDataTypes: DataType[];
  status: PolicyStatus;
}

export interface DLPSearchResult {
  hits: DLPRule[];
  total: number;
  limit: number;
  offset: number;
}

export interface CreateDLPReq {
  name: string;
  subcategories: string[];
  emailPart: string[];
}

export interface Policy {
  id: string;
  organizationId: string;
  name: string;
  status: string;
  createdBy: string;
  isExternal: boolean;
  isInternal: boolean;
  severity?: string;
  displayId: string;
}

export interface PolicyActions {
  actions: ActionItem[];
  severity: string;
}

export interface ActionItem {
  rule: string;
  actions: string[];
}

export interface PolicyAudience {
  block: {
    sender: string;
    recipient: string;
  };
  exceptions: {
    sender: string;
    recipients: string[];
  }[];
  scope: string;
}

export interface ListDLPFilters {
  limit: number;
  offset: number;
  status: PolicyStatus | '';
  query: string;
}

export interface DLPIncidentFilters {
  limit: number;
  offset: number;
  severity?: string;
}

export interface ViolationDetails {
  rule: string;
  description: string;
  content: string;
  category: string;
  subCategory: string;
  fileName: string;
  emailPart: string;
  exactMatch: string;
}

export interface DLPIncident {
  id: string;
  messageId: string;
  subject: string;
  sender: Email;
  recipients: Email[];
  detectionDate: string;
  displayId: string;
  name: string;
  scope: string;
  createdAt: string;
  sentAt: string;
  organizationId: string;
  violations: ViolationDetails[];
  severity: string;
  actions: string[];
  policyId: string;
}

export interface DLPIncidentList {
  hits: DLPIncident[];
  limit: number;
  offset: number;
  total: number;
}

export interface DLPStats {
  policies: number;
  violations: number;
}

export interface AudienceType {
  value: string;
  type: string;
  error?: string;
}

export interface CategoryItem {
  category: string;
  dataTypes: DataType[];
}

export interface AudienceRuleDetail {
  type: string;
  value: string;
}

export interface AudienceRule {
  rule: 'block' | 'allow';
  details: AudienceRuleDetail[];
}

export interface ActionRule {
  rule: 'block' | 'allow';
  actions: string[];
}

export interface PolicyDetails {
  name: string;
  isInternal: boolean;
  isExternal: boolean;
  status: string;
  severity: string;
  displayId: string;
  scans: string[];
  updatedBy: string;
  createdBy: string;
  updatedAt: string;
  categories: CategoryItem[];
  audience: AudienceRule[];
  actions: ActionRule[];
}

export function getAllDataTypes(c: CategoryItem[]): DataType[] {
  return c?.flatMap((item) => item.dataTypes);
}

export interface UpdatePolicyActionReq {
  actions: ActionRule[];
  severity: string;
}

export interface DLPIncidentDetails extends DLPIncident {
  sentAt: string;
}
