import { createBrowserRouter } from 'react-router-dom';

import RootLayout from 'components/Layouts';

import Home from 'pages/Home';
import Users from 'pages/Users';
import Settings from 'pages/Settings';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Onboarding from 'pages/Onboarding';
import Remediation from 'pages/Remediation';
import Analytics from 'pages/Analytics';
import Policies from 'pages/Policies';
import Rules from 'pages/Rules';
import { EventDetails } from 'pages/Home/EventDetails';
import { RemediationDetails } from 'pages/Remediation/RemediationDetails';
import { BlacklistEventDetails } from 'pages/Remediation/BlacklistDetails';
import DLP from 'pages/DLP';
import { DLPList } from 'pages/DLP/DLPList';
// import { CreateDLP } from 'pages/DLP/CreateDLP';
import { DLPIncidentList } from 'pages/DLP/DLPIncidentList';
import CreateCustomDLPRule from 'pages/DLP/CreateCustomDLPRule';
import { ViolationDetails } from 'pages/DLP/ViolationDetails';

import ProtectedRoute from './ProtectedRoutes';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/onboarding',
    element: (
      <ProtectedRoute>
        <Onboarding />
      </ProtectedRoute>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <RootLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Home />,
        children: [
          {
            path: '/events/:messageId',
            element: <EventDetails />,
          },
        ],
      },
      {
        element: <Users />,
        path: '/users',
      },
      {
        element: <Remediation />,
        path: '/remediation',
        children: [
          {
            path: '/remediation/:messageId',
            element: <RemediationDetails />,
          },
          {
            path: '/remediation/blocklist/:id',
            element: <BlacklistEventDetails />,
          },
        ],
      },
      {
        element: <Settings />,
        path: '/settings',
      },
      {
        element: <Analytics />,
        path: '/analytics',
      },
      {
        element: <Policies />,
        path: '/policies',
      },
      {
        element: <Rules />,
        path: '/rules',
      },
      {
        element: <DLP />,
        path: '/dlp',
        children: [
          {
            path: '/dlp/list',
            element: <DLPList />,
          },
          {
            path: '/dlp/incidents',
            element: <DLPIncidentList />,
          },
          {
            path: '/dlp/incidents/:violationId',
            element: <ViolationDetails />,
          },
        ],
      },
    ],
  },
  {
    path: '/dlp/create',
    element: (
      <ProtectedRoute>
        <CreateCustomDLPRule />
      </ProtectedRoute>
    ),
  },
]);

export default router;
