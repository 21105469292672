import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { ReactComponent as IconAlert } from 'assets/icons/icon-alert-red.svg';
import { Divider } from 'components/Divider';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

interface DLPAlertProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  handleClose: () => void;
  description?: string;
}
export function DLPAlert({ open, handleClose, onConfirm, onCancel, description }: DLPAlertProps) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 z-50" />
        <AlertDialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg max-w-96 z-50">
          <button
            type="button"
            className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
            onClick={() => handleClose()}
          >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <IconClose className="w-2.5 h-2.5 fill-light-grey" />
          </button>
          <div className="p-8 flex flex-col items-center gap-4">
            <IconAlert />
            <AlertDialog.Title className="text-xl font-medium text-center w-4/5 break-words">
              {description || 'Are you sure you want to discard this Policy draft?'}
            </AlertDialog.Title>
            <span className="text-light-grey">All configurations & changes will be lost.</span>
          </div>
          <Divider customCss="my-0" />
          <div className="p-8 flex flex-col gap-4 justify-center">
            <button
              type="button"
              className="bg-black rounded-lg text-white p-4"
              onClick={() => onConfirm()}
            >
              Confirm
            </button>
            <button
              type="button"
              className="flex items-center justify-center text-light-grey"
              onClick={() => onCancel()}
            >
              <span className="underline decoration-light-grey">Cancel</span>
            </button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
