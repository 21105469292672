import { useEffect, useState } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as Select from '@radix-ui/react-select';

import { Event, RemediationRequest } from 'models/events';

import { ReactComponent as IconRemediationAction } from 'assets/icons/icon-remediation-action.svg';
import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconSuccessCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconWarning } from 'assets/icons/icon-warning-alert.svg';
import { ReactComponent as IconGrayChecked } from 'assets/icons/gray-icon-checked.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info-icon.svg';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import {
  useRemediateEventMutation,
  useGiveFeedbackMutation,
  useValidateBlacklistMutation,
} from 'service/eventsApi';

import { useOrgId } from 'hooks/useOrgId';

import Loader from 'components/Loader';
import { useToast } from 'hooks/toast';

import { Divider } from 'components/Divider';
import { Analysis } from 'components/EmailAttributes/Analysis';
import Avatar from 'components/Avatar';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ExpandableToast } from 'components/Toasts/ExpandableToast';
import { WarningToast } from 'components/Toasts/WarningToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';

import { getInitials } from 'utils/name';

import { MOVE_TO_JUNK, QUARANTINE_MESSAGE } from 'constants/remediationActions';
import { RemediationActions } from 'enums/remediationAction';
import { getDomainFromEmail } from 'utils/email';

import { EventActionStatus } from './EventActionStatus';
import { RemediateAlert } from './RemediateAlert';
import { MarkAsSafeAlert } from './MarkAsSafeAlert';

interface EventOverviewProps {
  event: Event | undefined;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}

export function EventOverview({
  open,
  onClose,
  event,
  isLoading,
}: EventOverviewProps): JSX.Element {
  const { showToast } = useToast();

  const [checkedStateOfActions, setCheckedStateOfActions] = useState({
    logout: false,
    quarantine: false,
    blacklist: false,
  });

  const [loading, setLoading] = useState(false);

  const [OrgId] = useOrgId();

  const [selectedRemediationAction, setSelectedRemediationAction] =
    useState<RemediationActions>('junk');

  const [blacklistOptions, setBlacklistOptions] = useState<Record<string, boolean>>();

  useEffect(() => {
    const senderDomain = getDomainFromEmail(event?.sender?.email || '');
    const blacklistOps: Record<string, boolean> = {};
    blacklistOps[senderDomain] = false;
    blacklistOps[event?.sender.email || ''] = false;

    setBlacklistOptions(blacklistOps);
  }, [event]);

  const [remediateMessage] = useRemediateEventMutation();

  const [validateBlacklist] = useValidateBlacklistMutation();

  const [showBlacklist, setShowBlacklist] = useState(false);

  const [blacklistCheckLoading, setBlacklistCheckLoading] = useState(false);

  const [blacklistData, setBlacklistData] = useState<Record<string, boolean>>();

  const handleValidateBlacklist = async () => {
    setBlacklistCheckLoading(true);
    try {
      const data = await validateBlacklist({
        orgId: OrgId,
        listEntries: [
          {
            value: event?.sender.email || '',
            comment: '',
          },
          {
            value: getDomainFromEmail(event?.sender.email || ''),
            comment: '',
          },
        ],
      }).unwrap();

      if (Object.values(data)?.some((validation) => validation.valid)) setShowBlacklist(true);

      const responseData: Record<string, boolean> = {};
      Object.keys(data).forEach((key) => {
        responseData[key] = data[key].valid;
      });

      setBlacklistData(responseData);
    } catch {
      showToast({
        component: <ErrorToast message="Error in fetching blacklist actions" />,
      });
    } finally {
      setBlacklistCheckLoading(false);
    }
  };

  useEffect(() => {
    if (event) {
      handleValidateBlacklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const [remediationData, setRemediationData] = useState({
    visible: false,
    data: {
      email: '',
      blacklist: [] as string[],
      action: '',
    },
  });

  const [showSafeAlert, setShowSafeAlert] = useState(false);

  const [giveFeedbackMutation] = useGiveFeedbackMutation();

  if (!event || isLoading || blacklistCheckLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 h-overview-modal  bg-white rounded-3xl">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const handleToggle = (action: 'logout' | 'quarantine' | 'blacklist') => {
    setCheckedStateOfActions((prevState) => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const handleRemediateCheck = () => {
    handleToggle('quarantine');
  };

  const handleSelect = (value: RemediationActions) => {
    setSelectedRemediationAction(value);
  };

  const handleMarkAsSafe = async () => {
    setLoading(true);
    try {
      await giveFeedbackMutation({
        orgId: OrgId,
        messageId: event.messageId,
        feedback: true,
      }).unwrap();

      showToast({
        component: <SuccessToast message="Marked mail as safe successfully" />,
      });
      setShowSafeAlert(false);
    } catch {
      showToast({
        component: <ErrorToast message="Unable to mark mail as safe, please try again!" />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckedBlacklistOption = (val: string) => {
    setBlacklistOptions((prev) => {
      if (!prev) return {};
      const isTargetFalse = prev?.[val] === false;
      const areOthersTrue = Object.entries(prev)
        .filter(([key]) => key !== val && blacklistData?.[key])
        .every(([, value]) => value === true);

      setCheckedStateOfActions({
        ...checkedStateOfActions,
        blacklist: isTargetFalse && areOthersTrue,
      });

      return {
        ...prev,
        [val]: !prev?.[val],
      };
    });
  };

  const handleCheckBlacklist = () => {
    setCheckedStateOfActions((prev) => {
      setBlacklistOptions((prevOptions) => {
        if (!prevOptions) return prevOptions;

        const updatedOptions: Record<string, boolean> = {};

        // Set all existing keys to true
        Object.keys(prevOptions).forEach((key) => {
          updatedOptions[key] = !prev.blacklist;
        });

        return updatedOptions;
      });
      return { ...prev, blacklist: !prev.blacklist };
    });
  };

  const handleRemediateClick = async () => {
    const data = {
      email: '',
      action: '',
      blacklist: [] as string[],
    };

    let showAlert = false;

    if (checkedStateOfActions.logout) {
      data.email = event.recipients.find((r) => r.isPrimary)?.email || '';
      showAlert = true;
    }

    if (checkedStateOfActions.quarantine) {
      data.action = selectedRemediationAction;
      showAlert = true;
    }

    const blacklistedValues = Object.entries(blacklistOptions || {})
      ?.filter(([, value]) => value === true)
      ?.map(([key]) => key);

    if (blacklistedValues.length) {
      data.blacklist = blacklistedValues.filter((value) => blacklistData?.[value]);
      showAlert = true;
    }

    if (!showAlert) {
      showToast({
        component: <WarningToast message="Please select an action before submitting" />,
      });
    } else {
      setRemediationData({
        visible: true,
        data: data,
      });
    }
  };

  const handleRemediateSubmit = async () => {
    setLoading(true);
    setRemediationData((prev) => ({ ...prev, visible: false }));

    const data: RemediationRequest = {};

    if (checkedStateOfActions.logout) {
      data.employee = {
        employeeId: event.employeeId,
      };
    }

    if (checkedStateOfActions.quarantine) {
      data.message = {
        action: selectedRemediationAction === 'junk' ? MOVE_TO_JUNK : QUARANTINE_MESSAGE,
      };
    }

    const blacklistedValues = Object.entries(blacklistOptions || {})
      ?.filter(([, value]) => value === true)
      ?.map(([key]) => key);

    if (blacklistedValues.length) {
      data.blacklist = {
        entries: blacklistedValues.filter((value) => blacklistData?.[value]),
      };
    }

    try {
      const response = await remediateMessage({
        orgId: OrgId,
        messageId: event.messageId,
        data,
      }).unwrap();

      showToast({
        component: (
          <ExpandableToast
            message="Successfully completed the operation"
            icon={
              response.overallSuccess ? (
                <div className="w-6 h-6 bg-green-400 rounded-lg text-center flex items-center justify-center">
                  <IconSuccessCheck className="w-6 h-6" />
                </div>
              ) : (
                <IconWarning className="w-6 h-6 rounded-lg fill-warning-yellow" />
              )
            }
          >
            <EventActionStatus actionResult={response.results} />
          </ExpandableToast>
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Unable to remediate message. Please try again." />,
      });
    } finally {
      setLoading(false);
    }
  };

  const getAlert = () => {
    if (remediationData.visible) {
      return (
        <RemediateAlert
          email={remediationData.data.email}
          action={remediationData.data.action}
          blacklist={remediationData.data.blacklist}
          onCancel={() => setRemediationData((prev) => ({ ...prev, visible: false }))}
          onSubmit={() => handleRemediateSubmit()}
        />
      );
    }

    return (
      <MarkAsSafeAlert
        onCancel={() => setShowSafeAlert(false)}
        onConfirm={() => handleMarkAsSafe()}
      />
    );
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4"
          onClick={(e) => e.stopPropagation()}
        >
          {remediationData.visible || showSafeAlert ? (
            getAlert()
          ) : (
            <div className="w-full max-h-overview-modal bg-white rounded-3xl flex justify-center">
              <Analysis event={event} />
              <div className="w-1/3 border-l-light border-border-primary flex flex-col justify-between gap-8 relative overflow-hidden h-full overflow-y-auto no-scrollbar">
                <button
                  type="submit"
                  className="absolute w-fit top-4 right-4 p-1 rounded-full border-light border-border-primary flex justify-center items-center hover:bg-black cursor-pointer"
                  onClick={() => onClose()}
                  aria-label="Close"
                >
                  <IconCross className="w-2 h-2 hover:stroke-white" />
                </button>
                <div>
                  <div className="flex flex-col">
                    <div className="flex items-center gap-4 p-1.5 border-b-light border-border-primary">
                      <IconRemediationAction />
                      <span className="text-xl text-light-grey">Remediation Actions</span>
                    </div>
                    <div className="flex flex-col gap-2 px-8 mt-6">
                      <div className="w-full flex gap-4 items-center">
                        <Checkbox.Root
                          className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                          checked={checkedStateOfActions.logout}
                          onClick={() => handleToggle('logout')}
                        >
                          <Checkbox.Indicator>
                            <IconCheck />
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <button
                          className="flex flex-col gap-1"
                          onClick={() => handleToggle('logout')}
                          type="button"
                        >
                          <span className="text-xs">Log Out Recepients & Reset Password</span>
                        </button>
                      </div>
                      <span className="text-2xs text-light-grey">
                        This action cannot be reversed. Recipient will continue to be signed out of
                        all active sessions.
                      </span>
                    </div>
                    <Divider customCss="border-border-light border-border-primary my-6" />
                    {event.status === 'Pending' ? (
                      <>
                        <div className="w-full flex gap-4 justify-between relative px-8">
                          <div className="flex items-center gap-4">
                            <Checkbox.Root
                              className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                              checked={checkedStateOfActions.quarantine}
                              onClick={() => handleRemediateCheck()}
                            >
                              <Checkbox.Indicator>
                                <IconCheck />
                              </Checkbox.Indicator>
                            </Checkbox.Root>
                            <button
                              className="flex flex-col gap-1"
                              onClick={() => handleRemediateCheck()}
                              type="button"
                            >
                              <span className="text-xs">Push Mail to</span>
                            </button>
                          </div>
                          <Select.Root defaultValue="junk" onValueChange={handleSelect}>
                            <Select.Trigger className="border-light border-border-primary p-2 rounded-md text-xs text-light-grey flex items-center gap-4">
                              <span className="text-black">
                                <Select.Value />
                              </span>
                              <Select.Icon>
                                <IconArrowDown className="w-4 h-4" />
                              </Select.Icon>
                            </Select.Trigger>
                            <Select.Portal>
                              <Select.Content className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2">
                                <Select.Viewport>
                                  <Select.Item
                                    value="quarantine"
                                    className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                  >
                                    <Select.ItemText>Quarantine</Select.ItemText>
                                  </Select.Item>
                                  <Select.Item
                                    value="junk"
                                    className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                                  >
                                    <Select.ItemText>Junk</Select.ItemText>
                                  </Select.Item>
                                </Select.Viewport>
                              </Select.Content>
                            </Select.Portal>
                          </Select.Root>
                        </div>
                        <Divider customCss="border-border-light border-border-primary my-6" />
                      </>
                    ) : null}
                    {showBlacklist ? (
                      <div className="flex flex-col gap-6 px-8">
                        <div className="w-full flex gap-4 items-center justify-between">
                          <div className="flex items-center gap-4">
                            <Checkbox.Root
                              className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                              checked={checkedStateOfActions.blacklist}
                              onClick={() => handleCheckBlacklist()}
                            >
                              <Checkbox.Indicator>
                                <IconCheck />
                              </Checkbox.Indicator>
                            </Checkbox.Root>
                            <button
                              className="flex flex-col gap-1"
                              onClick={() => handleCheckBlacklist()}
                              type="button"
                            >
                              <span className="text-xs">Block Sender & Domain</span>
                            </button>
                          </div>
                          <button
                            type="button"
                            className="bg-soft-sand border-light border-border-primary p-1 rounded-lg text-secondary-text text-xs"
                          >
                            Recommended
                          </button>
                        </div>
                        <div className="flex flex-col gap-4 ml-4">
                          {blacklistData?.[event.sender.email] ? (
                            <div className="flex items-center gap-3">
                              <Checkbox.Root
                                className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                                checked={blacklistOptions?.[event.sender.email] || false}
                                onClick={() => handleCheckedBlacklistOption(event.sender.email)}
                              >
                                <Checkbox.Indicator>
                                  <IconGrayChecked className="w-4 h-4" />
                                </Checkbox.Indicator>
                              </Checkbox.Root>
                              <Avatar
                                customCssClass="w-6 h-6 border-light border-border-primary text-xs"
                                initials={getInitials(event.sender.email, 1)}
                                textColor="secondary-text"
                                bgColor="soft-gray"
                                hoverBgColor="soft-gray"
                              />
                              <span className="text-sm">{event.sender.email}</span>
                            </div>
                          ) : null}
                          {blacklistData?.[getDomainFromEmail(event.sender.email)] ? (
                            <div className="flex items-center gap-3">
                              <Checkbox.Root
                                className="w-3.5 h-3.5 border border-light-grey rounded text-center flex justify-center items-center p-1"
                                checked={
                                  blacklistOptions?.[getDomainFromEmail(event.sender.email)] ||
                                  false
                                }
                                onClick={() =>
                                  handleCheckedBlacklistOption(
                                    getDomainFromEmail(event.sender.email)
                                  )
                                }
                              >
                                <Checkbox.Indicator>
                                  <IconGrayChecked className="w-4 h-4" />
                                </Checkbox.Indicator>
                              </Checkbox.Root>
                              <Avatar
                                customCssClass="w-6 h-6 border-light border-border-primary text-xs"
                                initials={getInitials(getDomainFromEmail(event.sender.email), 1)}
                                textColor="secondary-text"
                                bgColor="soft-gray"
                                hoverBgColor="soft-gray"
                              />
                              <span className="text-sm">
                                {getDomainFromEmail(event.sender.email)}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="px-8 py-6 border-t-light border-border-primary flex flex-col gap-8 items-center">
                  <div className="flex flex-row gap-4 items-center">
                    <IconInfo className="w-6 h-6" />
                    <span className="text-xs text-light-grey">
                      Retention of Quarantined/ Junk mails is managed by your mail service provider
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-6">
                    <button
                      className="w-full bg-black rounded-lg text-white p-4 text-center text-base"
                      type="button"
                      onClick={() => handleRemediateClick()}
                    >
                      {loading ? <Loader color="white" /> : 'Trigger Actions'}
                    </button>
                    {!event.isUpvoted ? (
                      <div className="w-full flex justify-center">
                        <button
                          type="button"
                          className={`w-fit bg-transparent text-success-green text-center text-base ${!loading ? 'border-b border-b-success-green' : ''}`}
                          onClick={() => setShowSafeAlert(true)}
                        >
                          {loading ? <Loader color="success-green" /> : 'Mark as Safe?'}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
