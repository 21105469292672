import { NoDataFallback } from 'components/Fallback/NoDataFallback';
import Loader from 'components/Loader';

import { DLPRule, ListDLPFilters } from 'models/dlp';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { DLPRow } from './DLPRow';

interface DLPTableProps {
  hits: DLPRule[];
  limit: number;
  offset: number;
  total: number;
  loading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<ListDLPFilters>>;
}

export function DLPTable({ hits, loading, total, offset, limit, setFilters }: DLPTableProps) {
  const columns = [
    {
      name: 'Creation Date',
      width: 'w-1/7',
    },
    {
      name: 'Ref Id',
      width: 'w-1/7',
    },
    {
      name: 'Policy Name',
      width: 'w-1/7',
    },
    {
      name: 'Policy Type',
      width: 'w-1/7',
    },
    {
      name: 'Protected Data',
      width: 'w-2/7',
    },
    {
      name: 'Status',
      width: 'w-1/7',
    },
    // {
    //   name: 'Actions',
    //   width: 'w-1/7',
    // },
  ];

  const navigate = useNavigate();

  const getFallback = () => {
    if (loading) {
      return (
        <td colSpan={columns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }

    if (!hits?.length) {
      return (
        <td colSpan={columns.length}>
          <NoDataFallback
            title="No Policies configured yet"
            description="Get started on configuring your first policy to view them here"
            actionButtonText="No Policy +"
            onAdd={() => navigate('/dlp/create')}
          />
        </td>
      );
    }

    return null;
  };

  return (
    <>
      <table className="w-full mt-8">
        <colgroup>
          {columns.map((header) => (
            <col key={header.name} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-sm text-light-grey w-full border-y-light border-border-primary">
          <tr>
            {columns.map((column) => (
              <th
                key={column.name}
                scope="col"
                className="py-6 px-3 font-normal text-xs text-start text-light-grey"
              >
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full">
          {loading || !hits?.length
            ? getFallback()
            : hits?.map((dlp) => <DLPRow key={dlp.id} data={dlp} />)}
        </tbody>
      </table>
      {hits?.length && !loading ? (
        <div className="flex justify-between px-6 py-4 items-center">
          <span className="text-xs font-light">
            Showing {hits && hits?.length > 0 ? offset + 1 : '0'} -{' '}
            {hits && hits?.length > 0 ? offset + (hits?.length || 0) : '0'} of {total} events
          </span>
          <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
            <IconArrowDown
              className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${hits && offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (hits) {
                  if (offset > 0) {
                    setFilters((prevFilter) => ({ ...prevFilter, offset: offset - limit }));
                  }
                }
              }}
            />
            <IconArrowDown
              className={`w-6 h-6 -rotate-90 ${hits && offset + limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (hits && offset + limit < total) {
                  setFilters((prevFilters) => ({ ...prevFilters, offset: offset + limit }));
                }
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
