import { useState } from 'react';
import Loader from 'components/Loader';

import { Divider } from 'components/Divider';
import { useDLPForm } from 'contexts/DLPFormContext';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconBlock } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconCorrect } from 'assets/icons/icon-correct.svg';

import { useUpdatePolicyAudienceMutation } from 'service/dlp';
import { PolicyAudience } from 'models/dlp';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';

import { getAllSenderRecipientPairs, validateExceptions } from './data';

export function RulePreview() {
  const { formDetails, onBack, onNext, policyId, mode, setActiveStep, formSteps } = useDLPForm();

  const { showToast } = useToast();

  const [OrgId] = useOrgId();

  const [formLoading, setFormLoading] = useState(false);

  const validateRules = (): boolean => {
    if (!(formDetails?.isInternal || formDetails?.isExternal)) return false;

    const validAudience = ['all_internal', 'all_external', 'email', 'domain'];

    if (!validAudience.includes(formDetails.blockRule?.sender?.type || '')) return false;
    if (!validAudience.includes(formDetails.blockRule?.recipient?.type || '')) return false;

    return validateExceptions(formDetails?.exceptions || []);
  };

  const [updatePolicyAudience] = useUpdatePolicyAudienceMutation();

  const handleNext = async () => {
    if (!validateRules()) return;

    setFormLoading(true);
    const request: PolicyAudience = {
      block: {
        sender:
          formDetails.blockRule?.sender.value === 'All Internal Users'
            ? formDetails?.blockRule?.sender?.type
            : formDetails.blockRule?.sender?.value || '',
        recipient:
          formDetails.blockRule?.sender.value === 'All External Users'
            ? formDetails?.blockRule?.recipient?.type
            : formDetails.blockRule?.recipient?.value || '',
      },
      exceptions:
        formDetails.exceptions?.map((r) => ({
          sender: r.sender.value === 'All Internal Users' ? r.sender.type : r.sender.value,
          recipients: r.recipients.map((rc) =>
            rc.value === 'All External Users' ? rc.type : rc.value
          ),
        })) || [],
      scope: formDetails.isExternal ? 'external' : 'internal',
    };

    try {
      await updatePolicyAudience({ ...request, orgId: OrgId, policyId }).unwrap();
      if (mode === 'edit') {
        setActiveStep(formSteps.length - 1);
      } else {
        onNext();
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again" />,
      });
    } finally {
      setFormLoading(false);
    }
  };

  const handleBack = () => {
    if (mode === 'edit') {
      setActiveStep(formSteps.length - 1);
    } else {
      onBack();
    }
  };

  return (
    <div className="px-7 py-12 flex flex-col gap-4 justify-between h-full">
      <div className="flex-1 h-full overflow-y-auto no-scrollbar">
        <div className="flex flex-col gap-4">
          <span className="text-sm text-light-grey">Policy Preview</span>
          {/* <button
            type="button"
            className="py-2 px-4 rounded-lg border-light border-border-primary w-fit"
          >
            Sensitive Data
          </button> */}
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          <span>This policy will be applied to mails sent</span>
          <div className="flex items-center gap-2 flex-wrap">
            {formDetails?.isExternal && (
              <button
                type="button"
                className="px-2.5 py-2 rounded-lg border-light border-border-primary bg-soft-purple flex items-center gap-2"
              >
                <IconGlobe />
                <span className="text-sm">Externally</span>
              </button>
            )}
            {formDetails?.isInternal && (
              <button
                type="button"
                className="px-2.5 py-2 rounded-lg border-light border-border-primary bg-soft-blue flex items-center gap-2"
              >
                <IconIndustry />
                <span className="text-sm">Internally</span>
              </button>
            )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          <span className="text-sm text-light-grey">Block Rule</span>
          <div className="flex justify-between items-center gap-2">
            <span className="text-sm">{formDetails.blockRule?.sender.value || ''}</span>
            <div className="w-6 h-6 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-red">
              <IconBlock className="w-4 h-4 fill-error-text" />
            </div>
            <span className="text-sm text-end">{formDetails.blockRule?.recipient.value || ''}</span>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-4">
          <span className="text-sm text-light-grey">Exceptions</span>
          <div className="flex flex-col gap-2 w-full">
            {getAllSenderRecipientPairs(formDetails.exceptions || [])
              .filter((p) => p.sender !== '' && p.recipient !== '')
              .map((pair) => (
                <div className="flex justify-between items-center gap-2">
                  <span className="text-sm break-words flex-1">{pair.sender || ''}</span>
                  <div className="w-6 h-6 bg-soft-green rounded-lg border-light border-border-primary flex items-center justify-center">
                    <IconCorrect className="w-4 h-4" />
                  </div>
                  <span className="text-sm text-end break-words flex-1">
                    {pair.recipient || ''}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <button
          type="button"
          className="flex-1 rounded-lg border-light border-border-primary py-4 shadow-sm text-sm"
          onClick={() => handleBack()}
        >
          Back
        </button>
        <button
          type="button"
          className={`rounded-lg flex-1 py-4 text-white ${!validateRules() ? 'bg-black/50 cursor-not-allowed' : 'bg-black'} text-sm`}
          onClick={() => handleNext()}
        >
          {formLoading ? (
            <Loader color="white" />
          ) : (
            <span>{mode === 'edit' ? 'Save Changes' : 'Next'}</span>
          )}
        </button>
      </div>
    </div>
  );
}
