import * as Dialog from '@radix-ui/react-dialog';

import { Divider } from 'components/Divider';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import { useState } from 'react';
import { ListRecord, RuleFilter } from 'models/rules';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useOrgId } from 'hooks/useOrgId';
import { useCreateAllowedListMutation } from 'service/whitelist';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { useToast } from 'hooks/toast';
import { useCreateBlacklistMutation } from 'service/eventsApi';
import { useGetConfigQuery, useUpdateConfigMutation } from 'service/config';

import { BlockRuleList } from './BlockRuleList';
import { AllowRuleList } from './AllowRuleList';
import { RulesForm } from './RulesForm';
import { RulesConfirmationModal } from './RulesConfirmationModal';

export function BlockAndAllowRules() {
  const [selectedTab, setSelectedTab] = useState(0);

  const { showToast } = useToast();

  const filterOption = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Email Addresses',
      value: 'sender',
    },
    {
      label: 'Domains',
      value: 'domain',
    },
    {
      label: 'IP Addresses',
      value: 'ip',
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => {
    setShowModal((prevState) => !prevState);
  };

  const [OrgId] = useOrgId();

  const [createBlacklist] = useCreateBlacklistMutation();

  const [lists, setLists] = useState<ListRecord[]>([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleAddBlacklist = async () => {
    try {
      await createBlacklist({
        orgId: OrgId,
        listEntries: lists,
      }).unwrap();

      showToast({
        component: <SuccessToast message={`Successfully added ${lists.length} blacklist rules`} />,
      });

      setShowConfirmationModal(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error as FetchBaseQueryError;

      showToast({
        component: <ErrorToast message={(message.data as string) || 'Something went wrong'} />,
      });
    }
  };

  const [createAllowList] = useCreateAllowedListMutation();
  const handleAddAllowList = async () => {
    try {
      await createAllowList({
        orgId: OrgId,
        listEntries: lists,
      }).unwrap();

      showToast({
        component: <SuccessToast message={`Successfully added ${lists.length} blacklist rules`} />,
      });

      setShowConfirmationModal(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error as FetchBaseQueryError;

      showToast({
        component: <ErrorToast message={(message.data as string) || 'Something went wrong'} />,
      });
    }
  };

  const handleSubmit = (records: ListRecord[]) => {
    setShowConfirmationModal(() => {
      // updating lists in callback so that before the
      // confirmation modal becomes visible the lists state is set
      setLists(records);
      setShowModal(false);
      return true;
    });
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const { data, isLoading } = useGetConfigQuery({ orgId: OrgId });

  const [updateConfig] = useUpdateConfigMutation();

  const handleUpdateConfig = async (checked: boolean) => {
    try {
      await updateConfig({
        orgId: OrgId,
        mode: checked ? 'PREVENT' : 'MANUAL',
      }).unwrap();

      setShowModal(false);

      showToast({
        component: checked ? (
          <SuccessToast message="Prevent mode enabled" />
        ) : (
          <ErrorToast message="Prevent mode disabled" />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again" />,
      });
    }
  };

  const getPreventModeStatus = () => {
    if (isLoading) return false;
    return data?.applicationMode?.mode === 'prevent';
  };
  return (
    <>
      <div className="px-10 py-8 w-full">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-xl">Block and Allow Rules</h2>
          <div className="flex items-center gap-2">
            <InfoIcon className="w-3.5 h-3.5 fill-light-grey" />
            <span className="text-light-grey italic text-sm">
              Most changes take effect within a few minutes.
            </span>
          </div>
        </div>
        <Divider customCss="my-8" />
        <div className="flex items-center justify-between">
          <div className="w-fit rounded-md border-light border-border-primary p-1 bg-soft-gray">
            <button
              type="button"
              className={`min-w-24 py-2.5 ${selectedTab === 0 ? 'text-critical-red border-light bg-white border-border-primary rounded-md text-center' : 'text-light-grey'}`}
              onClick={() => setSelectedTab(0)}
            >
              Block
            </button>
            <button
              type="button"
              className={`min-w-24 py-2.5 ${selectedTab === 1 ? 'text-success-green border-light bg-white border-border-primary rounded-md text-center' : 'text-light-grey'}`}
              onClick={() => setSelectedTab(1)}
            >
              Allow
            </button>
          </div>
          {!getPreventModeStatus() ? (
            <div className="w-fit px-7 py-4 bg-soft-red flex items-center justify-between gap-8 border-light border-border-primary rounded-lg">
              <div className="flex-1 flex flex-col">
                <span className="text-sm text-black">
                  Block & Allow Rules are currently inactive.
                </span>
                <span className="text-sm text-light-grey">
                  Enable Prevent mode to activate block & allow rules
                </span>
              </div>
              <button
                type="button"
                onClick={() => handleUpdateConfig(true)}
                className="rounded-lg bg-black text-white p-3"
              >
                Enable Prevent Mode
              </button>
            </div>
          ) : null}
        </div>
        <Divider customCss="my-8" />
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center gap-4">
            {filterOption.map((filter, index) => (
              <button
                key={filter.label}
                className={`${index === selectedFilter ? 'bg-black text-white' : 'bg-soft-gray text-black'} rounded-full text-center px-4 py-1.5 border-light border-border-primary`}
                type="button"
                onClick={() => setSelectedFilter(index)}
              >
                {filter.label}
              </button>
            ))}
          </div>
          <button
            type="button"
            className="px-4 py-2 text-white bg-black rounded-md text-sm"
            onClick={() => handleToggle()}
          >
            Add a {selectedTab === 0 ? 'Block' : 'Allow'} Rule +
          </button>
        </div>
        {selectedTab === 0 ? (
          <BlockRuleList
            onAddRule={() => setShowModal(true)}
            ruleType={filterOption[selectedFilter].value as RuleFilter | ''}
          />
        ) : (
          <AllowRuleList
            onAddRule={() => setShowModal(true)}
            ruleType={filterOption[selectedFilter].value as RuleFilter | ''}
          />
        )}
      </div>
      <Dialog.Root open={showModal} onOpenChange={handleToggle}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-20" onClick={handleToggle} />
        <Dialog.Content className="fixed top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 w-2/5 bg-white border-light border-border-primary rounded-md z-20">
          {selectedTab === 0 ? (
            <RulesForm
              onSubmit={(rules) => handleSubmit(rules)}
              onClose={() => setShowModal(false)}
              ruleType="blacklist"
            />
          ) : (
            <RulesForm
              onClose={() => setShowModal(false)}
              onSubmit={(rules) => handleSubmit(rules)}
              ruleType="allowlist"
            />
          )}
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root open={showConfirmationModal} onOpenChange={setShowConfirmationModal}>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-20" onClick={handleToggle} />
        <Dialog.Content className="fixed top-1/2 left-1/2 flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 w-1/4 bg-white border-light border-border-primary rounded-md z-20">
          {selectedTab === 0 ? (
            <RulesConfirmationModal
              onSubmit={() => handleAddBlacklist()}
              onClose={() => handleCloseConfirmationModal()}
              ruleType="blacklist"
              lists={lists}
            />
          ) : (
            <RulesConfirmationModal
              onSubmit={() => handleAddAllowList()}
              onClose={() => handleCloseConfirmationModal()}
              ruleType="allowlist"
              lists={lists}
            />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
