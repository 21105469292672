import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactElement } from 'react';

interface ActionMenuProps {
  icon: ReactElement;
  actions: {
    icon?: React.ReactNode;
    title: string;
    onClick: () => void;
  }[];
  customCss?: string;
}

export default function ActionMenu({ icon, actions, customCss = 'text-end' }: ActionMenuProps) {
  const handleActionClick = (e: React.MouseEvent, action: () => void) => {
    e.stopPropagation();
    action();
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={customCss} asChild>
        <div>{icon}</div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <div className="w-full">
          <DropdownMenu.Content
            className="z-20 data-[state=open]:outline-none w-full animate-fade-in bg-white rounded-md flex flex-col text-xs p-2 shadow-custom"
            side="left"
            align="start"
          >
            {actions.map((action) => (
              <DropdownMenu.Item
                className="px-3 py-1.5 hover:bg-select-background hover:text-black text-light-grey bg-transparent cursor-pointer outline-none flex items-center gap-2 data-[highlighted]:fill-black fill-light-grey"
                onClick={(e) => handleActionClick(e, action.onClick)}
              >
                {action.icon}
                <span>{action.title}</span>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </div>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
