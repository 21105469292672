import { useNavigate } from 'react-router-dom';

import * as Dialog from '@radix-ui/react-dialog';

import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';

import { ReactComponent as IconBlocked } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconCorrect } from 'assets/icons/icon-correct.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconPencil } from 'assets/icons/icon-pencil.svg';

import { Divider } from 'components/Divider';
import { useCallback, useState } from 'react';
import { useOrgId } from 'hooks/useOrgId';
import {
  useDeletePolicyMutation,
  useGetPolicyQuery,
  useUpdatePolicyStatusMutation,
} from 'service/dlp';
import Loader from 'components/Loader';
import { transformAudienceToExceptions } from 'contexts/DLPFormContext.data';
import * as Switch from '@radix-ui/react-switch';

import { toTitleCase } from 'utils/name';
import { useToast } from 'hooks/toast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';

import { PolicyStatus } from 'enums/dlp';
import { dateUtils } from 'utils/time';

import {
  emailPartDetails,
  getAllSenderRecipientPairs,
  getBgColor,
  policyActionDetails,
} from './data';

import { DLPItemBadge } from './DLPItemBadge';
import { DLPAlert } from './DLPAlert';

export interface DLPDetailsProps {
  open: boolean;
  onClose: () => void;
  policyId: string;
}

export function DLPDetails({ policyId, open, onClose }: DLPDetailsProps) {
  const [orgId] = useOrgId();

  const navigate = useNavigate();

  const { showToast } = useToast();

  const { data: details, isLoading } = useGetPolicyQuery({ orgId, policyId }, { skip: !open });

  const [tab, setTab] = useState(0);

  const [showDelete, setShowDelete] = useState(false);

  const [openCategoryIdx, setOpenCategoryIdx] = useState<number>(0);

  const getExceptionDetails = useCallback(() => {
    const exceptions = transformAudienceToExceptions(details?.audience || []);
    if (exceptions) {
      return getAllSenderRecipientPairs(exceptions);
    }
    return [];
  }, [details?.audience]);

  const [deletePolicy] = useDeletePolicyMutation();

  const [updatePolicyStatus] = useUpdatePolicyStatusMutation();

  const handleDiscard = async () => {
    try {
      await deletePolicy({
        orgId: orgId,
        policyId,
      }).unwrap();
      onClose();
      showToast({
        component: <SuccessToast message="Successfully discarded the policy" />,
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again!" />,
      });
    }
  };

  const handleUpdatePolicyStatus = async () => {
    try {
      await updatePolicyStatus({
        orgId: orgId,
        policyId: policyId,
        status: details?.status === 'active' ? 'inactive' : 'active',
      }).unwrap();

      showToast({
        component: (
          <SuccessToast
            message={`Success ${details?.status === 'active' ? 'deactivated' : 'activated'} the policy`}
          />
        ),
      });
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again!" />,
      });
    }
  };

  if (!details || isLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 z-40" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-3/4 z-40"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-1/2 h-overview-modal  bg-white rounded-3xl">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const handleEdit = () => {
    const params = new URLSearchParams({
      pId: policyId,
      mode: 'edit',
    });

    navigate(`/dlp/create?${params.toString()}`);
  };

  return !showDelete ? (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 z-40" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-1/2 z-40"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full bg-white rounded-3xl border-light border-border-primary flex flex-col justify-between max-h-overview-modal">
            <div className="px-7 py-9 border-b-light border-border-primary flex flex-col gap-6">
              <span>{details?.name || ''}</span>
              <div className="flex items-center gap-2">
                <div className="flex items-center px-3 py-2 bg-soft-red rounded-md border-light border-border-primary gap-2">
                  <IconBlocked className="fill-error-text" />
                  <p>
                    This policy will <strong className="text-error-text">block</strong> emails with
                    sensitive data
                  </p>
                </div>
                <button
                  type="button"
                  className={`border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3 ${details.isExternal ? 'bg-soft-purple' : 'bg-soft-blue'}`}
                >
                  {details.isExternal ? <IconGlobe /> : <IconIndustry />}
                  <span className="text-sm text-black">
                    {details.isExternal ? 'Externally' : 'Internally'}
                  </span>
                </button>
              </div>
            </div>
            <div className="px-7 py-9 flex items-center justify-between border-y-light border-border-primary">
              <div className="flex flex-col gap-4 text-sm">
                <span className="text-light-grey">Created by</span>
                <span>{details.createdBy}</span>
              </div>
              <div className="flex flex-col gap-4 text-sm">
                <span className="text-light-grey">Last updated at</span>
                <span>{dateUtils.format(details.updatedAt, 'HH:mm A, DD/MM/YYYY').toString()}</span>
              </div>
              <div className="flex flex-col gap-4 text-sm">
                <span className="text-light-grey">Updated by</span>
                <span>{details.updatedBy}</span>
              </div>
            </div>
            <div className="px-7 py-9 h-full flex-1 overflow-hidden overflow-y-auto max-h-dlp-modal">
              <div className="flex items-center gap-4">
                <button
                  type="button"
                  className={`${tab === 0 ? 'bg-black text-white border-light border-black' : 'bg-white text-black border-light border-border-primary'} px-4 py-3 rounded-full text-sm`}
                  onClick={() => setTab(0)}
                >
                  Protected Data Types
                </button>
                <button
                  type="button"
                  className={`${tab === 1 ? 'bg-black text-white border-light border-black' : 'bg-white text-black border-light border-border-primary'} px-4 py-3 rounded-full text-sm`}
                  onClick={() => setTab(1)}
                >
                  Rules, Exceptions and Actions
                </button>
              </div>
              <Divider />
              {tab === 0 ? (
                <>
                  <div className="flex flex-col gap-6">
                    <span className="text-light-grey text-sm">Protected Data Types</span>
                    {details.categories?.length
                      ? details.categories.map((c, idx) => {
                          return (
                            <>
                              <div className="flex flex-col gap-6">
                                <div className="flex items-center gap-4 justify-between">
                                  <div className="flex items-center gap-4">
                                    <span>{c.category}</span>
                                  </div>
                                  <IconArrowDown
                                    className={`w-8 h-8 transition-transform duration-400 ${openCategoryIdx !== idx ? '' : 'rotate-180'} stroke-light-grey cursor-pointer`}
                                    onClick={() =>
                                      setOpenCategoryIdx((prev) => (prev === idx ? -1 : idx))
                                    }
                                  />
                                </div>
                                {idx === openCategoryIdx ? (
                                  <div className="flex items-center gap-4 flex-wrap">
                                    {c.dataTypes.map((d) => (
                                      <DLPItemBadge label={d.label} />
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                              {(details?.categories?.length || 0) - 1 !== idx ? (
                                <Divider customCss="my-0" />
                              ) : null}
                            </>
                          );
                        })
                      : null}
                  </div>
                  <Divider />
                  <div className="flex flex-col gap-6">
                    <span className="text-light-grey text-sm">Detection Scope</span>
                    <div className="flex items-center flex-wrap gap-2">
                      {details.scans.map((c) => (
                        <DLPItemBadge label={emailPartDetails[c]?.title} />
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-6">
                    <div className="flex items-center gap-2.5">
                      <button
                        type="button"
                        className="w-8 h-8 flex items-center justify-center border-light border-border-primary rounded-lg bg-soft-red"
                      >
                        <IconBlocked className="fill-error-text" />
                      </button>
                      <span>Block Rule</span>
                    </div>
                    <div className="flex items-center gap-6">
                      <button
                        type="button"
                        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3 bg-soft-gray"
                      >
                        <div className="w-6 h-6 rounded-full bg-soft-blue flex items-center justify-center">
                          <IconIndustry />
                        </div>
                        <span className="text-sm text-light-grey">All Internal Users</span>
                      </button>
                      <div className="w-6 h-6 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-red">
                        <IconBlocked className="w-4 h-4 fill-error-text" />
                      </div>
                      <button
                        type="button"
                        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3 bg-soft-gray"
                      >
                        <div className="w-6 h-6 rounded-full bg-soft-purple flex items-center justify-center">
                          <IconGlobe className="w-4 h-4" />
                        </div>
                        <span className="text-sm text-light-grey">All External Users</span>
                      </button>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="text-sm text-light-grey">Actions</span>
                      <div className="flex items-center flex-wrap gap-2">
                        {details?.actions
                          ?.find((f) => f.rule === 'block')
                          ?.actions?.map((a) => (
                            <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-gray text-sm w-fit">
                              {policyActionDetails[a]?.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {getExceptionDetails()?.length > 0 ? (
                    <>
                      <Divider />
                      <div className="flex flex-col gap-6">
                        <div className="flex items-center gap-2.5">
                          <button
                            type="button"
                            className="w-8 h-8 flex items-center justify-center border-light border-border-primary rounded-lg bg-soft-green"
                          >
                            <IconCorrect className="fill-success-green" />
                          </button>
                          <span>Allow (Exceptions) Rule</span>
                        </div>
                        <div className="w-full flex flex-col gap-2">
                          {getExceptionDetails()?.length
                            ? getExceptionDetails().map((e) => (
                                <div className="flex items-center gap-6">
                                  <button
                                    type="button"
                                    className="px-2 py-1 bg-soft-gray border-light border-border-primary rounded-lg text-sm w-40"
                                  >
                                    {e.sender}
                                  </button>
                                  <div className="w-6 h-6 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-green">
                                    <IconCorrect className="w-4 h-4 fill-success-green" />
                                  </div>
                                  <button
                                    type="button"
                                    className="px-2 py-1 bg-soft-gray border-light border-border-primary rounded-lg text-sm w-40"
                                  >
                                    {e.recipient}
                                  </button>
                                </div>
                              ))
                            : null}
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-sm text-light-grey">Actions</span>
                          <div className="flex items-center flex-wrap gap-2">
                            {details?.actions
                              ?.find((f) => f.rule === 'allow')
                              ?.actions?.map((a) => (
                                <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-gray text-sm w-fit">
                                  {policyActionDetails[a]?.name}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {details.severity !== '' ? (
                    <>
                      <Divider />
                      <div className="flex flex-col gap-6">
                        <span className="text-sm text-light-grey">Policy Severity</span>
                        <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-gray text-sm w-fit">
                          {toTitleCase(details.severity)}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
            <div className="px-7 py-9 border-t-light border-t-border-primary flex items-center justify-between">
              <div className="flex items-center gap-6">
                <div className="flex items-center justify-between gap-4">
                  <button
                    type="button"
                    className="rounded-lg px-3 py-2 bg-black border-light border-black text-white flex items-center gap-2"
                    onClick={() => handleEdit()}
                  >
                    <IconPencil className="fill-white w-3.5 h-3.5" />
                    <span className="text-sm">Edit</span>
                  </button>
                  <button
                    type="button"
                    className="rounded-lg px-3 py-2 border-light border-border-primary"
                    onClick={() => setShowDelete(true)}
                  >
                    <IconTrash className="w-5 h-5" />
                  </button>
                </div>
              </div>
              {details.status !== 'draft' ? (
                <button
                  className={`${getBgColor(details.status as PolicyStatus)} rounded-lg border-light border-border-primary flex items-center gap-4 px-3 py-2`}
                  type="button"
                >
                  <span>Policy {toTitleCase(details.status)}</span>
                  <Switch.Root
                    checked={details?.status === 'active'}
                    className="relative h-6 w-10 cursor-pointer rounded-full outline-none focus:shadow-black data-[state=checked]:bg-gradient-to-r bg-border-primary from-gradient-yellow to-gradient-green border border-border-primary"
                    onCheckedChange={() => handleUpdatePolicyStatus()}
                  >
                    <Switch.Thumb className="border border-border-primary block size-5 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-4" />
                  </Switch.Root>
                </button>
              ) : null}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  ) : (
    <DLPAlert
      open={showDelete}
      handleClose={() => setShowDelete(false)}
      onCancel={() => setShowDelete(false)}
      onConfirm={() => handleDiscard()}
      description="Are you sure you want to delete this policy?"
    />
  );
}
