import { ReactComponent as IconBlockList } from 'assets/icons/add-block-list.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';

import { Divider } from 'components/Divider';
import { useDLPForm } from 'contexts/DLPFormContext';

export function DLPActionsPanel() {
  const { formSteps, activeStep, formDetails, formStatus } = useDLPForm();

  return (
    <div className="border-r-light border-border-primary h-full overflow-auto no-scrollbar flex flex-col gap-8 pr-8 py-12 max-w-96">
      <div>
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-lg border-light border-border-primary flex items-center justify-center p-2">
            <IconBlockList className="fill-light-grey w-full h-full" />
          </div>
          <span>{formDetails?.name?.value || 'Untitled Policy'}</span>
        </div>
        <Divider customCss="my-4" />
      </div>
      <div className="flex flex-col gap-10 w-full">
        {formSteps.map((step, index) => (
          <div
            className={`py-2 px-4 flex items-center justify-between gap-4 w-full ${index === activeStep ? 'rounded-lg bg-soft-gray border-light border-border-primary' : 'text-light-grey'}`}
          >
            <button type="button" className="text-start flex flex-col flex-1">
              <p className="text-base">{step.name}</p>
              <p className="text-sm break-words w-full max-w-full">{step.description}</p>
            </button>
            {formStatus[index] ? (
              <div className="w-5 h-5 bg-green-400 rounded-full text-center flex items-center justify-center">
                <IconCheck className="w-3 h-3" />
              </div>
            ) : (
              // adding a placeholder div to avoid change in width
              <div className="w-5 h-5" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
