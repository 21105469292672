import { ReactComponent as IconAlert } from 'assets/icons/alert-rules.svg';

interface NoDataFallbackProps {
  title: string;
  description: string;
  actionButtonText: string;
  onAdd: () => void;
}
export function NoDataFallback({
  title,
  description,
  actionButtonText,
  onAdd,
}: NoDataFallbackProps) {
  return (
    <div className="w-full py-12 flex flex-col justify-center items-center gap-6 bg-white rounded-lg">
      <IconAlert />
      <span className="text-xl">{title}</span>
      <span className="text-sm text-center text-light-grey">{description}</span>
      <button
        type="button"
        className="px-4 py-2 text-white bg-black rounded-md text-sm"
        onClick={() => onAdd()}
      >
        {actionButtonText}
      </button>
    </div>
  );
}
