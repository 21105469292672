import { useState } from 'react';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

interface ExpandableToastProps {
  icon: React.ReactNode;
  // children is the component which will be displayed when the
  // user expands the toast
  children: React.ReactNode;

  // the message to be displayed inside the toast
  message: string;
}

export function ExpandableToast({ children, message, icon }: ExpandableToastProps) {
  const [open, setOpen] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="w-fit min-w-[420px] rounded-lg flex flex-col border-light gap-2 bg-white border-border-primary shadow-md"
      onClick={() => setOpen(!open)}
    >
      <div className="w-full flex items-center justify-between cursor-pointer gap-4 p-4">
        <div className="flex items-center gap-4">
          {icon}
          <span className="text-base">{message}</span>
        </div>
        <button
          type="button"
          className="text-gray-500 hover:text-gray-700"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          {!open ? (
            <IconArrowDown className="w-4 h-4" />
          ) : (
            <IconArrowDown className="w-4 h-4 rotate-180" />
          )}
        </button>
      </div>
      {open && <div className="bg-soft-gray">{children}</div>}
    </div>
  );
}
