import * as Tooltip from '@radix-ui/react-tooltip';

interface InfoTooltipProps {
  message: string;
  icon: React.ReactNode;
}
export function InfoTooltip({ message, icon }: InfoTooltipProps) {
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{icon}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="select-none text-xs font-normal rounded bg-secondary-text text-white px-[15px] py-2.5 leading-none will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade max-w-96 p-4"
            sideOffset={5}
          >
            {message}
            <Tooltip.Arrow className="fill-secondary-text" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
