import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconBlocked } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { useDLPForm } from 'contexts/DLPFormContext';

// import { BlockRule } from './data';

// interface BlockFormProps {
//   rule?: BlockRule;
//   // onChange : (rule: BlockRule) => void;
// }

export function BlockForm() {
  const { formDetails } = useDLPForm();
  return (
    <div className="w-full rounded-2xl border-light border-border-primary p-7 flex items-center gap-4 bg-white justify-between">
      <button
        type="button"
        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
      >
        <div className="w-6 h-6 rounded-full bg-soft-blue flex items-center justify-center">
          <IconIndustry />
        </div>
        <span className="text-sm text-light-grey">{formDetails.blockRule?.sender.value}</span>
      </button>
      <span className="text-sm">are</span>
      <button
        type="button"
        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
      >
        <IconBlocked className="fill-error-text" />
        <span className="text-sm text-light-grey">Blocked</span>
      </button>
      <span className="text-sm">from sending</span>
      <button
        type="button"
        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
      >
        <IconGlobe />
        <span className="text-sm text-light-grey">
          {formDetails.isExternal ? 'Externally' : 'Internally'}
        </span>
      </button>
      <span className="text-sm">to</span>
      <button
        type="button"
        className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
      >
        <div className="w-6 h-6 rounded-full bg-soft-purple flex items-center justify-center">
          <IconGlobe className="w-4 h-4" />
        </div>
        <span className="text-sm text-light-grey">{formDetails.blockRule?.recipient?.value}</span>
      </button>
    </div>
  );
}
