import * as Dialog from '@radix-ui/react-dialog';
import * as Accordion from '@radix-ui/react-accordion';

import Loader from 'components/Loader';
import { BlacklistItem } from 'models/events';
import { ReactComponent as IconCross } from 'assets/icons/cross-close.svg';
import { ReactComponent as IconChevronDown } from 'assets/icons/button-with-circle.svg';
import { ReactComponent as IconRemediationAlert } from 'assets/icons/remediation-alert.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info-icon.svg';

import { dateUtils } from 'utils/time';
import { Divider } from 'components/Divider';
import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { useRevertBlacklistEventMutation } from 'service/eventsApi';
import { useToast } from 'hooks/toast';
import { useOrgId } from 'hooks/useOrgId';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { getEmailDetails } from 'components/Events/data';

interface BlacklistOverview {
  event: BlacklistItem | undefined;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
}
export function BlacklistOverview({ event, open, onClose, isLoading }: BlacklistOverview) {
  const [revertBlacklist, { isLoading: loading }] = useRevertBlacklistEventMutation();

  const { showToast } = useToast();

  const [orgId] = useOrgId();

  if (!event || isLoading) {
    return (
      <Dialog.Root open={open} onOpenChange={onClose}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
          <Dialog.Content
            className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-1/3"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-2/3 bg-white rounded-lg px-12 py-6">
              <Loader />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }

  const handleRevertBlacklist = async () => {
    try {
      await revertBlacklist({
        messageId: event.messageId,
        orgId,
      });

      showToast({
        component: <SuccessToast message="Successfully reverted the blacklist message" />,
      });

      onClose();
    } catch {
      showToast({
        component: <ErrorToast message="Unable to revert blacklist. Please try again!" />,
      });
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50" onClick={onClose} />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 flex justify-center transform -translate-x-1/2 -translate-y-1/2 w-1/3 bg-white rounded-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full bg-white relative rounded-lg overflow-scroll no-scrollbar max-h-blacklist-modal flex flex-col justify-between">
            <button
              type="submit"
              className="absolute w-fit top-4 right-4 p-1 rounded-full border-light border-border-primary flex justify-center items-center hover:bg-black cursor-pointer"
              onClick={() => onClose()}
              aria-label="Close"
            >
              <IconCross className="w-3 h-3 hover:stroke-white" />
            </button>
            <div className="w-full py-8 px-12 flex items-center gap-4 border-b-light border-border-primary">
              <IconRemediationAlert className="w-10 h-10" />
              <span className="text-light-grey text-xl">Mail Auto-Remediated</span>
              <button
                type="button"
                className="py-1 px-2 border-light border-border-primary bg-soft-gray text-light-grey rounded-md"
              >
                {dateUtils.format(event?.remediationDate || '', 'DD/MM/YYYY, HH:mm A').toString()}
              </button>
            </div>

            <div className="px-12 py-6 flex flex-col flex-1 overflow-y-scroll">
              <button
                type="button"
                className="w-fit bg-soft-blue rounded-lg border-light border-border-primary p-2"
              >
                🚫 {event.actionDetails}
              </button>
              <div>
                <Divider />
                <div className="flex flex-col gap-4">
                  <span className="text-gray-text text-sm">Date & Time</span>
                  <span className="text-black text-sm">
                    {dateUtils.format(event.remediationDate, 'DD/MM/YYYY, HH:mm A').toString()}
                  </span>
                </div>
                <Divider />
                <div className="flex flex-col gap-4">
                  <span className="text-gray-text text-sm">Mail Subject</span>
                  <span className="text-black text-sm">{event.subject}</span>
                </div>
                <Divider />
                <div className="flex flex-col gap-4">
                  <span className="text-gray-text text-sm">Sender</span>
                  <div className="flex items-center gap-2">
                    <Avatar
                      bgColor="soft-blue"
                      textColor="avatar-blue"
                      hoverBgColor="soft-blue"
                      initials={getInitials(event.sender.email, 1)}
                      customCssClass="w-6 h-6 p-1.5 text-xs"
                    />
                    <span className="text-sm text-black">{getEmailDetails(event.sender)}</span>
                  </div>
                </div>
                <Divider />
                <Accordion.Root type="single" collapsible>
                  <Accordion.Item value="recipients">
                    <Accordion.Trigger className="flex justify-between gap-4 w-full items-center [&[data-state=open]>svg]:rotate-180">
                      <span className="text-sm text-light-grey">
                        Recipients {`(${event?.recipients?.length})`}
                      </span>
                      <IconChevronDown className="w-4 h-4 transition-transform duration-400" />
                    </Accordion.Trigger>
                    <Accordion.Content className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden">
                      <div className="flex flex-col gap-4 mt-4">
                        {event?.recipients?.map((recipient) => (
                          <div className="flex justify-between items-center mt-4 gap-4">
                            <div className="flex items-center gap-4">
                              <Avatar
                                initials={getInitials(recipient.email, 2)}
                                bgColor="avatar-blue"
                                textColor="white"
                                customCssClass="w-6 h-6 p-1 text-xs"
                              />
                              <span className="text-sm text-black">
                                {getEmailDetails(recipient)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
                <Divider />
                <div className="flex flex-col gap-4">
                  <span className="text-gray-text text-sm">Actions Taken</span>
                  <button
                    type="button"
                    className="border-light border-border-primary text-black rounded-lg bg-soft-gray py-1 px-2 w-fit text-sm"
                  >
                    Push Mail to Quarantine
                  </button>
                </div>
              </div>
            </div>

            <div className="pt-3 pb-9 px-11 flex flex-col gap-8 border-t-light border-border-primary">
              <div className="flex items-center gap-4">
                {' '}
                <IconInfo className="w-6 h-6" />{' '}
                <span className="text-sm text-light-grey">
                  Retention of Quarantined/ Junk mails is managed by your mail service provider
                </span>
              </div>
              <button
                type="button"
                className="bg-black rounded-full text-white text-lg text-center w-full py-6"
                onClick={() => handleRevertBlacklist()}
              >
                {!loading ? 'Restore Mail' : <Loader color="white" />}
              </button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
