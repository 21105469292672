import { ReactComponent as IconFinance } from 'assets/icons/icon-finance.svg';
import { ReactComponent as IconPersonal } from 'assets/icons/icon-personal-management.svg';
import { ReactComponent as IconIP } from 'assets/icons/icon-ip.svg';
import { ReactComponent as IconLegal } from 'assets/icons/icon-legal.svg';
import { ReactComponent as IconBusiness } from 'assets/icons/icon-business.svg';

import { ReactComponent as IconMessage } from 'assets/icons/icon-envelope.svg';
import { ReactComponent as IconAttachment } from 'assets/icons/icon-file.svg';
import { ReactComponent as IconGallery } from 'assets/icons/icon-gallery.svg';
import { ReactComponent as IconLink } from 'assets/icons/icon-link.svg';

import { DataType, Category } from 'models/categories';
import { PolicyStatus } from 'enums/dlp';

export const ruleNameToDataTypes: Record<string, string[]> = {
  rule_1: [
    'Aadhaar Number',
    'PAN Number',
    'Voter ID/EPIC Card',
    'Driving License',
    'Student ID Cards',
    'Armed Forces ID',
    'Pay Slips',
    'Company ID Card',
    'Disability ID',
    'Mnrega Number',
    'IBAN',
    'IFSC',
  ],
  rule_2: [
    'Credit Card Numbers',
    'CVV / CVC Codes',
    'Debit Card Number',
    'Card Holder Names',
    'UPI ID',
    'Payment QR Codes',
    'Bank Account Numbers',
    'Payment Amounts',
    'Payment Timestamps',
    'Transaction IDs',
    'Invoice Details',
    'Profit & Loss Statements',
    'Cash Flow Statements',
    'Balance Sheets',
  ],
  rule_3: [
    'Passwords',
    'API Keys',
    'Access Tokens',
    'Authentication Cookies',
    'OAuth Credentials',
    'SSH Keys',
    'Digital Certificates',
    'Private Keys',
    'Security Questions & Answers',
    'Session IDs',
    'JWT Tokens',
    'Database Connection Strings',
    'VPN Credentials',
    'Admin Portal Credentials',
    'Encryption Keys',
  ],
  rule_4: [
    'Patent Documents',
    'Proprietary Files',
    'Code Packages',
    'Code Snippets',
    'Configuration Documents',
    'API Documentation',
    'Encryption Documents',
  ],
  rule_5: [
    'Legal Agreements',
    'MOUs',
    'Partnership or Investment Agreements',
    'ESOP Documents',
    'Compliance Reports',
    'Audit Records',
    'Regulatory Filings',
  ],
  rule_6: [
    'Customer Data',
    'Revenue Data',
    'Region-wise Data',
    'Campaign Performance',
    'Sales Presentations',
    'Marketing Materials',
  ],
  rule_7: [
    'Pricing Data',
    'Tender Data',
    'RFP/RFQ Information',
    'Name of Partners/Resellers',
    'Financial Summaries',
    'Profit & Loss',
    'Cash Flow Statements',
    'Balance Sheets',
    'Strategy Documents',
    'Roadmaps',
    'Operating Plans',
    'Meeting Minutes',
  ],
};

export const dlpRules = [
  {
    title: 'Personal Identification (PII)',
    icon: IconPersonal,
    value: 'rule_1',
  },
  {
    title: 'Financial Data Security',
    icon: IconFinance,
    value: 'rule_2',
  },
  {
    title: 'Password & Credential Protection',
    icon: IconPersonal,
    value: 'rule_3',
  },
  {
    title: 'Source Code Control',
    icon: IconIP,
    value: 'rule_4',
  },
  {
    title: 'Legal & Compliance',
    icon: IconLegal,
    value: 'rule_5',
  },
  {
    title: 'CRM & Customer',
    icon: IconPersonal,
    value: 'rule_6',
  },
  {
    title: 'Business & Operational',
    icon: IconBusiness,
    value: 'rule_7',
  },
];

export function getRuleDetailsByRule(rule: string) {
  return dlpRules.find((ruleDetails) => ruleDetails.value === rule);
}

export function getRuleDetailsByName(rule: string) {
  return dlpRules.find((ruleDetails) => ruleDetails.title === rule);
}

interface EmailPartDetails {
  icon: React.ReactNode;
  title: string;
  description: string;
  comingSoon: boolean;
}

export const emailPartDetails: Record<string, EmailPartDetails> = {
  content: {
    icon: <IconMessage />,
    title: 'Email Content',
    description: 'Scan the Body & Header',
    comingSoon: false,
  },
  attachment: {
    icon: <IconAttachment />,
    title: 'File Attachments',
    description: 'Scan the attachments',
    comingSoon: false,
  },
  images: {
    icon: <IconGallery />,
    title: 'Images',
    description: '',
    comingSoon: true,
  },
  links: {
    icon: <IconLink className="w-3.5 h-3.5" />,
    title: 'Cloud Storage Links',
    description: '',
    comingSoon: true,
  },
};

export interface CategoryItem {
  category: string;
  dataTypes: CheckedDataType[];
  checked: boolean;
}

export interface CheckedDataType extends DataType {
  checked: boolean;
}

export interface BlockRule {
  sender: {
    value: string;
    type: string;
  };
  recipient: {
    value: string;
    type: string;
  };
  scope: 'internal' | 'external';
}

export interface ExceptionDetails {
  name: string;
  sender: {
    value: string;
    type: string;
    error?: string;
  };
  recipients: {
    value: string;
    type: string;
    error?: string;
  }[];
}

export const defaultException: ExceptionDetails = {
  name: '',
  sender: {
    value: '',
    type: '',
    error: '',
  },
  recipients: [
    {
      value: '',
      type: '',
      error: '',
    },
  ],
};

export interface BlockRuleData {
  sender: {
    value: string;
    type: string;
  };
  recipient: {
    value: string;
    type: string;
  };
}

export function getSenderRecipientPairs(
  details: ExceptionDetails
): Array<{ sender: string; recipient: string }> {
  const { sender, recipients } = details;

  // If there are no recipients, return an empty array
  if (!recipients || recipients.length === 0) {
    return [];
  }

  // Map each recipient to a sender-recipient pair
  return recipients.map((recipient) => ({
    sender: sender.value,
    recipient: recipient.value,
  }));
}

export function getAllSenderRecipientPairs(exceptions: ExceptionDetails[]) {
  const listOfExceptions: { sender: string; recipient: string }[] = [];

  exceptions.forEach((e) => {
    const pairs = getSenderRecipientPairs(e);
    listOfExceptions.push(...pairs);
  });

  return listOfExceptions;
}

export interface Action {
  name: string;
  value: string;
  enabled: boolean;
  submitted?: boolean;
}

export const getActiveActions = (actions: Record<string, Action>) => {
  return Object.values(actions).filter((c) => c.enabled) || [];
};

export function hasAnyCheckedDataType(category: CategoryItem): boolean {
  return category.dataTypes.some((dataType) => dataType.checked);
}

export function getCheckedDataTypes(categories: CategoryItem[]): CheckedDataType[] {
  return categories
    .filter((category) => category.checked)
    .flatMap((category) => category.dataTypes.filter((dataType) => dataType.checked));
}

export interface ScanItem {
  name: string;
  checked: boolean;
}

export function transformCategoryToItem(
  category: Category,
  initialCheckedState = false
): CategoryItem {
  // Convert each DataType to CheckedDataType by adding the checked property
  const checkedDataTypes: CheckedDataType[] = category?.dataTypes.map((dataType) => ({
    ...dataType,
    checked: initialCheckedState,
  }));

  // Create and return the CategoryItem
  return {
    category: category.category,
    dataTypes: checkedDataTypes,
    checked: initialCheckedState,
  };
}

export const policyActionDetails: Record<string, { name: string; value: string }> = {
  log: {
    name: 'Log Event',
    value: 'log',
  },
  alert: {
    name: 'Alert Admin',
    value: 'alert',
  },
};

export const getBgColor = (status: PolicyStatus) => {
  if (status === 'active') return 'bg-soft-green';
  if (status === 'inactive') return 'bg-soft-red';
  return 'bg-soft-yellow';
};

export const validateExceptions = (exceptions: ExceptionDetails[]) => {
  let valid = true;

  const validAudience = ['all_internal', 'all_external', 'email', 'domain'];

  exceptions.forEach((v) => {
    // eslint-disable-next-line consistent-return

    if (!validAudience.includes(v?.sender?.type || '')) {
      valid = false;
    }

    const invalidRecipientCount =
      v.recipients.filter((r) => !validAudience.includes(r.type))?.length || 0;
    if (invalidRecipientCount > 0) valid = false;
  });

  return valid;
};
