import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DLPSearchResult,
  CreateDLPReq,
  ListDLPFilters,
  DLPRule,
  DLPIncidentList,
  DLPIncidentFilters,
  PolicyAudience,
  PolicyDetails,
  UpdatePolicyActionReq,
  DLPIncidentDetails,
} from 'models/dlp';
import { DLP_URL, GET_VIOLATION_URL, UPDATE_DLP_URL } from 'constants/apiUrls';
import { PolicyStatus } from 'enums/dlp';

import { baseQuery } from './baseQuery';

export const dlpApi = createApi({
  reducerPath: 'dlpApi',
  baseQuery: baseQuery,
  tagTypes: ['DLP', 'DLP_INCIDENTS'],
  endpoints: (builder) => ({
    getDLPPolicy: builder.query<DLPSearchResult, { orgId: string } & ListDLPFilters>({
      query: ({ limit, offset, query, status, orgId }) => {
        return {
          url: DLP_URL(orgId),
          params: {
            limit: limit,
            offset: offset,
            search: query,
            status: status,
          },
        };
      },
      providesTags: ['DLP'],
    }),
    getPolicy: builder.query<PolicyDetails, { orgId: string; policyId: string }>({
      query: ({ orgId, policyId }) => {
        return `${DLP_URL(orgId)}/${policyId}`;
      },
      providesTags: (policy) => [{ type: 'DLP', id: policy?.displayId }],
    }),
    createDLPRule: builder.mutation<DLPRule, CreateDLPReq & { orgId: string }>({
      query: ({ name, subcategories, orgId, emailPart }) => {
        return {
          url: DLP_URL(orgId),
          method: 'POST',
          body: {
            name,
            subcategories,
            emailPart,
          },
        };
      },
      invalidatesTags: ['DLP'],
    }),
    updateDLPRule: builder.mutation<DLPRule, CreateDLPReq & { orgId: string; policyId: string }>({
      query: ({ name, subcategories, orgId, emailPart, policyId }) => {
        return {
          url: `${DLP_URL(orgId)}/${policyId}`,
          method: 'PATCH',
          body: {
            name,
            subcategories,
            emailPart,
          },
        };
      },
      invalidatesTags: ['DLP'],
    }),
    deleteDLPRule: builder.mutation<void, { orgId: string; ruleId: string }>({
      query: ({ orgId, ruleId }) => {
        return {
          url: `${DLP_URL(orgId)}/${ruleId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DLP'],
    }),
    listDLPIncident: builder.query<DLPIncidentList, DLPIncidentFilters & { orgId: string }>({
      query: ({ limit, offset, orgId, severity }) => {
        return {
          url: `${DLP_URL(orgId)}/violations`,
          params: {
            limit,
            offset,
            severity,
          },
        };
      },
      providesTags: ['DLP_INCIDENTS'],
    }),
    updatePolicyAudience: builder.mutation<
      void,
      PolicyAudience & { policyId: string; orgId: string }
    >({
      query: ({ block, exceptions, scope, orgId, policyId }) => {
        return {
          url: `${UPDATE_DLP_URL(orgId, policyId)}/audience`,
          body: {
            block,
            exceptions,
            scope,
          },
          method: 'PATCH',
        };
      },
      invalidatesTags: ['DLP'],
    }),
    updatePolicyActions: builder.mutation<
      void,
      UpdatePolicyActionReq & { orgId: string; policyId: string }
    >({
      query: ({ orgId, policyId, actions, severity }) => {
        return {
          method: 'PATCH',
          body: {
            actions,
            severity,
          },
          url: `${UPDATE_DLP_URL(orgId, policyId)}/actions`,
        };
      },
      invalidatesTags: ['DLP'],
    }),
    updatePolicyStatus: builder.mutation<
      void,
      { status: PolicyStatus; orgId: string; policyId: string }
    >({
      query: ({ orgId, policyId, status }) => {
        return {
          method: 'PATCH',
          url: `${UPDATE_DLP_URL(orgId, policyId)}/status`,
          body: {
            status,
          },
        };
      },
      invalidatesTags: ['DLP'],
    }),
    deletePolicy: builder.mutation<void, { orgId: string; policyId: string }>({
      query: ({ orgId, policyId }) => {
        return {
          method: 'DELETE',
          url: UPDATE_DLP_URL(orgId, policyId),
        };
      },
      invalidatesTags: ['DLP'],
    }),
    getViolation: builder.query<DLPIncidentDetails, { orgId: string; violationId: string }>({
      query: ({ orgId, violationId }) => {
        return {
          method: 'GET',
          url: GET_VIOLATION_URL(orgId, violationId),
        };
      },
      providesTags: ['DLP_INCIDENTS'],
    }),
  }),
});

export const {
  useGetDLPPolicyQuery,
  useCreateDLPRuleMutation,
  useDeleteDLPRuleMutation,
  useUpdateDLPRuleMutation,
  useListDLPIncidentQuery,
  useLazyGetPolicyQuery,
  useUpdatePolicyAudienceMutation,
  useUpdatePolicyActionsMutation,
  useDeletePolicyMutation,
  useUpdatePolicyStatusMutation,
  useGetPolicyQuery,
  useGetViolationQuery,
} = dlpApi;
