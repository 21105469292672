import { BEC, IMPERSONATION, INSIDER_THREAT, MALWARE } from 'constants/tabName';
import { ThreatCategory } from 'enums/threatCategory';

export const getThreatCategory = (category: string): ThreatCategory => {
  switch (category) {
    case BEC:
      return 'bec';
    case MALWARE:
      return 'malware';
    case INSIDER_THREAT:
      return 'insider_threat';
    case IMPERSONATION:
      return 'impersonation';
    default:
      return '';
  }
};

export const getLegendColor = (category: string): string => {
  const categoryToColor: Record<string, string> = {
    vip_impersonation: '#F7C6FF',
    vendor_impersonation: '#D173E0',
    regulator_impersonation: '#AC38BF',
    malware: '#FFB098',
    bec: '#85D8FF',
    financial_fraud: '#198ABF',
    attorney_fraud: '#62C6F5',
    invoice_fraud: '#BDEAFF',
    insider_threat: '#FFE03B',
  };

  return categoryToColor[category] || '#FFB6C1';
};

export const strokeColorMap: Record<string, string> = {
  financial_fraud: '#E74C3C',
  attorney_fraud: '#2ECC71',
  invoice_fraud: '#3498DB',

  malware: '#F39C12',
  invoice_anomaly: '#9B59B6',

  insider_threat: '#E91E63',

  RVNCLW_RUL_00013: '#34495E',
  RVNCLW_RUL_00008: '#7F8C8D',
  RVNCLW_RUL_00004: '#16A085',
  RVNCLW_RUL_00019: '#F1C40F',
  RVNCLW_RUL_00005: '#1ABC9C',
  RVNCLW_RUL_00009: '#C0392B',
  RVNCLW_RUL_00015: '#8E44AD',
  RVNCLW_RUL_00012: '#2980B9',
  RVNCLW_RUL_00010: '#D35400',
  RVNCLW_RUL_00014: '#27AE60',
  RVNCLW_RUL_00018: '#E67E22',
  RVNCLW_RUL_00001: '#2C3E50',

  vip_impersonation: '#D98880',
  vendor_impersonation: '#73C6B6',
  regulator_impersonation: '#BB8FCE',
};

// Example usage in a graph
export const getStrokeColor = (item: string): string => {
  return strokeColorMap[item] || '#CCCCCC'; // Default to gray if item not found
};
