import { SidePanel } from 'components/Sidepanel';
import { ExternalUser } from 'models/externalUsers';
import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import GridTile from 'components/Tile/GridTile';
import { useGetExternalUserOverviewQuery } from 'service/externalUsers';
import { useOrgId } from 'hooks/useOrgId';
import Loader from 'components/Loader';
import { formatEmailName } from 'utils/email';

import { IncidentsList } from './PreviousIncidents';

interface ExternalUserOverviewProps {
  open: boolean;
  onClose: () => void;
  user: ExternalUser;
}

export function ExternalUserOverview({
  open,
  onClose,
  user,
}: ExternalUserOverviewProps): JSX.Element {
  const [OrgId] = useOrgId();

  const { data, isLoading } = useGetExternalUserOverviewQuery(
    {
      orgId: OrgId,
      userId: user.id,
    },
    {
      skip: !open,
    }
  );

  return (
    <SidePanel open={open} onClose={onClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="p-10">
            <div className="flex items-center gap-2">
              <Avatar
                initials={getInitials(user?.name !== '' ? user?.name : user.email, 2)}
                bgColor="warning-yellow"
                textColor="white"
                customCssClass="w-8 h-8 text-xs"
              />
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <span className="text-base">
                    {user?.name ? user.name : formatEmailName(user.email)}
                  </span>
                </div>
                <span className="text-xs text-light-grey">{user.email}</span>
              </div>
            </div>
          </div>
          <hr className="mb-4" />
          <div className="px-10">
            <GridTile
              details={[
                {
                  title: 'Email ID',
                  value: data?.email || '-',
                },
                {
                  title: 'Domain',
                  value: data?.domain || '-',
                },
                {
                  title: 'Region',
                  value: '-',
                },
                {
                  title: 'Domain Reputation',
                  value: '-',
                },
              ]}
            />
            <hr className="my-6" />
            <IncidentsList
              title="Mail Incidents"
              incidents={data?.mailIncidents || []}
              showHighlight={false}
            />
          </div>
        </>
      )}
    </SidePanel>
  );
}
