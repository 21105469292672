import { useNavigate } from 'react-router-dom';

import { NoDataFallback } from 'components/Fallback/NoDataFallback';
import Loader from 'components/Loader';

import { DLPIncident, DLPIncidentFilters } from 'models/dlp';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { DLPIncidentRow } from './DLPIncident';

interface DLPIncidentTableProps {
  hits: DLPIncident[];
  limit: number;
  offset: number;
  total: number;
  loading: boolean;
  setFilters: React.Dispatch<React.SetStateAction<DLPIncidentFilters>>;
}

export default function DLPIncidentTable({
  hits,
  limit,
  offset,
  total,
  loading,
  setFilters,
}: DLPIncidentTableProps) {
  const columns = [
    {
      label: 'Detection Date',
      width: 'w-1/9',
    },
    {
      label: 'Incident ID',
      width: 'w-1/9',
    },
    {
      label: 'Policy Triggered',
      width: 'w-1/9',
    },
    {
      label: 'Policy Type',
      width: 'w-1/9',
    },
    {
      label: 'Data Detected',
      width: 'w-1/9',
    },
    {
      label: 'Sender - Recipient',
      width: 'w-1/9',
    },
    {
      label: 'Severity',
      width: 'w-1/9',
    },
    {
      label: 'Actions Taken',
      width: 'w-1/9',
    },
    {
      label: 'Status',
      width: 'w-1/9',
    },
  ];

  const navigate = useNavigate();
  const getFallback = () => {
    if (loading) {
      return (
        <td colSpan={columns.length}>
          <div className="w-full flex items-center justify-center py-6">
            <Loader />
          </div>
        </td>
      );
    }
    if (!hits?.length) {
      return (
        <td colSpan={columns.length}>
          <NoDataFallback
            title="No violations found"
            description="No violations found. Visit the manage policies page to review or configure settings."
            actionButtonText="Goto manage policies"
            onAdd={() => navigate('/dlp/list')}
          />
        </td>
      );
    }

    return null;
  };

  return (
    <>
      <table className="w-full mt-8">
        <colgroup>
          {columns.map((header) => (
            <col key={header.label} className={header.width} />
          ))}
        </colgroup>
        <thead className="text-sm text-light-grey w-full border-y-light border-border-primary">
          <tr>
            {columns.map((column, index) => (
              <th
                key={column.label}
                scope="col"
                className={`${index === 0 ? 'px-6' : 'px-3'} py-6 font-normal text-xs text-start text-light-grey bg-soft-gray`}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full">
          {loading || !hits?.length
            ? getFallback()
            : hits?.map((dlp) => <DLPIncidentRow key={dlp.messageId} data={dlp} />)}
        </tbody>
      </table>
      {hits?.length && !loading ? (
        <div className="flex justify-between px-6 py-4 items-center">
          <span className="text-xs font-light">
            Showing {hits && hits?.length > 0 ? offset + 1 : '0'} -{' '}
            {hits && hits?.length > 0 ? offset + (hits?.length || 0) : '0'} of {total} events
          </span>
          <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
            <IconArrowDown
              className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${hits && offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (hits) {
                  if (offset > 0) {
                    setFilters((prevFilter) => ({ ...prevFilter, offset: offset - limit }));
                  }
                }
              }}
            />
            <IconArrowDown
              className={`w-6 h-6 -rotate-90 ${hits && offset + limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
              onClick={() => {
                if (hits && offset + limit < total) {
                  setFilters((prevFilters) => ({ ...prevFilters, offset: offset + limit }));
                }
              }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
