export const ALL_EVENTS_URL = (orgId: string) => `/v0/api/organizations/${orgId}/events/flagged`;

export const REMEDIATED_EVENTS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/events/remediated`;

export const QUARANTINE_EMAIL_API_URL = (orgId: string, messageId: string) =>
  `/v0/api/organizations/${orgId}/messages/${messageId}/move`;

export const STATISTICS_URL = (orgId: string) => `/v0/api/organizations/${orgId}/metrics`;

export const TOP_DETECTIONS_URL = (orgId: string) =>
  `/v0/api/organizations/${orgId}/vectors/inferences`;

export const VECTORS_URL = (orgId: string) => `/v0/api/organizations/${orgId}/vectors`;

export const EMAIL_VECTOR_FREQUENCY_URL = (orgId: string) =>
  `/v0/api/organizations/${orgId}/vectors/frequency`;

export const USERS_URL = (orgId: string) => `/v0/api/organizations/${orgId}/users`;

export const LOGOUT_USER_URL = (orgId: string, userId: string) =>
  `/v0/api/organizations/${orgId}/users/${userId}/password/force-change`;

export const PROFILE_OVERVIEW_URL = (orgId: string, userId: string) =>
  `/v0/api/organizations/${orgId}/users/${userId}/overview`;

export const GET_USER_PROFILE = (orgId: string) => `/v0/api/organizations/${orgId}/profile`;

export const ADMINS_URL = (orgId: string) => `/v0/api/organizations/${orgId}/admins`;

export const THREATS_INFERENCES_URL = (orgId: string) =>
  `/v0/api/organizations/${orgId}/threats/inferences`;

export const THREAT_FREQUENCY_URL = (orgId: string) =>
  `/v0/api/organizations/${orgId}/threats/frequency`;

export const ONBOARDING_STATUS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/onboarding/status`;

export const ONBOARD_ORGANIZATION = (orgId: string) => `/v0/api/organizations/${orgId}/onboard`;

export const VALIDATE_ORGANIZATION_CODE = (code: string) =>
  `/v0/api/auth/org-code/${code}/validate`;

export const GET_SENDERS = (orgId: string) => `/v0/api/organizations/${orgId}/senders`;

export const GET_VENDORS = (orgId: string) => `/v0/api/organizations/${orgId}/domains/vendors`;

export const GET_REGULATORS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/domains/regulators`;

export const UPDATE_DOMAIN = (orgId: string, domainId: string) =>
  `/v0/api/organizations/${orgId}/domains/${domainId}`;

export const DOMAIN_OVERVIEW = (orgId: string, domainId: string) =>
  `/v0/api/organizations/${orgId}/domains/${domainId}/overview`;

export const ADD_DOMAIN = (orgId: string) => `/v0/api/organizations/${orgId}/domains`;

export const SUGGESTED_VENDOR = (orgId: string) =>
  `/v0/api/organizations/${orgId}/domains/vendors/suggestion`;

export const GET_MALWARE_DETECTIONS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/threats/malware/detections`;

export const VERIFY_GOOGLE_DELEGATED_PERMISSIONS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/google/admin/consent`;

export const GET_THREATS = (orgId: string) => `/v0/api/organizations/${orgId}/threats`;

export const GET_THREAT_CATEGORY_STATS = (orgId: string) =>
  `/v0/api/organizations/${orgId}/threats/stats`;

export const GET_ORGANIZATION = (orgId: string) => `/v0/api/organizations/${orgId}`;

export const BLACKLIST_URL = (orgId: string) => `/v0/api/organizations/${orgId}/blacklist`;

export const DELETE_BLACKLIST_URL = (orgId: string, listId: string) =>
  `/v0/api/organizations/${orgId}/blacklist/${listId}`;

export const ALLOWEDLIST_URL = (orgId: string) => `/v0/api/organizations/${orgId}/allowedlist`;

export const DELETE_ALLOWEDLIST_URL = (orgId: string, listId: string) =>
  `/v0/api/organizations/${orgId}/allowedlist/${listId}`;

export const GET_CONFIG = (orgId: string) => `/v0/api/organizations/${orgId}/config`;

export const UPDATE_CONFIG = (orgId: string) => `/v0/api/organizations/${orgId}/remediation-mode`;

export const VALIDATE_LIST_URL = (orgId: string, rule: string) =>
  `/v0/api/organizations/${orgId}/${rule}/validate`;

export const REMEDIATED_EVENT = (orgId: string, messageId: string) =>
  `/v0/api/organizations/${orgId}/events/remediated/${messageId}`;

export const BLACKLISTED_URL = (orgId: string) =>
  `/v0/api/organizations/${orgId}/events/blacklisted`;

export const REMEDIATE_URL = (orgId: string, messageId: string) =>
  `/v0/api/organizations/${orgId}/events/remediate/${messageId}`;

export const DLP_URL = (orgId: string) => `/v0/api/organizations/${orgId}/dlp`;

export const UPDATE_DLP_URL = (orgId: string, policyId: string) =>
  `/v0/api/organizations/${orgId}/dlp/${policyId}`;

export const GET_VIOLATION_URL = (orgId: string, violationId: string) =>
  `/v0/api/organizations/${orgId}/dlp/violations/${violationId}`;

export const GET_CATEGORIES = '/v0/api/categories';
