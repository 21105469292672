import { ReactComponent as IconWarning } from 'assets/icons/icon-warning-alert.svg';

interface WarningToastProps {
  message: string;
  icon?: React.ReactNode;
}

export function WarningToast({ message, icon }: WarningToastProps) {
  return (
    <div className="flex items-center p-2 gap-2 bg-white rounded-lg border-light border-border-primary shadow-sm">
      {icon || <IconWarning className="fill-yellow-400" />}
      <p className="text-sm ">{message}</p>
    </div>
  );
}
