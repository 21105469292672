import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { dateUtils } from 'utils/time';

import { useOrgId } from 'hooks/useOrgId';
import { useEffect, useState } from 'react';
import { useGetThreatFrequencyQuery } from 'service/threatFrequency';
import { FrequencyGraphToolTip } from 'components/FrequencyGraph/FrequencyGraphToolTip';
import { ThreatFrequencyFilters } from 'models/threatInferences';
import { TransformToTimeseries } from 'components/FrequencyGraph/transformations';
import { TabCategory } from 'enums/threatCategory';
import Loader from 'components/Loader';
import { getStrokeColor } from './data';

interface ThreatFrequencyProps {
  tab: TabCategory;
  from: string;
  to: string;
}

interface GraphLabel {
  label: string;
  value: string;
  stroke: string;
}

export function Frequency({ tab, from, to }: ThreatFrequencyProps) {
  const [OrgId] = useOrgId();

  const [filters, setFilters] = useState<ThreatFrequencyFilters>({
    orgId: OrgId,
    from: from,
    to: to,
    frequency: 'daily',
    category: tab,
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      from,
      to,
      category: tab,
    }));
  }, [from, to, tab]);

  const { data, isLoading } = useGetThreatFrequencyQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  // Generate all dates between from and to
  const generateDateRange = (fromDate: string, toDate: string): string[] => {
    const dates: string[] = [];
    const currentDate = new Date(fromDate);
    const endDate = new Date(toDate);

    // Ensure we're working with the start of the day
    currentDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    // Add one day to include the end date
    endDate.setDate(endDate.getDate() + 1);

    while (currentDate < endDate) {
      dates.push(currentDate.toISOString().split('T')[0]); // Use only the date part
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const transformData = () => {
    if (!data?.threats || data.threats.length === 0) return [];

    // Generate all dates in the range
    const dateRange = generateDateRange(filters.from || '', filters.to || '');

    // Create a map of threat codes to their transformed data
    const threatDataMap = new Map();
    data.threats.forEach((threat) => {
      // Use the TransformToTimeseries function to ensure all dates in range are included
      const transformedData = TransformToTimeseries(
        filters.from || '',
        filters.to || '',
        threat.frequency
      );

      threatDataMap.set(threat.code, {
        data: transformedData,
        label: threat.label,
        stroke: getStrokeColor(threat.code),
      });
    });

    // Combine all threat data by date
    return dateRange.map((date) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataPoint: any = { date };
      threatDataMap.forEach((threatInfo, code) => {
        const dateData = threatInfo.data.find((d: { date: string }) => d.date === date);
        dataPoint[code] = dateData?.count || 0;
      });
      return dataPoint;
    });
  };

  const getGraphLabels = (): GraphLabel[] => {
    if (!data?.threats) return [];

    return data.threats.map((threat) => ({
      label: threat.label,
      value: threat.code,
      stroke: getStrokeColor(threat.code),
    }));
  };

  return (
    <ResponsiveContainer width="100%" height={240} className="mt-6">
      {isLoading ? (
        <Loader />
      ) : (
        <LineChart
          accessibilityLayer
          margin={{
            left: 20,
            right: 20,
          }}
          data={transformData()}
        >
          <XAxis
            dataKey="date"
            className="text-xs"
            color="#E1E5E8"
            axisLine={false}
            tickLine={false}
            tick={{
              fill: '#9FAFB5',
              fontSize: '10px',
            }}
            tickMargin={12}
            tickFormatter={(value) => {
              const dateText = value as string;
              return dateUtils.format(dateText, 'MMM DD').toString();
            }}
            scale="point"
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            orientation="right"
            tick={{
              fill: '#9FAFB5',
              fontSize: '10px',
            }}
            tickMargin={16}
            tickCount={3}
            domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.2)]}
          />
          <CartesianGrid
            vertical={false}
            color="#E1E5E8"
            strokeWidth={0.5}
            strokeOpacity={0.5}
            horizontalCoordinatesGenerator={({ height }) => {
              const count = 10;
              return Array(count)
                .fill(0)
                .map((_, index) => height * (index / count));
            }}
          />
          <Tooltip content={<FrequencyGraphToolTip labels={getGraphLabels()} />} cursor={false} />
          {getGraphLabels().map((label) => (
            <Line
              key={label.value}
              dataKey={label.value}
              name={label.label}
              stroke={label.stroke}
              dot={false}
              strokeWidth={1}
            />
          ))}
        </LineChart>
      )}
    </ResponsiveContainer>
  );
}
