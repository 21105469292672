import { getActionDisplayName, RemediationResult } from 'models/events';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconFailure } from 'assets/icons/failure-icon.svg';
import { AnalysisBadge } from './AnalysisBadge';

interface EventActionStatusProps {
  // actionResult is a list of results for each action that was performed
  // while remediating a flagged message, using this list we can display the
  // status of different actions
  actionResult: RemediationResult[];
}

const getActionStatusIcon = (success: boolean) => {
  return success ? (
    <IconCheck className="w-6 h-6 stroke-success-green" />
  ) : (
    <div className="w-6 h-6 flex items-center justify-center">
      <IconFailure />
    </div>
  );
};

export function EventActionStatus({ actionResult }: EventActionStatusProps) {
  return (
    <div className="flex flex-col gap-2">
      {actionResult.map((result, index) => (
        <div
          className={`w-full gap-4 flex items-center ${index !== actionResult.length - 1 ? 'border-b-light border-border-primary' : ''} px-5 py-4`}
        >
          {getActionStatusIcon(result.success)}
          <div className="flex flex-col gap-2">
            <span className={`text-sm ${!result.success ? 'text-error-text' : ''}`}>
              {' '}
              {getActionDisplayName(result.action, result.success)}{' '}
            </span>
            {result.error ? <AnalysisBadge text={result.error || ''} type="detection" /> : null}
          </div>
        </div>
      ))}
    </div>
  );
}
