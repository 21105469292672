import { ReactComponent as SafeAlertIcon } from 'assets/icons/safe-alert.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

import { Divider } from 'components/Divider';

interface MarkAsSafeAlertProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function MarkAsSafeAlert({ onConfirm, onCancel }: MarkAsSafeAlertProps) {
  return (
    <div className="w-1/3 rounded-3xl border-light border-border-primary relative flex flex-col bg-white">
      <button
        type="button"
        className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
        onClick={() => onCancel()}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <IconClose className="w-2.5 h-2.5 fill-light-grey" />
      </button>
      <div className="px-6 py-7 flex flex-col items-center justify-center gap-5">
        <SafeAlertIcon />
        <div className="flex flex-col gap-4">
          <span className="text-xl text-center">
            Are you sure you want to mark this flagged mail as safe ?
          </span>
          <span className="font-normal text-center">
            We&apos;ll notify your Security Admin to review and mark this email as safe, then
            analyze why it was flagged. Thank you for your feedback!
          </span>
        </div>
      </div>
      <Divider />
      <div className="px-6 py-7 flex flex-col gap-5 w-full">
        <button
          type="button"
          className="p-4 text-white bg-black rounded-lg w-full"
          onClick={() => onConfirm()}
        >
          Yes mark it as safe
        </button>
        <button
          type="button"
          className="p-4 border-light border-border-primary rounded-lg w-full"
          onClick={() => onCancel()}
        >
          No, Cancel
        </button>
      </div>
    </div>
  );
}
