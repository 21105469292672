import { useState } from 'react';

import { ReactComponent as IconWarning } from 'assets/icons/icon-error-alert.svg';

import { Divider } from 'components/Divider';
import { useDLPForm } from 'contexts/DLPFormContext';
import { toTitleCase } from 'utils/name';
import { useUpdatePolicyActionsMutation } from 'service/dlp';
import { UpdatePolicyActionReq } from 'models/dlp';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import Loader from 'components/Loader';
import { WarningToast } from 'components/Toasts/WarningToast';

import { getActiveActions } from './data';

export function ActionPreviewPanel() {
  const { showToast } = useToast();

  const { formDetails, onBack, onNext, activeStep, policyId, mode, setActiveStep, formSteps } =
    useDLPForm();

  const [formLoading, setFormLoading] = useState(false);

  const [orgId] = useOrgId();

  const validateActions = (): { valid: boolean; error: string } => {
    const activeBlockActions = getActiveActions(formDetails.actionConfiguration.block);

    // none of the actions are enabled, atleast there should be one block action enabled
    if (!activeBlockActions.length)
      return {
        valid: false,
        error: 'Please select atleast one action to be performed on block rules',
      };

    // if there are any exceptions, check if there;s any action enabled for them
    // if there's no action enabled then return false
    if (formDetails.exceptions && formDetails?.exceptions?.length) {
      const activeAllowActions = getActiveActions(formDetails.actionConfiguration.exception);

      if (!activeAllowActions.length)
        return {
          valid: false,
          error: 'Please select atleast one action to be performed on exception rules',
        };
    }

    if (!formDetails.severity)
      return { valid: false, error: 'Please select severity of the violations to be flagged' };
    return { valid: true, error: '' };
  };

  const [updateActions] = useUpdatePolicyActionsMutation();

  const handleNext = async () => {
    const { valid, error } = validateActions();
    if (!valid) {
      showToast({
        component: (
          <WarningToast message={error} icon={<IconWarning className="fill-error-text" />} />
        ),
      });
      return;
    }

    setFormLoading(true);
    try {
      const request: UpdatePolicyActionReq = {
        actions: [
          {
            rule: 'block',
            actions:
              getActiveActions(formDetails.actionConfiguration.block).map((a) => a.value) || [],
          },
          {
            rule: 'allow',
            actions: getActiveActions(formDetails.actionConfiguration.exception).map(
              (e) => e.value
            ),
          },
        ],
        severity: formDetails.severity,
      };

      await updateActions({ ...request, policyId, orgId }).unwrap();
      if (mode === 'edit') {
        setActiveStep(formSteps.length - 1);
      } else {
        onNext();
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, Please try again!" />,
      });
    } finally {
      setFormLoading(false);
    }
  };

  const handleBack = () => {
    if (mode === 'edit') {
      setActiveStep(formSteps.length - 1);
    } else {
      onBack();
    }
  };

  return (
    <div className="px-7 py-12 flex flex-col gap-4 justify-between h-full">
      <div className="flex-1 h-full overflow-y-auto">
        <span className="text-sm text-light-grey">Policy Preview</span>
        <Divider />
        {getActiveActions(formDetails.actionConfiguration.block).length ? (
          <>
            <div className="flex flex-col gap-6">
              <span className="text-sm text-light-grey">Block Actions</span>
              <div className="flex items-center flex-wrap gap-4">
                {getActiveActions(formDetails.actionConfiguration.block).map((v) => (
                  <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-red text-sm">
                    {v.name}
                  </div>
                ))}
              </div>
            </div>
            <Divider />
          </>
        ) : null}
        {getActiveActions(formDetails.actionConfiguration.exception).length ? (
          <>
            <div className="flex flex-col gap-6">
              <span className="text-sm text-light-grey">Exception Actions</span>
              <div className="flex items-center flex-wrap gap-4">
                {getActiveActions(formDetails.actionConfiguration.exception).map((ea) => (
                  <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-green text-sm">
                    {ea.name}
                  </div>
                ))}
              </div>
            </div>
            <Divider />
          </>
        ) : null}
        {formDetails.severity !== '' && (
          <div className="flex flex-col gap-6">
            <span className="text-sm text-light-grey">Policy Severity</span>
            <div className="border-light border-border-primary bg-soft-gray px-2.5 py-2 rounded-lg w-fit text-sm">
              {toTitleCase(formDetails.severity)}
            </div>
          </div>
        )}
      </div>
      <div className="flex gap-2 items-center">
        <button
          type="button"
          className={`flex-1 rounded-lg border-light border-border-primary py-4 shadow-sm ${activeStep === 0 ? 'cursor-not-allowed opacity-50' : ''} text-sm`}
          onClick={() => handleBack()}
        >
          Back
        </button>
        <button
          type="button"
          className={`rounded-lg flex-1 py-4 text-white ${!validateActions().valid ? 'bg-black/50 cursor-not-allowed' : 'bg-black '} text-sm`}
          onClick={() => handleNext()}
        >
          {formLoading ? (
            <Loader color="white" />
          ) : (
            <span>{mode === 'edit' ? 'Save Changes' : 'Next'}</span>
          )}
        </button>
      </div>
    </div>
  );
}
