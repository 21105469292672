import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Loader from 'components/Loader';

interface AlertProps {
  open: boolean;
  icon?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  content?: React.ReactNode; // any styled details to show in the alert
  message: string;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
}

export function Alert({
  open,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  message,
  onCancel,
  onConfirm,
  content,
  icon,
  loading,
}: AlertProps) {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 z-50" />
        <AlertDialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg max-w-96 z-50">
          <div className="p-8 flex flex-col items-center gap-4">
            {icon}
            <AlertDialog.Title className="text-base font-medium text-center w-4/5 break-words">
              {message}
            </AlertDialog.Title>
            {content}
          </div>
          <hr />
          <div className="p-8 flex items-center justify-center gap-4">
            <button
              type="button"
              className="bg-white border-light-grey border-light text-light-grey rounded-full p-2 flex-1 text-xs"
              onClick={() => onCancel()}
            >
              {cancelText}
            </button>
            <button
              type="button"
              className="bg-black text-white rounded-full p-2 flex-1 text-xs"
              onClick={() => onConfirm()}
            >
              {loading ? <Loader color="white" customCss="w-4 h-4 border-2" /> : confirmText}
            </button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
