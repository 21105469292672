import { CategoryItem, ScanItem, Action, ExceptionDetails } from 'components/DLP/data';
import { DataType } from 'models/categories';
import { ActionRule, AudienceRule } from 'models/dlp';
import { getAudienceType } from 'utils/type';

export function updateCategoryItemsCheckedState(
  categoryItems: CategoryItem[] = [],
  availableDataTypes: DataType[] = []
): CategoryItem[] {
  return categoryItems?.map((categoryItem) => ({
    ...categoryItem,
    dataTypes: categoryItem?.dataTypes?.map((dataType) => ({
      ...dataType,
      checked: availableDataTypes?.some((availableType) => availableType.value === dataType.value),
    })),
    // Update category checked state if any of its data types are checked
    checked: categoryItem.dataTypes.some((dataType) =>
      availableDataTypes.some((availableType) => availableType.value === dataType.value)
    ),
  }));
}

export function updateScanItemsCheckedState(
  scanItems: ScanItem[] = [],
  availableScans: string[] = []
): ScanItem[] {
  return scanItems.map((scanItem) => ({
    ...scanItem,
    checked: availableScans.includes(scanItem.name),
  }));
}

export function getUpdatedActionConfig(
  rules: ActionRule[],
  actionConfig: {
    block: Record<string, Action>;
    exception: Record<string, Action>;
  }
) {
  const updatedConfig = {
    block: { ...actionConfig.block },
    exception: { ...actionConfig.exception },
  };

  rules?.forEach(({ rule, actions }) => {
    // Determine the corresponding section in the configuration
    const configKey = rule === 'block' ? 'block' : 'exception';
    const section = updatedConfig[configKey];

    // Use array map or filter to enable actions
    actions?.forEach((actionName) => {
      if (section?.[actionName]) {
        section[actionName] = {
          ...section[actionName],
          enabled: true,
          submitted: true,
        };
      }
    });
  });

  return updatedConfig;
}

export function transformAudienceToExceptions(rules: AudienceRule[] = []): ExceptionDetails[] {
  const allowRules = rules?.filter((item) => item.rule === 'allow') || [];

  return (
    allowRules.map((rule) => {
      const senderDetail = rule.details.find((d) => d.type === 'sender');
      const recipientDetails = rule.details.filter((d) => d.type === 'recipient');

      const sender = {
        value: senderDetail?.value || '',
        type: getAudienceType(senderDetail?.value || ''),
        error: '',
      };

      const recipients = recipientDetails.map((rd) => ({
        value: rd.value,
        type: getAudienceType(rd.value),
        error: '',
      }));

      return {
        name: 'Exception', // Name is left empty as it's not provided by the API
        sender,
        recipients,
      };
    }) || []
  );
}
