import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import KeycloakService from './keycloak';

const baseUrl = process.env.REACT_APP_BFF_API_URL;

const query = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const token = KeycloakService.getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await query(args, api, extraOptions);
  const status = result.meta?.response?.status;

  if (result.error && (status === 401 || status === 403)) {
    // Try to refresh the token
    try {
      const refreshed = await KeycloakService.updateToken();
      if (refreshed) {
        // Retry the initial query with the new token
        result = await query(args, api, extraOptions);
      } else {
        // Show session expired modal instead of immediate logout
        KeycloakService.handleUnauthorized();
      }
    } catch {
      // Show session expired modal on error
      KeycloakService.handleUnauthorized();
    }
  }

  if (status === 500 || status === 400) {
    if (status === 500 || status === 400) {
      const errMessage = (result.error?.data as string) || 'Something went wrong';
      return {
        error: { status, data: errMessage },
      };
    }
  }

  return result;
};
