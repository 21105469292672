import { useRef, useState, useEffect, KeyboardEvent } from 'react';

import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';

import { AudienceType } from 'models/dlp';
import { getAudienceType } from 'utils/type';
import { getInitials } from 'utils/name';

interface ExceptionInputProps {
  onAdd: ({ value, type }: AudienceType) => void;
  placeholder: string;
  value: string;
  error?: string;
  onClear?: () => void;
  showClear?: boolean;
}

export function ExceptionInput({
  placeholder,
  onAdd,
  value,
  error,
  onClear,
  showClear,
}: ExceptionInputProps) {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLButtonElement>(null);

  const [dataType, setDataType] = useState('');

  useEffect(() => {
    if (value) {
      const audienceType = getAudienceType(value);
      setDataType(audienceType);
    }
  }, [value]);

  // Handle click outside to close results
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAdd = (data: string) => {
    const type = getAudienceType(data);
    setDataType(type);
    if (type === '') {
      onAdd({ type, value: data, error: 'Please enter a valid email / domain' });
    } else {
      onAdd({ type, value: data, error: '' });
    }
  };

  const handleActivate = () => {
    setIsActive(true);
    // Focus the input after a small delay to ensure the render completes
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const getIcon = () => {
    if (dataType === 'domain') {
      return (
        <div className="w-8 h-8 rounded-full bg-soft-purple flex items-center justify-center">
          <IconGlobe className="w-4 h-4" />
        </div>
      );
    }
    return (
      <button
        type="button"
        className="flex text-sm justify-center items-center border-light border-border-primary rounded-full bg-soft-blue text-secondary-text w-8 h-8 p-2"
      >
        {getInitials(value, 1)}
      </button>
    );
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIsActive(false);
    }
  };

  return (
    <button
      ref={containerRef}
      type="button"
      className={`w-full ${isActive ? 'bg-soft-gray' : 'bg-white'} border-light ${error !== '' ? 'border-error-text' : 'border-border-primary'} rounded-lg flex items-center p-4 gap-2 relative`}
      onClick={!isActive ? handleActivate : undefined}
    >
      {isActive || value === '' ? (
        <div className="w-full flex items-center gap-3">
          <div className="w-8 h-8 flex items-center justify-center">
            <IconSearch className="w-6 h-6" />
          </div>
          <input
            className="flex-1 placeholder:text-light-grey outline-none text-sm bg-transparent"
            placeholder={placeholder}
            onChange={(e) => handleAdd(e.target.value)}
            value={value}
            ref={inputRef}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      ) : (
        <div className="w-full flex items-center gap-3">
          {getIcon()}
          <span className="text-sm text-center text-black">{value}</span>
        </div>
      )}
      {onClear && showClear ? (
        <button
          type="button"
          className="w-6 h-6 rounded-full text-xl border-light border-border-primary bg-action-button font-light flex items-center justify-center p-1"
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        >
          <IconClose className="fill-black" />
        </button>
      ) : null}
      {/* Results dropdown can be added here when needed */}
      {/* {isActive && (
        <div
          ref={resultsRef}
          className="bg-white w-full rounded-b-lg border-x-light border-x-border-primary border-b-light border-b-border-primary px-2.5 pt-2.5 pb-5 absolute top-full left-0 mb-4 z-[100]"
        >
          Content of dropdown
        </div>
      )} */}
    </button>
  );
}
