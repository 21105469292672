import { useEffect, useState } from 'react';

import { useDLPForm } from 'contexts/DLPFormContext';
import { ReactComponent as IconFinance } from 'assets/icons/icon-finance.svg';
import { ReactComponent as IconBlock } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconCorrect } from 'assets/icons/icon-correct.svg';

import { useUpdatePolicyStatusMutation } from 'service/dlp';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import Loader from 'components/Loader';
import { Divider } from 'components/Divider';
import { toTitleCase } from 'utils/name';

import { DLPItemBadge } from './DLPItemBadge';
import { emailPartDetails, getActiveActions, getAllSenderRecipientPairs } from './data';

export function PolicyReview() {
  const { showToast } = useToast();

  const [formLoading, setFormLoading] = useState(false);

  const [orgId] = useOrgId();

  const { formDetails, policyId, setFormDetails, setActiveStep } = useDLPForm();

  const [categoryOpenState, setCategoryOpenState] = useState<boolean[]>([]);

  useEffect(() => {
    if (formDetails.categories) {
      const defaultOpenStates = formDetails.categories.filter((cd) => cd.checked).map(() => false);
      setCategoryOpenState(defaultOpenStates);
    }
  }, [formDetails.categories]);

  const handleOpenState = (categoryIndex: number) => {
    const newOpenStates = [...categoryOpenState];
    newOpenStates[categoryIndex] = !newOpenStates[categoryIndex];
    setCategoryOpenState(newOpenStates);
  };

  const [activatePolicy] = useUpdatePolicyStatusMutation();

  const getStatus = () => {
    return formDetails.status === 'active' ? 'inactive' : 'active';
  };

  const handleActivatePolicy = async () => {
    const policyStatus = getStatus();
    setFormLoading(true);
    try {
      await activatePolicy({
        orgId,
        policyId,
        status: policyStatus,
      });

      showToast({
        component: (
          <SuccessToast message={`Successfully updated the policy status to ${policyStatus}`} />
        ),
      });

      setFormDetails((prev) => ({ ...prev, status: policyStatus }));
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again!" />,
      });
    } finally {
      setFormLoading(false);
    }
  };

  const getStatusActionText = () => {
    return formDetails.status !== 'active' ? 'Activate Policy' : 'Deactivate Policy';
  };

  const handleClickEdit = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="px-12 py-8 flex flex-col gap-7 flex-1 overflow-hidden overflow-y-auto">
        <span className="text-xl">Review Policy</span>
        <div className="rounded-2xl bg-white border-light border-border-primary">
          <div className="p-7 flex items-center justify-between border-b-light border-border-primary">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-lg border-light border-border-primary flex items-center justify-center">
                <IconFinance className="w-4 h-4 fill-success-green" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base">{formDetails?.name?.value || 'Untitled'}</span>
                {formDetails.displayId !== '' ? (
                  <span className="text-sm text-light-grey">#{formDetails.displayId}</span>
                ) : null}
              </div>
            </div>
            <div className="p-1 rounded-lg border-light border-border-primary flex items-center gap-2">
              <div className="w-8 h-8 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-red">
                <IconBlock className="w-4 h-4 fill-error-text" />
              </div>
              <span className="text-sm">
                This policy will <span className="text-error-text">block</span> emails with
                sensitive data sent
              </span>
              <button
                type="button"
                className={`px-2 py-1 rounded-lg border-light border-border-primary ${formDetails.isExternal ? 'bg-soft-purple' : 'bg-soft-blue'} flex items-center gap-2`}
              >
                {formDetails.isExternal ? <IconGlobe /> : <IconIndustry />}
                <span className="text-sm">{formDetails.isExternal ? 'External' : 'Internal'}</span>
              </button>
            </div>
          </div>
          <div className="p-7 border-b-light border-border-primary">
            <div className="flex flex-col gap-6">
              <div className="w-full flex items-center justify-between gap-4">
                <span className="text-black text-lg">Protected Data Types</span>
                <button
                  type="button"
                  className="rounded-full bg-black py-1.5 px-3 text-sm text-white"
                  onClick={() => handleClickEdit(0)}
                >
                  Edit Details
                </button>
              </div>
              {formDetails.categories?.length && categoryOpenState?.length
                ? formDetails.categories
                    .filter((cd) => cd.checked)
                    .map((c, idx) => {
                      return (
                        <>
                          <div className="flex flex-col gap-6">
                            <div className="flex items-center gap-4 justify-between">
                              <div className="flex items-center gap-4">
                                <span className="text-sm">{c.category}</span>
                                <button
                                  type="button"
                                  className="bg-border-primary py-1 px-2.5 rounded-full text-sm"
                                >
                                  {c.dataTypes.filter((d) => d.checked).length}/{c.dataTypes.length}
                                </button>
                              </div>
                              <IconArrowDown
                                className={`w-8 h-8 transition-transform duration-400 ${!categoryOpenState[idx] ? '' : 'rotate-180'} stroke-light-grey cursor-pointer`}
                                onClick={() => handleOpenState(idx)}
                              />
                            </div>
                            {categoryOpenState[idx] ? (
                              <div className="flex items-center gap-4 flex-wrap">
                                {c.dataTypes
                                  .filter((d) => d.checked)
                                  .map((d) => (
                                    <DLPItemBadge label={d.label} />
                                  ))}
                              </div>
                            ) : null}
                          </div>
                          {(formDetails?.categories?.filter((cd) => cd.checked)?.length || 0) -
                            1 !==
                          idx ? (
                            <Divider customCss="my-0" />
                          ) : null}
                        </>
                      );
                    })
                : null}
            </div>
            <Divider />
            <div className="flex flex-col gap-6">
              <div className="w-full flex items-center justify-between gap-4">
                <span className="text-light-grey text-sm">Scanning Email Parts</span>
              </div>
              <div className="flex items-center flex-wrap gap-2">
                {formDetails.scan && formDetails.scan.filter((sc) => sc.checked)?.length
                  ? formDetails.scan
                      ?.filter((sc) => sc.checked)
                      .map((s) => <DLPItemBadge label={emailPartDetails[s.name].title || s.name} />)
                  : null}
              </div>
            </div>
          </div>
          <div className="flex-1 p-7 flex flex-col gap-4">
            <div className="w-full flex items-center justify-between gap-4">
              <span className="text-black text-lg">Block & Exception Rules</span>
              <button
                type="button"
                className="rounded-full bg-black py-1.5 px-3 text-sm text-white"
                onClick={() => handleClickEdit(1)}
              >
                Edit Details
              </button>
            </div>
            <Divider customCss="my-2" />
            <div className="flex flex-col gap-4">
              <span className="text-sm text-light-grey">Block Rule</span>
              <div className="flex items-center gap-6">
                <button
                  type="button"
                  className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3 bg-soft-gray"
                >
                  <div className="w-6 h-6 rounded-full bg-soft-blue flex items-center justify-center">
                    <IconIndustry />
                  </div>
                  <span className="text-sm text-light-grey">
                    {formDetails.blockRule?.sender.value}
                  </span>
                </button>
                <div className="w-6 h-6 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-red">
                  <IconBlock className="w-4 h-4 fill-error-text" />
                </div>
                <button
                  type="button"
                  className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3 bg-soft-gray"
                >
                  <div className="w-6 h-6 rounded-full bg-soft-purple flex items-center justify-center">
                    <IconGlobe className="w-4 h-4" />
                  </div>
                  <span className="text-sm text-light-grey">
                    {formDetails.blockRule?.recipient?.value}
                  </span>
                </button>
              </div>
            </div>
            {formDetails?.exceptions && formDetails?.exceptions?.length ? (
              <>
                <Divider />
                <div className="flex flex-col gap-4">
                  <span className="text-light-grey text-sm">Allow Rule (Exceptions)</span>
                  <div className="flex flex-col w-full gap-2">
                    {getAllSenderRecipientPairs(formDetails.exceptions)?.map((e) => (
                      <div className="flex items-center gap-6">
                        <button
                          type="button"
                          className="px-2 py-1 bg-soft-gray border-light border-border-primary rounded-lg text-sm"
                        >
                          {e.sender}
                        </button>
                        <div className="w-6 h-6 rounded-lg border-light border-border-primary flex items-center justify-center bg-soft-green">
                          <IconCorrect className="w-4 h-4 fill-success-green" />
                        </div>
                        <button
                          type="button"
                          className="px-2 py-1 bg-soft-gray border-light border-border-primary rounded-lg text-sm"
                        >
                          {e.recipient}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <Divider customCss="my-2" />
          <div className="p-7 flex flex-col gap-4">
            <div className="w-full flex items-center justify-between gap-4">
              <span className="text-black text-lg">Actions and Configurations</span>
              <button
                type="button"
                className="rounded-full bg-black py-1.5 px-3 text-sm text-white"
                onClick={() => handleClickEdit(2)}
              >
                Edit Details
              </button>
            </div>
            <Divider customCss="my-2" />
            <div className="flex flex-col gap-4">
              <span className="text-sm text-light-grey">Block Actions</span>
              <div className="flex items-center gap-4 flex-wrap">
                <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-gray text-sm flex items-center gap-2">
                  <IconBlock className="w-4 h-4 fill-error-text" />
                  <span className="text-sm">Block Mail</span>
                </div>
                {getActiveActions(formDetails.actionConfiguration.block).map((itemBlock) => (
                  <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-red text-sm">
                    {itemBlock.name}
                  </div>
                ))}
              </div>
            </div>
            {formDetails?.exceptions && formDetails?.exceptions?.length ? (
              <>
                <Divider customCss="my-2" />
                <div className="flex flex-col gap-4">
                  <span className="text-sm text-light-grey">Allow Actions</span>
                  <div className="flex items-center gap-4 flex-wrap">
                    <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-gray text-sm flex items-center gap-2">
                      <IconCorrect className="w-4 h-4" />
                      <span className="text-sm">Allow Mail</span>
                    </div>
                    {getActiveActions(formDetails.actionConfiguration.exception).map(
                      (itemBlock) => (
                        <div className="rounded-md border-light border-border-primary px-2.5 py-2 bg-soft-green text-sm">
                          {itemBlock.name}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ) : null}
            <Divider customCss="my-2" />
            <div className="flex flex-col gap-4">
              <span className="text-sm text-light-grey">Policy Severity</span>
              <button
                type="button"
                className="px-3 py-2 rounded-lg border-light border-border-primary w-fit text-sm bg-soft-gray"
              >
                {toTitleCase(formDetails.severity) || 'No Severity'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-light border-border-primary px-9 py-7 bg-white flex flex-row-reverse items-center justify-between">
        <div className="flex items-center gap-2">
          <button
            type="button"
            className={`py-4 px-8 rounded-lg ${formDetails.status === 'active' ? 'bg-error-text' : 'bg-success-green'} text-white`}
            onClick={() => handleActivatePolicy()}
          >
            {formLoading ? <Loader color="white" /> : getStatusActionText()}
          </button>
        </div>
      </div>
    </div>
  );
}
