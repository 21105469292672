import { createApi } from '@reduxjs/toolkit/query/react';
import {
  EventsResponse,
  GetEventsQueryParams,
  RemediatedEventsFilter,
  RemediatedEventsResults,
  Event,
  GetFlaggedEventStatusParams,
  FlaggedEventStatusMetric,
  RemediationItem,
  BlacklistedEventResults,
  BlacklistFilter,
  BlacklistItem,
  RemediationResponse,
  RemediationRequest,
} from 'models/events';
import {
  ALL_EVENTS_URL,
  BLACKLISTED_URL,
  QUARANTINE_EMAIL_API_URL,
  REMEDIATE_URL,
  REMEDIATED_EVENT,
  REMEDIATED_EVENTS,
  BLACKLIST_URL,
  DELETE_BLACKLIST_URL,
  VALIDATE_LIST_URL,
} from 'constants/apiUrls';
import { ListResults, ListFilter, ListReq, RuleFilter, ValidationDetails } from 'models/rules';
import { getRandomNumber } from 'utils/randomNumber';
import { FolderType } from 'enums/eventStatusTypes';
import { getActionDetails } from 'utils/actionDescription';

import { baseQuery } from './baseQuery';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQuery,
  tagTypes: [
    'EVENTS',
    'EVENT',
    'EVENT_STATS',
    'REMEDIATED_EVENT',
    'BLACKLISTED_EVENT',
    'BLACKLIST',
    'CHECK_BLACKLIST',
  ],
  endpoints: (builder) => ({
    getFlaggedEvents: builder.query<EventsResponse, { orgId: string } & GetEventsQueryParams>({
      query: ({
        orgId,
        offset = 0,
        limit = 10,
        from,
        to,
        sortBy,
        sortOrder = 'asc',
        vector,
        threat,
        status,
      }) => {
        const params = new URLSearchParams();

        params.append('offset', offset.toString());
        params.append('limit', limit.toString());

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (sortBy) params.append('sort_by', sortBy);
        if (sortOrder) params.append('sort_order', sortOrder);
        if (vector) params.append('vector', vector);
        if (threat) params.append('threats', threat);
        if (status) params.append('status', status);

        return `${ALL_EVENTS_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['EVENTS'],
      transformResponse: (response: EventsResponse) => {
        response.hits = response?.hits?.map((hit) => {
          return {
            ...hit,
            severity: getRandomNumber(80, 100),
            status: hit.status,
          };
        });
        return response;
      },
    }),
    remediateMessage: builder.mutation<
      undefined,
      { orgId: string; messageId: string; folder: FolderType }
    >({
      query: ({ orgId, messageId, folder }) => {
        const actionDetails = getActionDetails(folder);
        return {
          url: QUARANTINE_EMAIL_API_URL(orgId, messageId),
          method: 'POST',
          body: {
            action: folder,
            actionDetails,
          },
        };
      },
      invalidatesTags: ['EVENTS'],
    }),
    getFlaggedEventStats: builder.query<
      Array<FlaggedEventStatusMetric>,
      { orgId: string } & GetFlaggedEventStatusParams
    >({
      query: ({ orgId, from, to, threats }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (threats) params.append('threats', threats);

        return `${ALL_EVENTS_URL(orgId)}/stats?${params.toString()}`;
      },
      providesTags: ['EVENT_STATS'],
    }),
    undoRemediation: builder.mutation<
      undefined,
      { orgId: string; messageId: string; action: FolderType }
    >({
      query: ({ orgId, messageId, action }) => {
        const actionDetails = getActionDetails(action);
        return {
          url: `${QUARANTINE_EMAIL_API_URL(orgId, messageId)}/undo`,
          method: 'POST',
          body: {
            action,
            actionDetails,
          },
        };
      },
      invalidatesTags: ['EVENTS'],
    }),
    getRemediatedEvents: builder.query<
      RemediatedEventsResults,
      { orgId: string } & RemediatedEventsFilter
    >({
      query: ({ orgId, offset = 0, limit = 10, from, to, sortBy, sortOrder, isAutomated }) => {
        const params = new URLSearchParams();

        params.append('offset', offset.toString());
        params.append('limit', limit.toString());

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (sortBy) params.append('sort_by', sortBy);
        if (sortOrder) params.append('sort_order', sortOrder);
        if (isAutomated) params.append('is_automated', isAutomated.toString());

        return `${REMEDIATED_EVENTS(orgId)}?${params.toString()}`;
      },
      providesTags: ['EVENTS'],
    }),
    giveFeedback: builder.mutation<
      undefined,
      { orgId: string; messageId: string; feedback: boolean | null }
    >({
      query: ({ orgId, messageId, feedback }) => {
        return {
          url: `${ALL_EVENTS_URL(orgId)}/feedback`,
          body: {
            messageId,
            feedback,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['EVENT'],
    }),
    getFlaggedEvent: builder.query<Event, { organizationId: string; messageId: string }>({
      query: ({ organizationId, messageId }) => {
        return `${ALL_EVENTS_URL(organizationId)}/${messageId}`;
      },
      providesTags: ['EVENT'],
    }),
    getRemediatedEvent: builder.query<
      RemediationItem,
      { organizationId: string; messageId: string }
    >({
      query: ({ organizationId, messageId }) => {
        return REMEDIATED_EVENT(organizationId, messageId);
      },
    }),
    getBlacklistedEvents: builder.query<BlacklistedEventResults, BlacklistFilter>({
      query: ({ limit, offset, from, to, orgId, sortBy, sortOrder }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());
        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (sortBy) params.append('sort_by', sortBy);
        if (sortOrder) params.append('sort_order', sortOrder);

        return `${BLACKLISTED_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['BLACKLISTED_EVENT'],
    }),
    getBlacklistEvent: builder.query<BlacklistItem, { orgId: string; messageId: string }>({
      query: ({ orgId, messageId }) => {
        return `${BLACKLISTED_URL(orgId)}/${messageId}`;
      },
    }),
    revertBlacklistEvent: builder.mutation<undefined, { orgId: string; messageId: string }>({
      query: ({ orgId, messageId }) => {
        return {
          method: 'DELETE',
          url: `${BLACKLISTED_URL(orgId)}/${messageId}`,
        };
      },
      invalidatesTags: ['BLACKLISTED_EVENT'],
    }),
    remediateEvent: builder.mutation<
      RemediationResponse,
      { orgId: string; messageId: string; data: RemediationRequest }
    >({
      query: ({ orgId, messageId, data }) => {
        return {
          method: 'POST',
          url: REMEDIATE_URL(orgId, messageId),
          body: data,
        };
      },
      invalidatesTags: ['BLACKLIST', 'EVENTS', 'EVENT', 'CHECK_BLACKLIST'],
    }),
    getBlacklist: builder.query<ListResults, ListFilter>({
      query: ({ orgId, type, limit, offset }) => {
        const params = new URLSearchParams();

        if (limit) params.append('limit', limit.toString());
        if (offset) params.append('offset', offset.toString());

        if (type) params.append('type', type);

        return `${BLACKLIST_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['BLACKLIST'],
    }),
    createBlacklist: builder.mutation<undefined, ListReq & { orgId: string }>({
      query: ({ orgId, listEntries }) => {
        return {
          url: `${BLACKLIST_URL(orgId)}`,
          body: {
            listEntries,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['BLACKLIST', 'CHECK_BLACKLIST'],
    }),
    checkBlacklist: builder.query<Record<string, boolean>, { orgId: string; blacklist: string[] }>({
      query: ({ orgId, blacklist }) => {
        return {
          method: 'POST',
          url: `${BLACKLIST_URL(orgId)}/exists`,
          body: {
            blacklist,
          },
        };
      },
      providesTags: ['CHECK_BLACKLIST'],
    }),
    deleteBlacklist: builder.mutation<
      undefined,
      { orgId: string; listId: string; type: RuleFilter }
    >({
      query: ({ orgId, listId, type }) => ({
        url: `${DELETE_BLACKLIST_URL(orgId, listId)}?type=${type}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BLACKLIST', 'CHECK_BLACKLIST'],
    }),
    validateBlacklist: builder.mutation<
      Record<string, ValidationDetails>,
      ListReq & { orgId: string }
    >({
      query: ({ listEntries, orgId }) => {
        return {
          method: 'POST',
          url: VALIDATE_LIST_URL(orgId, 'blacklist'),
          body: {
            listEntries,
          },
        };
      },
    }),
  }),
});

export const {
  useGetFlaggedEventsQuery,
  useRemediateMessageMutation,
  useGetRemediatedEventsQuery,
  useUndoRemediationMutation,
  useGiveFeedbackMutation,
  useGetFlaggedEventQuery,
  useGetFlaggedEventStatsQuery,
  useGetRemediatedEventQuery,
  useGetBlacklistedEventsQuery,
  useGetBlacklistEventQuery,
  useRevertBlacklistEventMutation,
  useRemediateEventMutation,
  useGetBlacklistQuery,
  useCreateBlacklistMutation,
  useDeleteBlacklistMutation,
  useValidateBlacklistMutation,
  useCheckBlacklistQuery,
} = eventsApi;
