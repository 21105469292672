import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';
import { ReactComponent as IconBlocked } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconCorrect } from 'assets/icons/icon-correct.svg';
import { ReactComponent as IconMetric } from 'assets/icons/icon-metric.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import * as Select from '@radix-ui/react-select';
import { useDLPForm } from 'contexts/DLPFormContext';
import { Divider } from 'components/Divider';

export function ConfigureActions() {
  const { formDetails, setFormDetails } = useDLPForm();

  const handleClick = (config: 'exception' | 'block', action: string) => {
    if (config === 'block' && action === 'log') return;

    const currentConfig =
      config === 'block'
        ? formDetails.actionConfiguration.block
        : formDetails.actionConfiguration.exception;
    currentConfig[action] = { ...currentConfig[action], enabled: !currentConfig[action].enabled };

    setFormDetails((prev) => {
      if (config === 'block') {
        return {
          ...prev,
          actionConfiguration: {
            ...prev.actionConfiguration,
            block: currentConfig,
          },
        };
      }
      return {
        ...prev,
        actionConfiguration: {
          ...prev.actionConfiguration,
          exception: currentConfig,
        },
      };
    });
  };

  const severityOptions = [
    {
      name: 'Critical',
      description: 'Overrides all other policies in case of conflict',
      value: 'critical',
    },
    {
      name: 'High',
      description: 'Overrides Medium and Low policies',
      value: 'high',
    },
    {
      name: 'Medium',
      description: 'Overrides Low policies',
      value: 'medium',
    },
    {
      name: 'Low',
      description: 'Will be overridden by higher severity policies',
      value: 'low',
    },
  ];

  const handleSelectChange = (value: string) => {
    setFormDetails((prev) => ({ ...prev, severity: value }));
  };

  const getIcon = (enabled: boolean) => {
    if (enabled) return <IconClose className="fill-black w-2 h-2" />;
    return <IconAdd className="fill-white w-2 h-2" />;
  };

  return (
    <div className="px-12 py-8">
      <div className="flex flex-col gap-10">
        <div className="flex items-center gap-6 w-full">
          <div className="w-10 h-10 border-light border-border-primary rounded-lg bg-white flex items-center justify-center">
            <IconBlocked className="w-5 h-5 fill-error-text" />
          </div>
          <span className="text-xl">Configure Block Actions</span>
        </div>
        <div className="flex flex-col gap-6 bg-white rounded-xl border-light border-border-primary p-7">
          <span>Policy Block Rule</span>
          <div className="w-full rounded-2xl flex items-center gap-4 justify-between">
            <button
              type="button"
              className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
            >
              <div className="w-6 h-6 rounded-full bg-soft-blue flex items-center justify-center">
                <IconIndustry />
              </div>
              <span className="text-sm text-light-grey">{formDetails.blockRule?.sender.value}</span>
            </button>
            <span className="text-sm">are</span>
            <button
              type="button"
              className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
            >
              <IconBlocked className="fill-error-text" />
              <span className="text-sm text-light-grey">Blocked</span>
            </button>
            <span className="text-sm">from sending</span>
            <button
              type="button"
              className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
            >
              <IconGlobe />
              <span className="text-sm text-light-grey">
                {formDetails.isExternal ? 'Externally' : 'Internally'}
              </span>
            </button>
            <span className="text-sm">to</span>
            <button
              type="button"
              className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
            >
              <div className="w-6 h-6 rounded-full bg-soft-purple flex items-center justify-center">
                <IconGlobe className="w-4 h-4" />
              </div>
              <span className="text-sm text-light-grey">
                {formDetails.blockRule?.recipient?.value}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-8 py-6 px-8 flex flex-col gap-6 bg-white rounded-lg border-light border-border-primary">
        <span>
          Configure additional actions when sensitive data is{' '}
          <span className="text-error-text">blocked</span> in this policy
        </span>
        <Divider customCss="my-0" />
        <div className="flex items-center flex-wrap gap-4">
          {Object.values(formDetails.actionConfiguration.block).map((item) => (
            <button
              type="button"
              className={`flex items-center gap-2 px-2.5 py-2 ${item.enabled ? 'bg-black border-light border-transparent text-white' : 'bg-soft-gray border-light border-border-primary text-black'} w-fit rounded-lg`}
              onClick={() => handleClick('block', item.value)}
            >
              <span className="text-sm">{item.name}</span>
              {item.value !== 'log' ? (
                <button
                  type="button"
                  className={`${item.enabled ? 'bg-white' : 'bg-black'} w-4 h-4 rounded-full flex items-center justify-center`}
                >
                  {getIcon(item.enabled)}
                </button>
              ) : null}
            </button>
          ))}
        </div>
      </div>
      {formDetails.exceptions && formDetails.exceptions?.length ? (
        <>
          <Divider />
          <div className="flex flex-col gap-10">
            <div className="flex items-center gap-6 w-full">
              <div className="w-10 h-10 border-light border-border-primary rounded-lg bg-white flex items-center justify-center">
                <IconCorrect className="w-5 h-5" />
              </div>
              <span className="text-xl">Configure Exception Actions</span>
            </div>
          </div>
          <div className="flex flex-col gap-8 mt-8">
            {formDetails.exceptions?.map((e) => (
              <div className="flex flex-col gap-6 bg-white rounded-xl border-light border-border-primary p-7">
                <span>{e.name}</span>
                <div className="w-full rounded-2xl flex items-center gap-4 justify-between">
                  <button
                    type="button"
                    className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
                  >
                    <div className="w-6 h-6 rounded-full bg-soft-blue flex items-center justify-center">
                      <IconIndustry />
                    </div>
                    <span className="text-sm text-light-grey">{e.sender.value}</span>
                  </button>
                  <span className="text-sm">is</span>
                  <button
                    type="button"
                    className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
                  >
                    <IconCorrect />
                    <span className="text-sm text-light-grey">Allowed</span>
                  </button>
                  <span className="text-sm">from sending</span>
                  <button
                    type="button"
                    className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
                  >
                    <IconGlobe />
                    <span className="text-sm text-light-grey">
                      {formDetails.isExternal ? 'Externally' : 'Internally'}
                    </span>
                  </button>
                  <span className="text-sm">to</span>
                  <button
                    type="button"
                    className="border-light border-border-primary rounded-lg flex items-center gap-2 py-2 px-3"
                  >
                    <div className="w-6 h-6 rounded-full bg-soft-purple flex items-center justify-center">
                      <IconGlobe className="w-4 h-4" />
                    </div>
                    <span className="text-sm text-light-grey">
                      {e.recipients?.[0]?.value || 'unknown sender'}
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8 py-6 px-8 flex flex-col gap-6 bg-white border-light border-border-primary rounded-lg">
            <span>
              Configure additional actions when sensitive data is{' '}
              <span className="text-success-green">allowed</span> in this policy
            </span>
            <Divider customCss="my-0" />
            <div className="flex items-center flex-wrap gap-4">
              {Object.values(formDetails.actionConfiguration.exception).map((item) => (
                <button
                  type="button"
                  className={`flex items-center gap-2 px-2.5 py-2 ${item.enabled ? 'bg-black border-light border-transparent text-white' : 'bg-soft-gray border-light border-border-primary text-black'} w-fit rounded-lg`}
                  onClick={() => handleClick('exception', item.value)}
                >
                  <span className="text-sm">{item.name}</span>
                  <button
                    type="button"
                    className={`${item.enabled ? 'bg-white' : 'bg-black'} w-4 h-4 rounded-full flex items-center justify-center`}
                  >
                    {item.enabled ? (
                      <IconClose className="fill-black w-2 h-2" />
                    ) : (
                      <IconAdd className="fill-white w-2 h-2" />
                    )}
                  </button>
                </button>
              ))}
            </div>
          </div>
        </>
      ) : null}
      <Divider />
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-6 w-full">
          <div className="w-10 h-10 border-light border-border-primary rounded-lg bg-white flex items-center justify-center">
            <IconMetric className="w-5 h-5" />
          </div>
          <span className="text-xl">Policy Severity</span>
        </div>
        <Select.Root onValueChange={handleSelectChange} value={formDetails.severity}>
          <Select.Trigger className="border-light border-border-primary text-black placeholder:text-light-grey p-4 rounded-md text-sm flex items-center justify-between bg-white w-fit">
            <Select.Value placeholder="Choose Severity" className="placeholder:text-light-grey" />
            <Select.Icon>
              <IconArrowDown className="w-4 h-4" />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content
              position="popper"
              className="bg-white border border-gray-300 rounded-md shadow-lg text-xs text-light-grey p-2"
              // align="end"
              side="bottom"
            >
              <Select.Viewport>
                {severityOptions.map((option) => (
                  <Select.Item
                    key={option.value}
                    value={option.value}
                    className="p-2 cursor-pointer hover:bg-gray-100 text-light-grey data-[highlighted]:text-black data-[highlighted]:bg-select-background data-[highlighted]:outline-none rounded-sm"
                  >
                    <Select.ItemText className="flex items-center gap-3">
                      <span className="text-sm">{option.name} </span>
                      <span className="text-light-grey text-sm">{`(${option.description})`}</span>
                    </Select.ItemText>
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </div>
  );
}
