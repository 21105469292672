import { useState } from 'react';

import { DLPRule } from 'models/dlp';
import { ReactComponent as IconGlobe } from 'assets/icons/icon-globe.svg';
import { ReactComponent as IconIndustry } from 'assets/icons/icon-industry.svg';

import { dateUtils } from 'utils/time';
import { toTitleCase } from 'utils/name';

import { DLPDetails } from './DLPDetails';
import { getBgColor } from './data';

interface DLPRow {
  data: DLPRule;
}

export function DLPRow({ data }: DLPRow) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const getProtectedData = () => {
    let badgeList: string[] = [];
    if (data?.protectedDataTypes?.length > 2) {
      badgeList = [
        ...data.protectedDataTypes.slice(0, 2).map((d) => d.label),
        `+ ${data.protectedDataTypes.length - 2} More`,
      ];
    } else {
      badgeList = data?.protectedDataTypes?.map((f) => f.label);
    }

    return (
      <div className="flex items-center gap-2 flex-wrap">
        {badgeList.map((badge) => (
          <button
            className="text-sm py-2 px-2.5 rounded-lg text-black border-light border-border-primary bg-soft-gray"
            type="button"
          >
            {badge}
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <tr
        className="border-b-light align-middle border-border-primary cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <td className="py-6 px-3 text-sm text-black">
          {dateUtils.format(data.createdAt, 'DD/MM/YYYY | hh:mm A').toString()}
        </td>
        <td className="py-6 px-3 text-sm text-black">{data.referenceId}</td>

        <td title={data.name} className="text-start py-6 px-3">
          <p className="text-black text-sm max-w-full text-ellipsis line-clamp-2">{data.name}</p>
        </td>
        <td className="py-6 px-3 text-sm text-black">
          <button
            type="button"
            className={`px-2.5 py-1 text-black flex items-center gap-1 ${data.scope === 'external' ? 'bg-soft-purple' : 'bg-soft-blue'} border-light border-border-primary rounded`}
          >
            {data.scope === 'internal' ? (
              <IconIndustry className="fill-black" />
            ) : (
              <IconGlobe className="fill-black" />
            )}
            <span className="text-xs">{toTitleCase(data.scope)}</span>
          </button>
        </td>
        <td className="py-6 px-3 text-sm text-black">{getProtectedData()}</td>
        <td className="py-6 px-3 text-sm text-black">
          <button
            className={`text-sm py-2 px-2.5 rounded-lg text-black border-light border-border-primary ${getBgColor(data.status)}`}
            type="button"
          >
            {toTitleCase(data.status)}
          </button>
        </td>
        {/* <td className="py-6 px-3 text-sm text-black">
          <ActionMenu
            icon={
              <button type="button" className="p-2 rounded-lg border-light border-border-primary">
                <IconSettings className="w-4 h-4" />
              </button>
            }
            actions={actions}
          />
        </td> */}
      </tr>
      <DLPDetails open={open} policyId={data.id} onClose={handleClose} />
    </>
  );
}
