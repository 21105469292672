import { useState } from 'react';

import Loader from 'components/Loader';
import { ReactComponent as IconRight } from 'assets/icons/icon-chevron-right.svg';

import { useDLPForm } from 'contexts/DLPFormContext';
import { Divider } from 'components/Divider';
import { Link } from 'react-router-dom';

import { DLPActionsPanel } from './DLPActionsPanel';
import { PolicyPanel } from './PolicyPanel';
import { PolicyPreview } from './PolicyPreview';
import { PolicyRule } from './PolicyRules';
import { RulePreview } from './RulePreview';
import { ConfigureActions } from './ConfigureActions';
import { ActionPreviewPanel } from './ActionPreview';
import { PolicyReview } from './PolicyReview';
import { DLPAlert } from './DLPAlert';

export function DLPForm() {
  const { activeStep, formSteps, loading, mode, formDetails, policyId, onDiscard } = useDLPForm();

  const [open, setOpen] = useState(false);

  const getActionSection = (): React.ReactNode => {
    const stepToActionComponent: Record<number, React.ReactNode> = {
      0: <PolicyPanel />,
      1: <PolicyRule />,
      2: <ConfigureActions />,
      3: <PolicyReview />,
    };

    return stepToActionComponent[activeStep];
  };

  const getPreviewSection = (): React.ReactNode => {
    const stepToPreviewComponent: Record<number, React.ReactNode> = {
      0: <PolicyPreview />,
      1: <RulePreview />,
      2: <ActionPreviewPanel />,
    };

    return stepToPreviewComponent[activeStep];
  };

  const handleDiscardOpen = () => {
    if (policyId) {
      setOpen(true);
    }
  };
  return (
    <>
      {' '}
      <div
        className={`w-screen h-screen ${activeStep === formSteps.length - 1 ? 'pl-10' : 'px-10'} flex flex-col relative`}
      >
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader customCss="w-10 h-10 border-2" />
          </div>
        ) : (
          <>
            <div className="sticky top-0 z-50 bg-white">
              <div className="w-full flex items-center justify-between">
                <Link to="/dlp/list" className="py-6 flex items-center gap-4">
                  <div className="w-6 h-6 border border-border-primary rounded-full bg-black p-1">
                    <IconRight className="rotate-180 w-full h-full stroke-white" />
                  </div>
                  <span className="text-xl">
                    {mode === 'edit'
                      ? `Edit ${formDetails.name?.value} Policy`
                      : 'Configure a New DLP Policy'}
                  </span>
                </Link>
                {mode !== 'edit' && policyId !== '' ? (
                  <button
                    type="button"
                    className={`w-fit py-2 px-4 mr-4 rounded-lg border-light border-border-primary bg-soft-red text-error-text ${policyId === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => handleDiscardOpen()}
                  >
                    Discard Policy
                  </button>
                ) : null}
              </div>
              <Divider customCss="my-0" />
            </div>
            <div className="flex-1 flex w-full overflow-hidden">
              <DLPActionsPanel />
              <div className="bg-soft-gray h-full overflow-auto no-scrollbar flex-1">
                {getActionSection()}
              </div>
              {activeStep !== formSteps.length - 1 ? (
                <div className="border-l-light border-border-primary h-full overflow-auto no-scrollbar w-72">
                  {getPreviewSection()}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      <DLPAlert
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => onDiscard()}
        handleClose={() => setOpen(false)}
      />
    </>
  );
}
