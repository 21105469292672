import { createApi } from '@reduxjs/toolkit/query/react';

import { ThreatFrequencyFilters, ThreatFrequencyResponse } from 'models/threatInferences';
import { THREAT_FREQUENCY_URL } from 'constants/apiUrls';

import { baseQuery } from './baseQuery';

export const threatFrequencyApi = createApi({
  reducerPath: 'threatFrequencyApi',
  baseQuery: baseQuery,
  tagTypes: ['threat-frequency'],
  endpoints: (builder) => ({
    getThreatFrequency: builder.query<ThreatFrequencyResponse, ThreatFrequencyFilters>({
      query: ({ orgId, from, to, frequency, threat, category }) => {
        const params = new URLSearchParams();

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        if (frequency) params.append('frequency', frequency);
        if (threat) params.append('threats', threat);
        if (category) params.append('category', category);

        return `${THREAT_FREQUENCY_URL(orgId)}?${params.toString()}`;
      },
      providesTags: ['threat-frequency'],
    }),
  }),
});

export const { useGetThreatFrequencyQuery } = threatFrequencyApi;
