interface DLPItemBadgeProps {
  label: string;
  icon?: React.ReactNode;
}

export function DLPItemBadge({ label, icon }: DLPItemBadgeProps) {
  return (
    <button
      type="button"
      className="rounded-lg bg-soft-gray text-sm border-light border-border-primary px-2 py-1 flex items-center gap-2"
    >
      {icon || null}
      <span className="text-sm">{label}</span>
    </button>
  );
}
