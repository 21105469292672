import { useState } from 'react';
import { ExceptionDetails } from 'components/DLP/data';

import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';

import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';
import { Divider } from 'components/Divider';

import { ExceptionInput } from './ExceptionInput';

interface ExceptionFormProps {
  onChange: (details: ExceptionDetails) => void;
  onDelete: () => void;
  exception: ExceptionDetails;
}

export function ExceptionFormItem({ onChange, onDelete, exception }: ExceptionFormProps) {
  const [isOpen, setIsOpen] = useState(true);

  const handleInternalUserAdd = (value: string, type: string, error: string) => {
    const updatedException = {
      ...exception,
      sender: { value, type: type === 'all' ? 'all_internal' : type, error },
    };
    onChange(updatedException);
  };

  const handleExternalUserAdd = (
    eIndex: number,
    value: string,
    type: string,
    externalUserErr: string
  ) => {
    const updatedException = {
      ...exception,
      recipients: exception.recipients.map((r, i) =>
        eIndex === i
          ? { value, type: type === 'all' ? 'all_external' : type, error: externalUserErr }
          : r
      ),
    };
    onChange(updatedException);
  };

  const handleRemoveNewRecipient = (rIndex: number) => {
    const updatedException = {
      ...exception,
      recipients: exception.recipients.filter((_, i) => i !== rIndex),
    };
    onChange(updatedException);
    return updatedException;
  };

  const handleAddNewRecipient = () => {
    const updatedException = {
      ...exception,
      recipients: [...exception.recipients, { value: '', type: '', error: '' }],
    };
    onChange(updatedException);
  };

  return (
    <div className="border-light border-border-primary rounded-lg bg-white">
      <div
        className={`p-5 flex items-center justify-between ${isOpen ? 'border-b-light border-border-primary' : ''} gap-2`}
      >
        <span>{exception.name}</span>
        <div className="flex items-center gap-3.5">
          <div className="w-8 h-8 border-light border-border-primary flex items-center justify-center rounded-lg cursor-pointer">
            <IconTrash className="w-4 h-4" onClick={() => onDelete()} />
          </div>
          <div className="w-8 h-8 flex items-center justify-center">
            <IconArrowDown
              className={`w-full h-full transition-transform duration-400 ${isOpen ? '' : 'rotate-180'} stroke-light-grey`}
              onClick={() => setIsOpen((prev) => !prev)}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="p-5 flex flex-col gap-6">
          <span>Sender ID / Domain</span>
          <div className="flex flex-col gap-2 w-full">
            <ExceptionInput
              value={exception.sender.value}
              onAdd={({ value, type, error }) => handleInternalUserAdd(value, type, error || '')}
              placeholder="Search for internal users and domains"
              error={exception.sender.error}
            />
            {exception.sender.error ? (
              <span className="text-xs text-error-text">{exception.sender.error}</span>
            ) : null}
          </div>
          <Divider customCss="my-0" />
          <span>Recipient ID / Domain</span>
          {exception.recipients.map((r, i) => (
            <div className="w-full flex flex-col gap-2">
              <div className="flex items-center gap-2 w-full">
                <ExceptionInput
                  onAdd={({ value, type, error }) =>
                    handleExternalUserAdd(i, value, type, error || '')
                  }
                  placeholder="Search for external users and domains"
                  value={r.value}
                  error={r.error}
                  onClear={() => handleRemoveNewRecipient(i)}
                  showClear={exception.recipients.length > 1}
                />
                {i === exception.recipients.length - 1 ? (
                  <button
                    type="button"
                    className="w-8 h-8 rounded-full text-xl border-light border-border-primary bg-action-button font-light flex items-center justify-center"
                    onClick={() => handleAddNewRecipient()}
                  >
                    <IconAdd className="w-4 h-4" />
                  </button>
                ) : null}
              </div>
              {r.error ? <span className="text-xs text-error-text">{r.error}</span> : null}
            </div>
          ))}
          {/* <button
            type="button"
            onClick={() => handleAddNewRecipient()}
            className="bg-white rounded-lg p-2 border-light border-border-primary w-fit text-xs"
          >
            + Add New Recipient
          </button> */}
        </div>
      )}
    </div>
  );
}
