import { EventStatus } from 'enums/eventStatusTypes';

export interface Email {
  name: string;
  email: string;
}

export interface Attachment {
  id: string;
  name: string;
  type: string;
  suspicious: boolean;
  threatCategory: string;
}

export interface Link {
  id: string;
  path: string;
  root: string;
  suspicious: boolean;
}

export interface Threat {
  code: string;
  inferences: string[];
}

interface SenderDetails extends Email {
  vendor: boolean;
  regulator: boolean;
}

export interface RecipientDetails extends Email {
  vip: boolean;
  isPrimary: boolean;
}

export interface LabelItem {
  label: string;
  value: string;
}

export interface Event {
  sender: SenderDetails;
  messageId: string;
  employeeId: string;
  recipients: RecipientDetails[];
  cc: Email[];
  bcc: Email[];
  subject: string;
  attachments: Attachment[];
  links: Link[];
  detections: LabelItem[];
  inferences: LabelItem[];
  intent: string[];
  tone: string[];
  reason: string;
  time: string;
  severity: number;
  status: EventStatus;
  detection_tags?: string[];
  designation: string;
  ipAddress: string;
  location: string;
  vectors: {
    code: string;
    inferences: string[];
  }[];
  isUpvoted?: boolean;
  threats: Threat[];
  webhookRecipients: Email[];
  enhancedReasoning: string;
}

export interface EventsResponse {
  hits: Event[];
  total: number;
  limit: number;
  offset: number;
}

export interface GetEventsQueryParams {
  offset?: number;
  limit?: number;
  from?: string;
  to?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc' | null;
  vector?: string;
  threat?: string;
  status: 'pending' | 'remediated' | '';
}

export interface FlaggedEventStatusMetric {
  status: string;
  count: number;
}

export interface GetFlaggedEventStatusParams {
  from?: string;
  to?: string;
  threats?: string;
}

export interface RemediatedEventsFilter {
  offset: number;
  limit: number;
  from?: string;
  to?: string;
  sortBy?: string;
  sortOrder?: string;
  threat?: string;
  searchQuery?: string;
  isAutomated?: boolean;
}

export interface RemediationActionDetails {
  action: string;
  actionBy: string;
}

export interface RemediationItem {
  id: string;
  action: string;
  actionBy: string;
  createdAt: string;
  userRemediation?: RemediationActionDetails;
  event: Event;
  isAutomated: boolean;
  reason: string;
}

export interface RemediatedEventsResults {
  hits: RemediationItem[];
  total: number;
  limit: number;
  offset: number;
}

export interface BlacklistItem {
  id: string;
  messageId: string;
  subject: string;
  sender: Email;
  recipients: Email[];
  isAutomated: boolean;
  actionDetails: string;
  emailDate: string;
  remediationDate: string;
  action: string;
  actionBy: string;
  type: string;
  value: string;
}

export interface BlacklistedEventResults {
  hits: BlacklistItem[];
  total: number;
  limit: number;
  offset: number;
}

export interface BlacklistFilter {
  orgId: string;
  limit: number;
  offset: number;
  from: string;
  to: string;
  sortOrder: string;
  sortBy: string;
}

interface EmployeeRemediationAction {
  employeeId: string;
}

interface BlacklistAction {
  entries: string[];
}

interface MessageRemediationAction {
  action: string;
}

export interface RemediationRequest {
  employee?: EmployeeRemediationAction;
  blacklist?: BlacklistAction;
  message?: MessageRemediationAction;
}

// Types for the API response
export interface RemediationResult {
  action: 'password_reset' | 'move_message' | 'blacklist';
  success: boolean;
  error?: string;
}

export interface RemediationResponse {
  overallSuccess: boolean;
  results: RemediationResult[];
}

// Helper to get user-friendly action names
export const getActionDisplayName = (action: string, result: boolean): string => {
  const actionMap: Record<string, string> = {
    password_reset: result
      ? 'Affected Recipient logged out'
      : 'Failed to logout affected recipient',
    move_message: result ? 'Mail moved successfully' : 'Failed to move mail to junk / quarantine',
    blacklist: result ? 'Sender & Domain blocked' : 'Sender & Domain block failed',
  };
  return actionMap[action] || action;
};
