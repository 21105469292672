import { DLPForm } from 'components/DLP/DLPForm';

import { DLPFormProvider } from 'contexts/DLPFormContext';

export default function CreateCustomDLPRule() {
  return (
    <DLPFormProvider>
      <DLPForm />
    </DLPFormProvider>
  );
}
