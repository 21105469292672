import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Checkbox from '@radix-ui/react-checkbox';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import { GetUsersFilter } from 'models/user';
import { AdminUser } from 'models/admin';
import { useRemoveAdminsMutation } from 'service/users';
import { useOrgId } from 'hooks/useOrgId';
import { useAuth } from 'contexts/AuthContext';
import { WarningToast } from 'components/Toasts/WarningToast';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { Alert } from 'components/Alert';

import { NoDataFallback } from 'components/Fallback/NoDataFallback';
import Loader from 'components/Loader';

import { AdminRow } from './AdminRow';

export interface AdminItem {
  id: string;
  name: string;
  email: string;
  role: string;
  isChecked: boolean;
  isVip: boolean;
  employeeId: string;
}

interface AdminTableProps {
  admins: AdminUser[];
  isLoading: boolean;
  filters: GetUsersFilter;
  setFilters: React.Dispatch<React.SetStateAction<GetUsersFilter>>;
  total: number;
  showAdminForm: () => void;
}

export function AdminTable({
  admins,
  isLoading,
  filters,
  setFilters,
  total,
  showAdminForm,
}: AdminTableProps): JSX.Element {
  const { email: loggedInUserEmail, superadmin } = useAuth();

  const { showToast } = useToast();

  const adminHeaderColumnNames = ['Name', 'Email ID', 'Role', 'Actions'];

  const [adminData, setAdminData] = useState<AdminItem[]>([]);

  // used to manage the state of the alert which opens after clicking on Remove From List button
  // above the table component
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setAdminData(admins.map((admin) => ({ ...admin, isChecked: false, isVip: admin.vip })));
  }, [admins]);

  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleToggle = (index: number) => {
    if (adminData[index].email.toLowerCase() === loggedInUserEmail.toLowerCase()) {
      showToast({
        component: <WarningToast message="You can't remove yourself from admins" />,
      });
      return;
    }

    // implement the toggle logic with index
    // update the toggle index
    const updatedVipData = [...adminData];
    updatedVipData[index].isChecked = !adminData[index].isChecked;

    if (updatedVipData.every((item) => item.isChecked)) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }

    setAdminData(updatedVipData);
  };

  const setAllChecked = () => {
    setIsAllChecked((prevChecked) => {
      if (prevChecked) {
        setAdminData(adminData.map((item) => ({ ...item, isChecked: false })));
      } else {
        setAdminData(
          adminData.map((item) =>
            item.email.toLowerCase() !== loggedInUserEmail.toLowerCase()
              ? { ...item, isChecked: true }
              : item
          )
        );
      }
      return !prevChecked;
    });
  };

  const [removeAdmins] = useRemoveAdminsMutation();

  const [OrgID] = useOrgId();

  const handleRemove = async (userIds: string[]) => {
    // eslint disable-next-line no-console
    try {
      await removeAdmins({
        orgId: OrgID,
        adminIds: userIds,
      }).unwrap();

      showToast({
        component: <SuccessToast message="Admin removed successfully" />,
      });

      if (isAllChecked) {
        setIsAllChecked(false);
        setAdminData([]);
      }

      setAlertOpen(false);
    } catch {
      showToast({
        component: <ErrorToast message="Failed to remove admin" />,
      });
    }
  };

  const getFallback = () => {
    return (
      <tr>
        <td colSpan={adminHeaderColumnNames.length + 1}>
          <div className="w-full flex items-center justify-center p-4">
            {isLoading ? (
              <Loader />
            ) : (
              <NoDataFallback
                title="Nothing to show here"
                description="Get started by adding the admins"
                actionButtonText="Add new admin +"
                onAdd={() => showAdminForm()}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="w-full overflow-auto">
        {adminData.some((data) => data.isChecked) ? (
          <div className="flex items-center gap-6 text-xs my-4">
            <span className="text-light-grey">Actions</span>
            <button
              type="button"
              className="bg-light-grey py-2 px-4 rounded-lg text-white"
              onClick={() => setAlertOpen(true)}
            >
              Remove from List
            </button>
          </div>
        ) : null}
        <table className="w-full bg-white overflow-auto rounded-t-lg">
          <thead className="text-2xs text-light-grey w-full border-y-light border-y-border-primary">
            <tr>
              <th className="px-1 py-3 font-normal text-start w-4" scope="col">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <Checkbox.Root
                  id="select-all"
                  className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:bg-light-grey rounded text-center flex justify-center items-center p-1"
                  onClick={() => setAllChecked()}
                  checked={isAllChecked}
                  disabled={adminData.length === 0}
                >
                  <Checkbox.CheckboxIndicator>
                    <IconCheck />
                  </Checkbox.CheckboxIndicator>
                </Checkbox.Root>
              </th>
              {adminHeaderColumnNames
                .filter((column) => (column === 'Actions' ? superadmin : true))
                .map((header) => {
                  return (
                    <th
                      key={header}
                      scope="col"
                      className={`px-6 py-3 font-normal text-start text-light-grey text-sm ${header === 'Actions' ? 'text-end' : ''}`}
                    >
                      <div className="inline-flex items-center gap-1">
                        <span>{header}</span>
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className="w-full">
            {adminData.length && !isLoading
              ? adminData.map((data, index) => {
                  return (
                    <AdminRow
                      key={data.email}
                      name={data.name}
                      role={data.role}
                      email={data.email}
                      onToggle={() => handleToggle(index)}
                      isChecked={data.isChecked}
                      isVip={data.isVip}
                      onRemove={() => handleRemove([data.employeeId])}
                    />
                  );
                })
              : getFallback()}
          </tbody>
        </table>
        <div className="bg-white rounded-b-lg">
          {admins?.length > 0 && !isLoading && (
            <div className="flex justify-between px-6 py-4 items-center">
              <span className="text-xs font-light">
                Showing {admins?.length > 0 ? filters.offset + 1 : '0'} -{' '}
                {admins.length > 0 ? filters.offset + admins.length : '0'} of {total}
              </span>
              <div className="flex rounded-full items-center border border-pagination-border cursor-pointer">
                <IconArrowDown
                  className={`w-6 h-6 rotate-90 border-t-light border-t-pagination-border ${filters.offset > 0 ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (admins) {
                      if (filters.offset > 0) {
                        setFilters({
                          ...filters,
                          offset: filters.offset - filters.limit,
                        });
                      }
                    }
                  }}
                />
                <IconArrowDown
                  className={`w-6 h-6 -rotate-90 ${filters.offset + filters.limit < total ? 'cursor-pointer' : 'cursor-not-allowed opacity-40'}`}
                  onClick={() => {
                    if (filters.offset + filters.limit < total) {
                      setFilters({
                        ...filters,
                        offset: filters.offset + filters.limit,
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Alert
        open={alertOpen}
        message={`Are you sure you want to remove ${
          adminData.filter((data) => data.isChecked).length > 1 ? 'these' : 'this'
        } ${adminData.filter((data) => data.isChecked).length > 1 ? 'admins' : 'admin'}?`}
        onCancel={() => setAlertOpen(false)}
        onConfirm={() =>
          handleRemove(
            // filtering the adminData array to get only the employeeId of the checked admin
            adminData.filter((data) => data.isChecked).map((data) => data.employeeId)
          )
        }
      />
    </>
  );
}
