export function getAudienceType(audience: string): string {
  // Trim whitespace and convert to lowercase
  const audienceStr = audience.trim().toLowerCase();

  // Check for "All Internal Users" or "All External Users"
  if (
    audienceStr === 'all internal users' ||
    audienceStr === 'all external users' ||
    audienceStr === 'all_internal' ||
    audienceStr === 'all_external'
  ) {
    return 'all';
  }

  // Check if it's an email with regex validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (audienceStr.includes('@') && emailRegex.test(audienceStr)) {
    return 'email';
  }

  // Check if it's a domain with regex validation
  const domainPattern =
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
  if (domainPattern.test(audienceStr)) {
    return 'domain';
  }

  // If it doesn't match any valid pattern
  return '';
}
