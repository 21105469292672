import { Divider } from 'components/Divider';
import Loader from 'components/Loader';
import { useAuth } from 'contexts/AuthContext';
import { useOrgId } from 'hooks/useOrgId';
import {
  useGetProfileQuery,
  useUpdateNotificationPreferenceMutation,
} from 'service/profileOverviewApi';
import { ReactComponent as IconArrowDown } from 'assets/icons/icon-arrow-down.svg';

import * as Switch from '@radix-ui/react-switch';
import { getInitials } from 'utils/name';
import { useToast } from 'hooks/toast';
import { SuccessToast } from 'components/Toasts/SuccessToast';
import { ErrorToast } from 'components/Toasts/ErrorToast';

export function GeneralSettings() {
  const [orgId] = useOrgId();

  const { showToast } = useToast();

  const { data: profileData, isLoading } = useGetProfileQuery({ orgId });

  const { superadmin } = useAuth();

  const [updateNotificationPreference] = useUpdateNotificationPreferenceMutation();

  if (isLoading) {
    return (
      <div className="w-full flex justify-center">
        <Loader />
      </div>
    );
  }

  const handleChecked = async () => {
    try {
      if (profileData?.emailAlert) {
        await updateNotificationPreference({ orgId, preference: false }).unwrap();
      } else {
        await updateNotificationPreference({ orgId, preference: true }).unwrap();
      }

      showToast({
        component: <SuccessToast message="Notification preference updated succesfully" />,
      });
    } catch {
      showToast({
        component: (
          <ErrorToast message="Unable to update notification preference, please try again." />
        ),
      });
    }
  };

  return (
    <div className="px-12 py-10 w-3/4 flex flex-col">
      <span className="text-light-grey">Profile Settings</span>
      <Divider />
      <div className="flex gap-28">
        <div className="flex gap-4 items-center">
          <div className="w-12 h-12 rounded-full bg-soft-blue text-avatar-blue text-lg border-light border-avatar-border flex justify-center items-center">
            {getInitials(
              (profileData?.displayName ? profileData?.displayName : profileData?.emailAddress) ||
                'U',
              2
            )}
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-black text-base">{profileData?.displayName}</span>
            <span className="text-black text-base">{`<${profileData?.emailAddress}>`}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 justify-between gap-y-6 flex-1">
          <div className="flex flex-col gap-1">
            <span className="text-light-grey text-xs">Role</span>
            <span className="text-black">
              {superadmin ? 'Super Administrator' : 'Administrator'}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-light-grey text-xs">Phone Number</span>
            <span className="text-black">{profileData?.phone || '-'}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-light-grey text-xs">Organization</span>
            <span className="text-black">{profileData?.organization || '-'}</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-light-grey text-xs">Designation</span>
            <span className="text-black">{profileData?.role || '-'}</span>
          </div>
        </div>
      </div>
      <div className="mt-20 flex items-center gap-4">
        <span className="text-light-grey">Email Alerts</span>
        <Switch.Root
          checked={profileData?.emailAlert}
          className="relative h-6 w-10 cursor-pointer rounded-full outline-none focus:shadow-black data-[state=checked]:bg-gradient-to-r bg-border-primary from-gradient-yellow to-gradient-green border border-border-primary"
          onCheckedChange={handleChecked}
        >
          <Switch.Thumb className="border border-border-primary block size-5 translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-4" />
        </Switch.Root>
      </div>
      <Divider />
      {!profileData?.emailAlert ? (
        <div className="w-full py-3.5 px-7 flex justify-between bg-soft-gray border-light border-border-primary rounded-lg">
          <div className="flex flex-col gap-2">
            <span className="text-base text-black">Email Alerts are disabled</span>
            <span className="text-sm text-light-grey">
              Turn on to receive Critical Threats & Auto remediated mail alerts.
            </span>
          </div>
          <button
            type="button"
            className="bg-black rounded-lg py-2 px-4 text-white"
            onClick={() => handleChecked()}
          >
            Turn on
          </button>
        </div>
      ) : (
        <div className="w-full py-3.5 px-7 flex justify-between bg-soft-green border-light border-border-primary rounded-lg">
          <div className="flex flex-col gap-2">
            <span className="text-base text-black">Email Alerts are Enabled</span>
            <span className="text-sm text-light-grey">
              You will receive Critical Threats & Auto remediated mail alerts.
            </span>
          </div>
        </div>
      )}
      <div className="mt-20 w-full">
        <span className="text-light-grey">General</span>
        <Divider />
        <div className="flex flex-col gap-8">
          <div className="flex justify-between w-full items-center">
            <div className="flex flex-col gap-2">
              <span className="text-base text-black">Appearance</span>
              <span className="text-sm text-light-grey">
                Customize how you themes looks on your device{' '}
              </span>
            </div>
            <button
              className="px-4 py-2 bg-soft-gray border-light border-border-primary text-light-grey cursor-not-allowed h-fit rounded-lg flex items-center gap-2"
              type="button"
            >
              Light
              <IconArrowDown className="w-6 h-6 stroke-light-grey" />{' '}
            </button>
          </div>
          <div className="flex justify-between w-full items-center">
            <div className="flex flex-col gap-2">
              <span className="text-base text-black">Language</span>
              <span className="text-sm text-light-grey">
                Select your preferred language for using this product
              </span>
            </div>
            <button
              className="px-4 py-2 bg-soft-gray border-light border-border-primary text-light-grey cursor-not-allowed h-fit rounded-lg flex items-center gap-2"
              type="button"
            >
              English
              <IconArrowDown className="w-6 h-6 stroke-light-grey" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
