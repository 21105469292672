import { Metric } from 'models/metric';

export interface ThreatInferencesFilter {
  from?: string;
  to?: string;
  limit?: number;
  sort?: string;
  order?: string;
  threat?: string;
}

export interface ThreatInferencesResponse {
  hits: Metric[];
  total: number;
}

export interface ThreatFrequencyFilters {
  orgId: string;
  from?: string;
  to?: string;
  frequency?: string;
  threat?: string;
  category?: string;
}

export interface Frequency {
  date: string;
  count: number;
}

export interface FrequencyThreat {
  code: string;
  label: string;
  frequency: Frequency[];
}

export interface ThreatFrequencyResponse {
  threats: FrequencyThreat[];
}

export enum ThreatType {
  VIPImpersonation = 'vip_impersonation',
  VENDORImpersonation = 'vendor_impersonation',
  REGULATORImpersonation = 'regulator_impersonation',
  MALWARE = 'malware',
  BEC = 'bec',
  FINANCIAL_FRAUD = 'financial_fraud',
  ATTORNEY_FRAUD = 'attorney_fraud',
  INVOICE_FRAUD = 'invoice_fraud',
  INSIDER_THREAT = 'insider_threat',
}
