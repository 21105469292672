import { ReactComponent as IconExpired } from 'assets/icons/expired.svg';

interface SessionExpiredModalProps {
  isOpen: boolean;
  onLogin: () => void;
}

function SessionExpiredModal({ isOpen, onLogin }: SessionExpiredModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 flex flex-col justify-between gap-8 items-center">
        <IconExpired />
        <div className="flex flex-col gap-2">
          <span className="text-black text-xl">Your session has expired.</span>
          <span className="text-base text-light-grey">Please Log in again to continue.</span>
        </div>
        <button
          type="button"
          className="py-4 px-8 w-full rounded-lg bg-black text-white"
          onClick={() => onLogin()}
        >
          Log In
        </button>
      </div>
    </div>
  );
}

export default SessionExpiredModal;
