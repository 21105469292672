import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_CATEGORIES } from 'constants/apiUrls';
import { Category } from 'models/categories';
import { baseQuery } from './baseQuery';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: baseQuery,
  tagTypes: ['CATEGORY'],
  endpoints: (builder) => ({
    getCategories: builder.query<Category[], void>({
      query: () => GET_CATEGORIES,
    }),
  }),
});

export const { useGetCategoriesQuery } = categoryApi;
