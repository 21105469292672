import { ReactComponent as InfoAllowIcon } from 'assets/icons/add-allow-list.svg';
import { ReactComponent as InfoBlockIcon } from 'assets/icons/add-block-list.svg';

import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconWarning } from 'assets/icons/icon-warning-alert.svg';

import { RuleFormInput } from 'components/Rules/RuleFormInput';
import { ListRecord, ValidationDetails } from 'models/rules';
import { useState } from 'react';

import { useValidateBlacklistMutation } from 'service/eventsApi';
import { useValidateWhitelistMutation } from 'service/whitelist';
import { useOrgId } from 'hooks/useOrgId';
import { useToast } from 'hooks/toast';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import Loader from 'components/Loader';

import { validateInput } from './data';
import { ValidationTile } from './ValidationTile';

interface RulesFormProps {
  ruleType: 'allowlist' | 'blacklist';
  onClose: () => void;
  onSubmit: (lists: ListRecord[]) => void;
}
export function RulesForm({ onClose, onSubmit, ruleType }: RulesFormProps) {
  const { showToast } = useToast();

  const [validationState, setValidationState] = useState<Record<string, ValidationDetails>>();

  const [formInput, setFormInput] = useState<Array<ListRecord & { error?: string }>>([
    {
      value: '',
      comment: '',
      error: '',
    },
  ]);

  const handleAdd = (index: number): void => {
    const newInput: ListRecord & { error: string } = { value: '', comment: '', error: '' };
    const updatedInputs = [
      ...formInput.slice(0, index + 1),
      newInput,
      ...formInput.slice(index + 1),
    ];
    setFormInput(updatedInputs);
  };

  const handleClose = (index: number): void => {
    if (formInput.length === 1) return;

    const newInputs = formInput.filter((_, i) => i !== index);
    setFormInput(newInputs);
  };

  const hasEmptyOrErrorValues = (): boolean => {
    return formInput.some((input) => !input.value.trim() || input.error);
  };

  const [OrgId] = useOrgId();

  const [validateBlacklist, { isLoading }] = useValidateBlacklistMutation();
  const [validateWhitelist, { isLoading: isWhitelistLoading }] = useValidateWhitelistMutation();

  const [validationInitiated, setValidationInitiated] = useState(false);

  const handleSubmit = async () => {
    if (hasEmptyOrErrorValues()) {
      return;
    }

    const formValues: ListRecord[] = formInput.map((input) => ({
      value: input.value.trim(),
      comment: input.comment.trim(),
    }));

    const conflictCount = Object.values(validationState || {}).reduce(
      (count, detail) => count + (detail.valid ? 0 : 1),
      0
    );

    if (validationInitiated && conflictCount === 0) {
      onSubmit(formValues);
      return;
    }

    try {
      if (ruleType === 'allowlist') {
        const { data } = await validateWhitelist({
          orgId: OrgId,
          listEntries: formValues,
        });
        setValidationState(data);
      } else {
        const { data } = await validateBlacklist({
          orgId: OrgId,
          listEntries: formValues,
        });
        setValidationState(data);
      }
    } catch {
      showToast({
        component: <ErrorToast message="Something went wrong, please try again" />,
      });
    } finally {
      setValidationInitiated(true);
    }
  };

  const getValidationStatus = () => {
    if (!validationInitiated) return false; // if validation is not initiated it will be false
    if (!validationState) return false;
    return Object.values(validationState).every((detail) => detail.valid);
  };

  const getValidationTile = () => {
    const conflictCount = Object.values(validationState || {}).reduce(
      (count, detail) => count + (detail.valid ? 0 : 1),
      0
    );

    const errorCount = Object.values(validationState || {}).reduce(
      (count, detail) => count + (detail?.error ? 1 : 0),
      0
    );

    if (errorCount > 0 && conflictCount === 0) {
      return (
        <ValidationTile
          text={`(${errorCount}) Exception Detection. Proceed with caution.`}
          bgColor="soft-sand"
          icon={<IconWarning className="fill-observation-text w-3.5 h-3.5" />}
        />
      );
    }

    if (getValidationStatus()) {
      return (
        <ValidationTile
          text="Account Successfully Validated"
          icon={
            <div className="w-3.5 h-3.5 bg-green-400 rounded-full text-center flex items-center justify-center">
              <IconCheck className="w-2 h-2" />
            </div>
          }
          bgColor="soft-green"
        />
      );
    }
    if (isLoading || isWhitelistLoading) {
      return (
        <ValidationTile
          bgColor="soft-blue"
          text="Validation Pending"
          icon={<InfoIcon className="fill-avatar-blue h-3.5 w-3.5" />}
        />
      );
    }

    const text = `(${conflictCount}) detected. Resolve them to continue.`;

    return (
      <ValidationTile
        text={text}
        bgColor="soft-red"
        icon={<IconWarning className="fill-red-600 w-3.5 h-3.5" />}
      />
    );
  };

  const handleChange = (index: number, key: string, value: string): void => {
    let newInputs: Array<ListRecord & { error?: string }> = [];
    if (key === 'value') {
      const { isValid, error } = validateInput(value);
      if (isValid) {
        newInputs = formInput.map((input, i) =>
          i === index ? { ...input, [key]: value, error: '' } : input
        );
      } else {
        newInputs = formInput.map((input, i) =>
          i === index ? { ...input, [key]: value, error } : input
        );
      }
    } else {
      newInputs = formInput.map((input, i) => (i === index ? { ...input, [key]: value } : input));
    }

    setFormInput(newInputs);
    // if the validation was earlier initiated and user has changed the inputs it should be turned to false
    if (validationInitiated) {
      setValidationInitiated(false);
    }
  };

  const getButtonText = () => {
    if (getValidationStatus()) return `Add to ${ruleType === 'blacklist' ? 'Block' : 'Allow'} List`;

    const conflictCount = Object.values(validationState || {}).reduce(
      (count, detail) => count + (detail.valid ? 0 : 1),
      0
    );

    if (conflictCount) return 'Revalidate Accounts';

    return 'Validate Accounts';
  };

  return (
    <>
      <div className="px-8 py-6 border-b-light border-b-border-primary flex items-center justify-center w-full relative">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 flex items-center justify-center gap-4 border-light border-border-primary rounded-md shadow-sm">
            {ruleType === 'allowlist' ? (
              <InfoAllowIcon className="w-5 h-5" />
            ) : (
              <InfoBlockIcon className="w-5 h-5" />
            )}
          </div>
          <span className="text-xl">
            Add to {ruleType === 'allowlist' ? 'Allow' : 'Block'} List
          </span>
        </div>
        <button
          type="button"
          className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
          onClick={() => onClose()}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <IconClose className="w-2.5 h-2.5 fill-light-grey" />
        </button>
      </div>
      <div className="w-full border-light border-y-border-primary px-6 py-8 flex items-center justify-between gap-10">
        {!validationInitiated ? (
          <span className="text-lg">
            Add an entry below to add it to the {ruleType === 'allowlist' ? 'Allow' : 'Block'} List
          </span>
        ) : (
          getValidationTile()
        )}
        <span className="w-fit px-2 py-1 bg-soft-gray border-light border-border-primary rounded-md text-sm text-light-grey">
          {formInput.filter((f) => f.value !== '').length} account(s) added
        </span>
      </div>
      <div className="p-8 w-full flex flex-col gap-4 max-h-overview-modal overflow-hidden overflow-y-scroll no-scrollbar border-b-light border-border-primary">
        {formInput.map((input, index) => (
          <RuleFormInput
            onChange={handleChange}
            value={input}
            index={index}
            onAdd={handleAdd}
            onClose={handleClose}
            validation={validationState?.[input.value]}
          />
        ))}
        <div className="flex w-full items-center justify-center gap-2">
          <InfoIcon className="w-3.5 h-3.5 fill-light-grey" />
          <span className="text-light-grey italic text-sm">
            {ruleType === 'allowlist'
              ? "Any mail from these accounts will be allowed in the user's inbox and will not be checked for threats"
              : 'Any future mail from these accounts will be automatically Quarantined.'}
          </span>
        </div>
      </div>
      <div className="w-full py-5 px-6 flex items-center justify-center gap-4">
        <button
          type="button"
          className={`py-4 text-center border-light text-white border-border-primary ${!hasEmptyOrErrorValues() ? 'bg-black cursor-pointer' : 'bg-black/15 cursor-not-allowed'} bg-black rounded-md text-base w-2/3`}
          onClick={() => handleSubmit()}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}

          {isLoading || isWhitelistLoading ? <Loader color="white" /> : getButtonText()}
        </button>
      </div>
    </>
  );
}
