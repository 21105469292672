import { Divider } from 'components/Divider';
// import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg';
import { useDLPForm } from 'contexts/DLPFormContext';
import * as Checkbox from '@radix-ui/react-checkbox';
import { ReactComponent as IconCheck } from 'assets/icons/checkbox-icon.svg';
import { ReactComponent as IconDash } from 'assets/icons/icon-dash.svg';
import { ReactComponent as IconNotSelected } from 'assets/icons/icon-not-selected.svg';

import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import { emailPartDetails, CategoryItem } from './data';

export function PolicyPanel() {
  const { formDetails, setFormDetails, loading } = useDLPForm();

  const [categoryIndex, setCategoryIndex] = useState(-1);

  const [categories, setCategories] = useState<CategoryItem[]>([]);

  useEffect(() => {
    if (formDetails.categories) {
      setCategoryIndex((c) => (c === -1 ? 0 : c));
    }
    setCategories(formDetails.categories || []);
  }, [formDetails.categories]);

  const getCategoryCountBadge = (item: CategoryItem): string => {
    const filteredCount = item.dataTypes.filter((dataType) => dataType.checked).length || 0;

    if (filteredCount === item.dataTypes.length) return 'All';

    return `${filteredCount}/${item.dataTypes.length}`;
  };

  const isCategoryChecked = (item: CategoryItem): boolean => {
    return item.dataTypes.some((dataType) => dataType.checked);
  };

  const getCheckedIcon = (item: CategoryItem): React.ReactNode => {
    if (item.dataTypes.every((dataType) => dataType.checked)) {
      return <IconCheck className="w-3.5 h-3.5" />;
    }

    return <IconDash className="w-3.5 h-3.5" />;
  };

  const handleSubCategoryCheck = (dataTypeIndex: number) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[categoryIndex];

      // Toggle the checked state of the specific data type
      const updatedDataTypes = category.dataTypes.map((dataType, index) => ({
        ...dataType,
        checked: index === dataTypeIndex ? !dataType.checked : dataType.checked,
      }));

      // Update the category's checked state based on the data types
      const categoryChecked = updatedDataTypes.some((dataType) => dataType.checked);

      // Update the category with the new data types and checked state
      updatedCategories[categoryIndex] = {
        ...category,
        checked: categoryChecked,
        dataTypes: updatedDataTypes,
      };

      setFormDetails({ ...formDetails, categories: updatedCategories });

      return updatedCategories;
    });
  };

  const handleCategoryCheck = (index: number) => {
    setCategories((prevCategories) => {
      const updatedCategories = [...prevCategories];
      const category = updatedCategories[index];

      // Toggle the checked state of the category
      category.checked = !category.checked;

      // Update the checked state of the data types in the category
      category.dataTypes = category.dataTypes.map((c) => ({ ...c, checked: category.checked }));

      updatedCategories[index] = category;

      // Update the form details with the updated categories
      setFormDetails({ ...formDetails, categories: updatedCategories });

      return updatedCategories;
    });
  };

  const handleScancheck = (i: number) => {
    setFormDetails({
      ...formDetails,
      scan: formDetails.scan?.map((s, index) => (index === i ? { ...s, checked: !s.checked } : s)),
    });
  };

  const handleInputChange = (val: string) => {
    if (val) {
      setFormDetails({ ...formDetails, name: { value: val, error: '' } });
    } else {
      setFormDetails({ ...formDetails, name: { value: val, error: 'Policy name is required' } });
    }
  };

  return (
    <div className="px-12 py-8">
      <div className="flex flex-col gap-6 w-full">
        <span className="text-lg">Policy Name</span>
        <div className="flex flex-col gap-2">
          <input
            placeholder="Enter a policy name"
            className={`w-full text-sm px-4 py-3 placeholder:text-light-grey border-light ${formDetails.name?.error ? 'border-error-text' : 'border-border-primary'} outline-none rounded-lg`}
            onChange={(e) => handleInputChange(e.target.value)}
            value={formDetails.name?.value || ''}
          />
          {formDetails?.name?.error ? (
            <span className="text-xs text-error-text">{formDetails?.name?.error}</span>
          ) : null}
        </div>
      </div>
      <Divider />
      <div className="mt-4">
        <div className="flex flex-col gap-2">
          <span className="text-xl">Choose a data category you want to protect</span>
          <span className="text-light-grey">Choose one or multiple data types to protect</span>
        </div>
        {/* <div className="flex items-center gap-3 px-4 py-3 rounded-lg border-light border-border-primary mt-8 w-full bg-white">
          <IconSearch className="w-4 h-4" />
          <input
            placeholder="Search categories and Policy Templates"
            className="placeholder:text-light-grey text-sm flex-1 outline-none bg-transparent"
          />
        </div> */}
        <div className="mt-6 w-full px-7 py-8 rounded-2xl border-light border-border-primary bg-white flex justify-between gap-12">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col gap-8">
                {categories.map((option, index) => (
                  <button
                    type="button"
                    className="flex items-center gap-4 justify-between cursor-pointer"
                    onClick={() => setCategoryIndex(index)}
                  >
                    <div className="flex items-center gap-4">
                      <Checkbox.Root
                        checked={isCategoryChecked(option)}
                        className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:border-none rounded text-center flex justify-center items-center p-1"
                        onClick={() => handleCategoryCheck(index)}
                      >
                        <Checkbox.Indicator>{getCheckedIcon(option)}</Checkbox.Indicator>
                      </Checkbox.Root>
                      <div className="flex items-center gap-2">
                        <span
                          className={`text-sm ${categoryIndex === index || isCategoryChecked(option) ? 'text-black' : 'text-light-grey'}`}
                        >
                          {option.category}
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="bg-border-primary rounded-full py-1 px-2 text-black text-xs"
                    >
                      {getCategoryCountBadge(option)}
                    </button>
                  </button>
                ))}
              </div>
              <div className="border-light border-border-primary rounded-2xl bg-soft-gray flex-1">
                <div className="px-8 py-5 border-b-light border-border-primary">
                  <span className="text-sm w-full text-center">
                    Choose data types under this group that you want to protect
                  </span>
                </div>
                <div className="px-8 py-5 grid grid-cols-2 gap-y-4 gap-x-8">
                  {categories && categories[categoryIndex]?.dataTypes?.length
                    ? categories[categoryIndex].dataTypes.map((option, index) => (
                        <div className="flex items-center gap-2">
                          <Checkbox.Root
                            checked={option.checked}
                            className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:border-none rounded text-center flex justify-center items-center p-1"
                            onClick={() => handleSubCategoryCheck(index)}
                          >
                            <Checkbox.Indicator>
                              <IconCheck className="w-3.5 h-3.5" />
                            </Checkbox.Indicator>
                          </Checkbox.Root>
                          <span className="text-xs break-words">{option.label}</span>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Divider />
      <div className="mt-4 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <span className="text-xl">Where should we scan for sensitive data?</span>
          <span className="text-light-grey">
            Select where you want to detect financial data in outgoing communications.
          </span>
        </div>
        <div className="px-7 py-8 rounded-2xl bg-white">
          {formDetails?.scan?.map((scan, index) => {
            const details = emailPartDetails[scan.name];
            return (
              <div>
                <div className="flex items-center gap-4">
                  {!details.comingSoon ? (
                    <Checkbox.Root
                      checked={scan.checked}
                      className="w-3.5 h-3.5 border border-light-grey data-[state=checked]:border-none rounded text-center flex justify-center items-center p-1"
                      onClick={() => handleScancheck(index)}
                    >
                      <Checkbox.Indicator>
                        <IconCheck className="w-3.5 h-3.5" />
                      </Checkbox.Indicator>
                    </Checkbox.Root>
                  ) : (
                    <IconNotSelected className="w-3.5 h-3.5" />
                  )}
                  <div className="w-8 h-8 flex items-center justify-center rounded-lg border-light border-border-primary">
                    {details.icon}
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-black">
                      {details.title}{' '}
                      {details.comingSoon ? (
                        <span className="text-sm text-light-grey">(Coming Soon)</span>
                      ) : null}
                    </span>
                    {!details.comingSoon ? (
                      <span className="text-xs text-light-grey">{details.description}</span>
                    ) : null}
                  </div>
                </div>
                <Divider />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
