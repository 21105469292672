import { useState, useEffect } from 'react';
import { defaultException, ExceptionDetails } from 'components/DLP/data';
import { useDLPForm } from 'contexts/DLPFormContext';

import { ExceptionFormItem } from './ExceptionFormItem';

export function ExceptionForm() {
  const { setFormDetails, formDetails } = useDLPForm();

  const [exceptions, setExceptions] = useState<ExceptionDetails[]>([]);

  const handleAddException = () => {
    setExceptions((prevExceptions) => {
      const updatedExceptions = [
        ...prevExceptions,
        { ...defaultException, name: `Exception ${prevExceptions.length + 1}` },
      ];
      setFormDetails((prev) => ({ ...prev, exceptions: updatedExceptions }));
      return updatedExceptions;
    });
  };

  const handleDeleteException = (idx: number) => {
    setExceptions((prevExceptions) => {
      const updatedException = prevExceptions.filter((_, i) => i !== idx);
      setFormDetails((prev) => ({ ...prev, exceptions: updatedException }));
      return updatedException;
    });
  };

  const handleUpdateException = (index: number, details: ExceptionDetails) => {
    setExceptions((prevExceptions) => {
      const updatedException = prevExceptions.map((p, i) => (index === i ? details : p));
      setFormDetails((prev) => ({ ...prev, exceptions: updatedException }));
      return updatedException;
    });
  };

  useEffect(() => {
    if (formDetails.exceptions) {
      setExceptions(formDetails.exceptions || []);
    }
  }, [formDetails]);

  return (
    <div className="flex flex-col gap-10">
      {exceptions.map((e, index) => (
        <ExceptionFormItem
          key={e.name}
          exception={e}
          onChange={(details) => handleUpdateException(index, details)}
          onDelete={() => handleDeleteException(index)}
        />
      ))}
      <button
        type="button"
        className="w-fit border-light border-border-primary text-black px-3 py-2 bg-white rounded-lg shadow-sm"
        onClick={() => handleAddException()}
      >
        Add Exception +
      </button>
    </div>
  );
}
