import { ReactComponent as IconRemediateAlert } from 'assets/icons/remediate-alert.svg';
import { ReactComponent as IconBlock } from 'assets/icons/icon-block.svg';
import { ReactComponent as IconLogout } from 'assets/icons/icon-logout.svg';
import { ReactComponent as IconQuarantine } from 'assets/icons/quarantine.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';

import Avatar from 'components/Avatar';
import { getInitials } from 'utils/name';
import { Divider } from 'components/Divider';

interface RemediateAlertProps {
  email?: string;
  action?: string;
  blacklist?: string[];
  onSubmit: () => void;
  onCancel: () => void;
}

export function RemediateAlert({
  email,
  action,
  blacklist,
  onCancel,
  onSubmit,
}: RemediateAlertProps) {
  return (
    <div className="w-1/3 rounded-3xl border-light border-border-primary flex flex-col bg-white relative">
      <button
        type="button"
        className="w-6 h-6 text-center rounded-full border-light border-border-primary flex items-center justify-center absolute right-4 top-4"
        onClick={() => onCancel()}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <IconClose className="w-2.5 h-2.5 fill-light-grey" />
      </button>
      <div className="px-6 py-7 flex items-center gap-5 border-b-light border-border-primary">
        <IconRemediateAlert />
        <span> Are you sure you want to perform the following actions?</span>
      </div>
      <div className="px-9 py-7 border-b-light border-border-primary flex flex-col">
        {email ? (
          <>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <IconLogout className="w-3.5 h-3.5" />
                <span className="text-black text-sm">Log out the following recipient</span>
              </div>
              <div className="flex items-center gap-2 ml-4">
                <Avatar
                  bgColor="soft-blue"
                  hoverBgColor="soft-blue"
                  initials={getInitials(email, 1)}
                  textColor="secondary-text"
                  customCssClass="w-6 h-6 border-light border-border-primary text-xs"
                />
                <span className="text-sm text-light-grey">{email}</span>
              </div>
            </div>
            <Divider />
          </>
        ) : null}
        {action ? (
          <>
            <div className="flex items-center gap-4">
              {action === 'quarantine' ? (
                <IconQuarantine className="w-4 h-4" />
              ) : (
                <IconTrash className="w-4 h-4" />
              )}
              <span className="text-sm">
                Push mail to {action === 'junk' ? 'Junk' : 'Quarantine'}
              </span>
            </div>
            {blacklist?.length ? <Divider /> : null}
          </>
        ) : null}
        {blacklist?.length ? (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <IconBlock className="w-4 h-4" />
              <span className="text-sm">Block the following senders</span>
            </div>
            <div className="flex flex-col gap-4 ml-4">
              {blacklist.map((item) => (
                <div className="flex items-center gap-2">
                  <Avatar
                    initials={getInitials(item, 1)}
                    bgColor="soft-purple"
                    hoverBgColor="soft-purple"
                    textColor="secondary-text"
                    customCssClass="w-6 h-6 border-light border-border-primary text-xs"
                  />
                  <span className="text-sm text-light-grey">{item}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="px-9 py-7 flex items-center gap-6">
        <button
          type="submit"
          className="border-light border-border-primary rounded-lg text-black flex-1 p-4"
          onClick={() => onCancel()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-black rounded-lg text-white flex-1 p-4"
          onClick={() => onSubmit()}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}
